import {
  Dialog,
  DialogTitle,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
  WithStyles,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Volver from "../../../../components/buttons/Volver/Volver";
import PerfilPaciente from "../../../../components/PerfilPaciente/PerfilPaciente";
import {RootState} from "../../../../store/rootReducer";
import {styles} from "../../../Administrador/AdministrarConsultoriosMedicos/ListadoMedicos/ListadoMedicosStyles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {IMedicoFav} from "../../../../Interfaces/IMedicoFav";
import {
  getFavoritosDetallado,
  quitarMedicoDeFavoritos,
} from "../../../../apis/medicosFavoritosAPI";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import CardMedicoFavorito from "../../../../components/CardMedicoFavorito/CardMedicoFavorito";
import {
  setEspecialidad,
  setMedicoFiltrado,
  setHorariosDisponibles,
} from "../../../../store/paciente/consultas-programadas/consultasProgramadasSlice";
import {
  getDiasLibres,
  getInfoDia,
  getDiasLibresFiltrado,
} from "../../../../apis/citaProgramadaAPI";

const ListadoMedicos: React.FC<WithStyles<typeof styles>> = (props) => {
  const {cuilPacienteSeleccionado, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [medicoSelected, setMedicoSelected] = useState("");
  // Alerta 1 = Hay medicos pero no el favorito
  // Alerta 0 = No hay turnos para ningun medico
  const [alerta, setAlerta] = useState(0);

  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const {classes} = props;

  const [listadoMedicos, setListadoMedicos] = React.useState<Array<IMedicoFav>>(
    []
  );
  const [loading, setLoading] = React.useState(false);

  const onVolverClicked = () => {
    history.goBack();
  };

  useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = () => {
    setLoading(true);
    getFavoritosDetallado(cuilPacienteSeleccionado.toString())
      .then((res) => {
        setLoading(false);
        setListadoMedicos(res.Medicos);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const quitarFavorito = (medico: string, especialidad: string) => {
    setLoading(true);
    quitarMedicoDeFavoritos(
      cuilPacienteSeleccionado.toString(),
      medico,
      especialidad
    )
      .then((res) => {
        obtenerDatos();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const solicitarTurno = (
    medico: string,
    especialidad: string,
    Nombre: string,
    Apellido: string
  ) => {
    setLoading(true);
    let strMedico = Nombre + " " + Apellido;
    setMedicoSelected(strMedico);
    //Consulto el getinfodia para ver si el medico tiene turnos
    getDiasLibresFiltrado(especialidad, medico)
      .then((valoresRecibidos) => {
        // const medicorecibido = valoresRecibidos.filter((med) => (med.profesional === medico))
        if (valoresRecibidos.length > 0) {
          setModal(false);
          // El medico favorito si tiene turnos disponible
          dispatch(setEspecialidad(especialidad));
          dispatch(setMedicoFiltrado(medico));
          dispatch(setHorariosDisponibles(valoresRecibidos));
          setLoading(false);
          history.push(`/dashboard/consultas-programadas/seleccionar-turno`);
        } else {
          getDiasLibres(especialidad).then((res) => {
            if (res.length > 0) {
              // El medico favorito no tiene turno disponible pero si hay otros medicos
              dispatch(setEspecialidad(especialidad));
              dispatch(setMedicoFiltrado(undefined));
              dispatch(setHorariosDisponibles(res));
              setAlerta(1);
            } else {
              // No hay turnos para ningun medico, mostrar cartel.
              setAlerta(0);
            }
            setModal(true);
            setDetailsOpen(true);
            setLoading(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (modal) {
    return (
      <>
        <Dialog open={detailsOpen}>
          <DialogTitle>
            <div style={{display: "flex", flexDirection: "row", marginTop: 5}}>
              <div>
                <Typography>
                  {alerta === 1
                    ? `Actualmente no hay agenda disponible para el médico: ${medicoSelected}. Desea obtener un turno con otro médico?`
                    : `Lo sentimos. Actualmente no hay agenda disponible para esta especialidad.`}
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                marginTop: 5,
              }}
            >
              {alerta === 1 ? (
                <>
                  <Button
                    style={{margin: 10}}
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      setModal(false);
                      history.push(
                        `/dashboard/consultas-programadas/seleccionar-turno`
                      );
                    }}
                  >
                    Si
                  </Button>
                  <Button
                    style={{margin: 10}}
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => setModal(false)}
                  >
                    No
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{margin: 10}}
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => setModal(false)}
                  >
                    Volver
                  </Button>
                </>
              )}
            </div>
          </DialogTitle>
        </Dialog>
      </>
    );
  } else {
    return (
      <div>
        <PerfilPaciente paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
        <Divider />
        {loading ? (
          <div style={{marginTop: 30, marginBottom: 30}}>
            <LoadingBubbles />
          </div>
        ) : (
          <div style={{margin: 10}}>
            <div style={{margin: 10}}>
              <Typography variant="h6">{`Tus médicos favoritos`}</Typography>
            </div>
            <Grid container spacing={2}>
              {listadoMedicos.map((medico, iMedico) => {
                return (
                  <Grid item xs={4}>
                    <CardMedicoFavorito
                      medico={medico}
                      onSolicitarTurno={solicitarTurno}
                      onQuitarFavorito={quitarFavorito}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
        <Divider />
        <div
          style={{
            marginTop: 10,
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Volver onClickHandler={onVolverClicked} />
        </div>
      </div>
    );
  }
};

export default ListadoMedicos;
