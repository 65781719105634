import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: theme.paletaColores.fondo.primero,
      color: "#000",
    },
    minHeightToolbar: {
      minHeight: 100,
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 1130,
      width: "100%",
      margin: "0 auto",
      backgroundColor: theme.paletaColores.fondo.primero,
    },
    telefonoBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 1130,
      width: "100%",
      margin: "0 auto",
      backgroundColor: theme.paletaColores.fondo.primero,
    },
  });
