import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import NavBar from "../../components/core/NavBar/NavBar";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import {RootState} from "../../store/rootReducer";
import {useHistory} from "react-router-dom";
import VerMedicoOnline from "../../store/paciente/ver-medico-online/VerMedicoOnline";
import Welcome from "../Paciente/Welcome/Welcome";
//import WelcomeMedico from "../ProfesionalMedico/WelcomeMedico/WelcomeMedico";
import VerConsultasAnteriores from "../../store/paciente/ver-consultas-anteriores/VerConsultasAnteriores";
import ConsultasProgramadas from "../../store/paciente/consultas-programadas/ConsultasProgramadas";
import AtenderConsultorio from "../../store/profesional-medico/atender-consultorio/AtenderConsultorio";
import AdministrarConsultorio from "../../store/profesional-medico/administrar-consultorio/AdministrarConsultorio";
import VerTurnosAgendados from "../../store/paciente/ver-turnos-agendados/VerTurnosAgendados";
import WelcomeAdmin from "../Administrador/WelcomeAdmin/WelcomeAdmin";
import AdministrarConsultoriosMedicos from "../../store/administrador/administrar-consultorios-medicos/AdministrarConsultoriosMedicos";
import AdministrarGuardia from "../../store/administrador/administrar-guardia/AdministrarGuardia";
import AdministrarSintomas from "../../store/administrador/administrar-sintomas/AdministrarSintomas";
import AdministrarHorarios from "../../store/administrador/administrar-horarios/AdministrarHorarios";
import GuardiaMedico from "../../store/profesional-medico/guardia-medico/GuardiaMedico";
import WelcomeOperador from "../OperadorFinochietto/WelcomeOperador/WelcomeOperador";
import GestionarTurnos from "../../store/operador-finochietto/gestionar-turnos/GestionarTurnos";
import WelcomeAdminFinochietto from "../Administrador/WelcomeAdminFinochietto/WelcomeAdminFinochietto";
import AdministrarHistorialOffline from "../../store/administrador/administrar-historial-offline/AdministrarHistorialOffline";
import AdministrarSolicitudesFarmacia from "../../store/farmaceutico/administrar-solicitudes-farmacia/AdministrarSolicitudesFarmacia";
import AdministrarCalificacionesProfesionales from "../../store/administrador/administrar-calificaciones-profesionales/AdministrarCalificacionesProfesionales";
import WelcomeFarmaceutico from "../Farmaceutico/WelcomeFarmaceutico/WelcomeFarmaceutico";
import VerMedicosFavoritos from "../../store/paciente/ver-medicos-favoritos/VerMedicosFavoritos";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import {SetSesionActiva} from "../../apis/profesionalAPI";
import {getFirestore} from "../../db";
import {setSesionLogeo} from "../../store/user/userSlice";
import {finalizarSesionProfesional} from "../../apis/adminAPI";
import {log} from "console";
import firebase from "firebase";
import NewHomeMedico from "../ProfesionalMedico/NewHomeMedico/NewHomeMedico";

const Dashboard: React.FC<any> = () => {
  const {path} = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  let routes: any = null;

  const {rol} = useSelector((state: RootState) => state.user);

  if (rol === "PACIENTE") {
    console.log("PACIENTE");
    routes = (
      <>
        <PrivateRoute component={Welcome} path={path} exact />
        {/* <PrivateRoute component={MiPerfil} path={`${path}/mi-perfil`} /> */}
        <PrivateRoute
          component={VerMedicoOnline}
          path={`${path}/ver-medico-online`}
        />
        <PrivateRoute
          component={ConsultasProgramadas}
          path={`${path}/consultas-programadas`}
        />
        <PrivateRoute
          component={VerConsultasAnteriores}
          path={`${path}/ver-consultas-anteriores`}
        />
        <PrivateRoute
          component={VerTurnosAgendados}
          path={`${path}/ver-turnos-agendados`}
        />
        <PrivateRoute
          component={VerMedicosFavoritos}
          path={`${path}/medicos-favoritos`}
        />
        {/* <Route component={Salir} path={`${path}/salir`} /> */}
      </>
    );
  } else if (rol === "MEDICO") {
    console.log("MEDICO");
    routes = (
      <>
        <PrivateRoute component={NewHomeMedico} path={path} exact />
        {/* <PrivateRoute component={WelcomeMedico} path={path} exact />*/}
        <PrivateRoute component={GuardiaMedico} path={`${path}/guardia`} />
        <PrivateRoute
          component={AtenderConsultorio}
          path={`${path}/atender-consultorio`}
        />
        <PrivateRoute
          component={AdministrarConsultorio}
          path={`${path}/administrar-consultorio`}
        />
        {/* <Route component={Salir} path={`${path}/salir`} />  */}
      </>
    );
  } else if (rol === "ADMINISTRADOR") {
    console.log("ADMINISTRADOR");
    routes = (
      <>
        <PrivateRoute component={WelcomeAdmin} path={path} exact />
        <PrivateRoute
          component={AdministrarConsultoriosMedicos}
          path={`${path}/admin/administrar-consultorios-medicos`}
        />
        <PrivateRoute
          component={AdministrarGuardia}
          path={`${path}/admin/administrar-guardia`}
        />
        <PrivateRoute
          component={AdministrarSintomas}
          path={`${path}/admin/administrar-sintomas`}
        />
        <PrivateRoute
          component={AdministrarHorarios}
          path={`${path}/admin/administrar-horarios`}
        />
        <PrivateRoute
          component={AdministrarHistorialOffline}
          path={`${path}/admin/administrar-historial-offline`}
        />
        {/* <PrivateRoute component={AdministrarSolicitudesFarmacia} path={`${path}/admin/farmacia`} /> */}
        <PrivateRoute
          component={AdministrarCalificacionesProfesionales}
          path={`${path}/admin/administrar-calificaciones-profesionales`}
        />
      </>
    );
  } else if (rol === "OPERADOR") {
    console.log("OPERADOR");
    routes = (
      <>
        <PrivateRoute component={WelcomeOperador} path={path} exact />
        <PrivateRoute
          component={GestionarTurnos}
          path={`${path}/operador/gestionar-turnos`}
        />
      </>
    );
  } else if (rol === "ADMINISTRADOR FINOCHIETTO") {
    console.log("ADMINISTRADOR FINOCHIETTO");
    routes = (
      <>
        <PrivateRoute component={WelcomeAdminFinochietto} path={path} exact />
        <PrivateRoute
          component={GestionarTurnos}
          path={`${path}/operador/gestionar-turnos`}
        />
        <PrivateRoute
          component={AdministrarConsultoriosMedicos}
          path={`${path}/admin/administrar-consultorios-medicos`}
        />
        <PrivateRoute
          component={AdministrarHistorialOffline}
          path={`${path}/admin/administrar-historial-offline`}
        />
      </>
    );
  } else if (rol === "FARMACEUTICO") {
    console.log("FARMACEUTICO");
    routes = (
      <>
        <PrivateRoute component={WelcomeFarmaceutico} path={path} exact />
        <PrivateRoute
          component={AdministrarSolicitudesFarmacia}
          path={`${path}/farmacia/solicitudes`}
        />
      </>
    );
  }

  return (
    <NavBar>
      <Switch>{routes}</Switch>
    </NavBar>
  );
};

export default Dashboard as React.ComponentType;
