import axios from "axios";
import {IArchivoSubido} from "../Interfaces/IArchivoSubido";
import {IElementoHistorial} from "../Interfaces/IElementoHistorial";
import {IEnfermedadCie10} from "../Interfaces/IEnfermedadCie10";
import {IMedicamentos} from "../Interfaces/IMedicamentos";
import {IPostHistoriaClinica} from "../Interfaces/IPostHistoriaClinica";
import {IPractica} from "../Interfaces/IPractica";
import {IResultadoBusqueda} from "../Interfaces/IResultadoBusqueda";
import firebase from "firebase";
import {IResultadoBusquedaPaciente} from "../Interfaces/IResultadoBuquedaPaciente";
import {convertDateToDatestring} from "../utils/dateTimeHelper";
import {ISolicitudFarmacia} from "../Interfaces/ISolicitudFarmacia";
import {IDireccionCABA} from "../Interfaces/IDireccionCABA";

const historiaClinicaAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

historiaClinicaAPI.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
historiaClinicaAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("Error 401");
      console.log("Historial clinico.ts");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);

export async function cargarHistoriaClinica(
  postHistoriaClinica: IPostHistoriaClinica
): Promise<string> {
  const {data, status, statusText} = await historiaClinicaAPI.post<string>(
    `/cargarHistorialClinico`,
    postHistoriaClinica
  );
  return data;
}

export async function buscarEnfermedadesCie10(
  descripcionAEnviar: string
): Promise<IResultadoBusqueda<IEnfermedadCie10>> {
  descripcionAEnviar = descripcionAEnviar.toLowerCase();
  const {data} = await historiaClinicaAPI.get<
    IResultadoBusqueda<IEnfermedadCie10>
  >(`/getDiagnosticosDw?descripcion=${descripcionAEnviar}`);
  return data;
}

export async function obtenerPracticasImagenes(
  descripcionAEnviar: string
): Promise<IResultadoBusqueda<IPractica>> {
  const datosAEnviar: {descripcion: string} = {
    descripcion: descripcionAEnviar,
  };
  const {data} = await historiaClinicaAPI.post<IResultadoBusqueda<IPractica>>(
    `/Practicas`,
    datosAEnviar
  );
  return data;
}

export async function obtenerPracticasLaboratorio(
  descripcionAEnviar: string
): Promise<IResultadoBusqueda<IPractica>> {
  const datosAEnviar: {descripcion: string} = {
    descripcion: descripcionAEnviar,
  };
  const {data} = await historiaClinicaAPI.post<IResultadoBusqueda<IPractica>>(
    `/Laboratorio`,
    datosAEnviar
  );
  return data;
}
export async function obtenerMedicamentos(
  descripcionAEnviar: string
): Promise<IResultadoBusqueda<IMedicamentos>> {
  descripcionAEnviar = descripcionAEnviar.toLowerCase();
  const {data} = await historiaClinicaAPI.get<
    IResultadoBusqueda<IMedicamentos>
  >(`/getMedicamentosDw?descripcion=${descripcionAEnviar}`);
  return data;
}

export async function obtenerHistorialClinico(
  usuario: string,
  fechaDesde: Date | null = null,
  fechaHasta: Date | null = null,
  especialidad: string = "",
  apellidoProfesional: string = ""
): Promise<IElementoHistorial[]> {
  let query = `/ObtenerHistorialClinico?usuario=${usuario}`;
  if (fechaDesde) {
    query +=
      "&fechaDesde=" + convertDateToDatestring(fechaDesde).replaceAll("/", "-");
  }
  if (fechaHasta) {
    query +=
      "&fechaHasta=" + convertDateToDatestring(fechaHasta).replaceAll("/", "-");
  }
  if (especialidad) {
    query += "&especialidad=" + especialidad;
  }
  if (apellidoProfesional) {
    query += "&apellidoProfesional=" + apellidoProfesional;
  }
  const {data} = await historiaClinicaAPI.get<IElementoHistorial[]>(query);
  return data;
}
export async function obtenerDocumentos(
  codigo: string
): Promise<{esFinochietto: boolean; documentos: IArchivoSubido[]}> {
  const {data} = await historiaClinicaAPI.get(
    `/ObtenerDocumentos?codigo=${codigo}`
  );
  return data;
}

export async function verificarZonaRecetas(
  latitud: number,
  longitud: number
): Promise<{ZonaPermitida: boolean}> {
  const {data} = await historiaClinicaAPI.post(`/verificarZonaRecetas`, {
    latitud: latitud.toString(),
    longitud: longitud.toString(),
  });
  return data;
}

export async function generarPedidoMedicamento(
  usuario: string,
  sesion: string,
  celular: string,
  email: string,
  idReceta: string
): Promise<string> {
  const {data} = await historiaClinicaAPI.post(`/generarPedidoMedicamento`, {
    usuario: usuario,
    sesion: sesion,
    celular: celular,
    email: email,
    idReceta: idReceta,
  });
  return data;
}

export async function getListaPedidos(
  apellido?: string,
  nombre?: string,
  idReceta?: string,
  estado?: string,
  fecha?: Date
): Promise<Array<ISolicitudFarmacia>> {
  let query = `/getListaPedidos`;
  let ampersand = false;
  if (apellido) {
    query += `?apellido=${apellido!}`;
    ampersand = true;
  }
  if (nombre) {
    query += `${ampersand ? `&` : `?`}nombre=${nombre!}`;
    ampersand = true;
  }
  if (idReceta) {
    query += `${ampersand ? `&` : `?`}idReceta=${idReceta!}`;
    ampersand = true;
  }
  if (estado) {
    query += `${ampersand ? `&` : `?`}estado=${estado!}`;
    ampersand = true;
  }
  if (fecha) {
    query += `${ampersand ? `&` : `?`}fecha=${convertDateToDatestring(fecha!)}`;
    ampersand = true;
  }

  const {data} = await historiaClinicaAPI.get<Array<ISolicitudFarmacia>>(query);
  return data;
}

export async function cambiarEstadoPedido(
  idReceta: string,
  estado: string
): Promise<string> {
  const {data} = await historiaClinicaAPI.post(`/cambiarEstadoPedido`, {
    estado: estado,
    idReceta: idReceta,
  });
  return data;
}

export async function actualizarDireccionPedido(
  idReceta: string,
  direccion: string
): Promise<string> {
  const {data} = await historiaClinicaAPI.post(`/actualizarDireccionPedido`, {
    direccion: direccion,
    idReceta: idReceta,
  });
  return data;
}

export async function validarDireccionCABA(
  direccion: string,
  maxOptions: number
): Promise<Array<IDireccionCABA>> {
  const {data} = await historiaClinicaAPI.get(
    `/validarDireccionCABA?direccion=${direccion}&maxOptions=${maxOptions}`
  );
  return data;
}

export async function buscarPacientes(
  cuil: string = "",
  apellido: string = "",
  nombre: string = ""
): Promise<IResultadoBusquedaPaciente[]> {
  let query = "/buscarPacientes?";
  let ampersand = false;
  if (cuil) {
    query += `cuil=${cuil!}`;
    ampersand = true;
  }
  if (apellido) {
    query += ampersand ? `&apellido=${apellido!}` : `apellido=${apellido!}`;
    ampersand = true;
  }
  if (nombre) {
    query += ampersand ? `&nombre=${nombre!}` : `nombre=${nombre!}`;
    ampersand = true;
  }
  const {data} = await historiaClinicaAPI.get(query);
  return data;
}
