import {createStyles, Theme} from "@material-ui/core";
import {getWindowDimensions} from "../../../../hooks/useWindowDimensions";

export const drawerWidth = 700;

var {width, height} = getWindowDimensions();

export function handleResize() {
  let dim = getWindowDimensions();
  width = dim.width;
  height = dim.height;
}

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: theme.paletaColores.fondo.segundo,
      width: 700,
    },
    drawer: {
      minWidth: drawerWidth,
      maxWidth: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(11, 0),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: `calc(((-${width}px + 1024px)/2) + ${drawerWidth}px)`,
      marginLeft: `calc((-${width}px + 1024px)/2)`,
      //marginLeft: "-5%"
    },
    docked: {
      height: "100%",
      display: "block",
      zIndex: 1,
      paddingBottom: 55,
      paddingTop: 120,
    },
    divider: {
      background: theme.paletaColores.naranja.tercero,
      height: 2,
    },
  });
