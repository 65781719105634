import { useState, useEffect } from "react";

export interface GeolocationData{
    loaded:boolean;
    coordinates?: { lat: number, lng: number };
    error?: { code: number, message: string };
}

export default function useGeolocation() {
    const [location, setLocation] = useState<GeolocationData>({ loaded: false });

    const onSuccess = (location: any) => {
        setLocation({
            loaded: true,
            coordinates: {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
            },
        });
    }

    const onError = (error: any) => {
        setLocation(state => ({
            ...state,
            loaded: true,
            error: {
                code: error.code,
                message: error.message
            },
        }))
    }

    useEffect(() => {
        if (!("geolocation" in navigator)) {
            setLocation(state => ({
                ...state,
                loaded: true,
                error: {
                    code: 0,
                    message: "Geolocation not supported",
                },
            }));
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError);

    }, [])
    return location;
}