import React, {useEffect, useState} from "react";
import {
  Typography,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Chip,
  Paper,
} from "@material-ui/core";
import {
  AddCircleOutlineOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@material-ui/icons";

import {ISintoma} from "../../Interfaces/ISintoma";
import CardNuevaPreguntaTriaje from "../CardNuevaPreguntaTriaje/CardNuevaPreguntaTriaje";
import {
  agregarPreguntasAlSintoma,
  cambiarImagenSintoma,
  deletePreguntaSintoma,
  deleteSintoma,
} from "../../apis/especialidadAPI";
import LoadingBubbles from "../LoadingBubbles/LoadingBubbles";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

interface OwnProps {
  especialidad: string;
  modeloSintoma: ISintoma;
  onCompleteModificacion: () => void;
  setParentLoading: (loading: boolean) => void;
}

type PublicProps = OwnProps;
type Props = PublicProps;

interface IPreguntaTriaje {
  pregunta: string;
  cerrada: boolean;
}

const CardSintomaAdmin: React.FC<Props> = (props) => {
  const {
    especialidad,
    modeloSintoma,
    onCompleteModificacion,
    setParentLoading,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [nuevasPreguntas, setNuevasPreguntas] = useState<
    Array<IPreguntaTriaje>
  >([]);

  useEffect(() => {
    let tmp = new Array<boolean>(modeloSintoma.preguntas.length);
    setDialogDeletePregOpen(tmp);
  }, [modeloSintoma]);

  const handleClickOpenDialog = () => {
    setDialogOpen(true);
    setNuevasPreguntas([]);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);

  const [dialogDeletePregOpen, setDialogDeletePregOpen] = useState<
    Array<boolean>
  >([]);

  const [isImagenHovered, setIsImagenHovered] = useState(false);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleEnviarNuevasPreguntas = () => {
    setLoading(true);
    agregarPreguntasAlSintoma(
      especialidad,
      modeloSintoma.sintoma,
      nuevasPreguntas
    )
      .then(() => {
        onCompleteModificacion();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const theme = useTheme();

  let habilitadoEnviarNuevasPreguntas = true;
  if (loading) {
    habilitadoEnviarNuevasPreguntas = false;
  } else {
    if (nuevasPreguntas.length === 0) {
      habilitadoEnviarNuevasPreguntas = false;
    } else {
      nuevasPreguntas.forEach((pregunta) => {
        if (pregunta.pregunta === "") {
          habilitadoEnviarNuevasPreguntas = false;
        }
      });
    }
  }

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Card style={{background: "none"}}>
          <CardHeader
            avatar={<Avatar src={modeloSintoma.imagen} />}
            title={
              <Typography variant="body1">{modeloSintoma.sintoma}</Typography>
            }
            action={
              <>
                <IconButton onClick={handleClickOpenDialog} color="primary">
                  <EditOutlined />
                </IconButton>
                <IconButton
                  onClick={() => setDialogDeleteOpen(true)}
                  color="inherit"
                >
                  <DeleteOutlined />
                </IconButton>
                <ConfirmationDialog
                  title={`¿Eliminar sintoma "${modeloSintoma.sintoma}"?`}
                  open={dialogDeleteOpen}
                  setOpen={setDialogDeleteOpen}
                  onConfirm={() => {
                    setParentLoading(true);
                    deleteSintoma(especialidad, modeloSintoma.sintoma)
                      .then(() => {
                        onCompleteModificacion();
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                />
              </>
            }
          />
        </Card>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{display: "flex", alignItems: "center"}}>
            <IconButton
              style={{
                borderColor: theme.paletaColores.negro.cuarto,
                borderStyle: "solid",
                borderWidth: "1px",
                margin: "0px 15px 0px 0px",
                width: "56px",
                height: "56px",
              }}
              onMouseEnter={() => setIsImagenHovered(true)}
              onMouseLeave={() => setIsImagenHovered(false)}
              onClick={() => hiddenFileInput.current?.click()}
            >
              <Avatar
                style={{
                  width: "56px",
                  height: "56px",
                }}
                src={isImagenHovered ? undefined : modeloSintoma.imagen}
              >
                {isImagenHovered && <EditOutlined />}
              </Avatar>
              <input
                type="file"
                name="inputArchivo"
                accept=".jpg,.png"
                onChange={(e) => {
                  const file = e!.target!.files![0]!;
                  if (
                    file.name.endsWith(".jpg") ||
                    file.name.endsWith(".png")
                  ) {
                    //subir
                    setParentLoading(true);
                    cambiarImagenSintoma(
                      especialidad,
                      modeloSintoma.sintoma,
                      file
                    )
                      .then(() => {
                        onCompleteModificacion();
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  } else {
                    alert("Por favor seleccione una imagen .png o .jpg");
                  }
                }}
                hidden
                ref={hiddenFileInput}
              />
            </IconButton>
            {modeloSintoma.sintoma}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {loading ? (
              <div style={{margin: "10vh 0px 10vh 0px"}}>
                <LoadingBubbles />
              </div>
            ) : (
              <>
                {modeloSintoma.nota ? (
                  <Typography paragraph variant="body2">
                    {" "}
                    {modeloSintoma.nota}
                  </Typography>
                ) : null}
                <Grid item container direction="column">
                  {modeloSintoma.preguntas.map((pregunta, iPreg) => {
                    const setDialogDeleteEstaPreguntaOpen = (open: boolean) => {
                      let tmp = [...dialogDeletePregOpen];
                      tmp[iPreg] = open;
                      setDialogDeletePregOpen(tmp);
                    };

                    return (
                      <Grid
                        item
                        xs={12}
                        key={iPreg}
                        style={{margin: "5px 0px 5px 0px"}}
                      >
                        <Card>
                          <CardHeader
                            avatar={<Avatar>{`${iPreg + 1}`}</Avatar>}
                            title={pregunta.pregunta}
                            subheader={
                              pregunta.opciones !== undefined &&
                              pregunta.opciones.length > 0 ? (
                                <>
                                  Pregunta cerrada. Opciones:
                                  {pregunta.opciones.map((opcion) => (
                                    <Chip
                                      variant="outlined"
                                      size="small"
                                      style={{
                                        minWidth: 35,
                                        margin: "0px 2px 0px 2px",
                                      }}
                                      label={opcion}
                                    />
                                  ))}
                                </>
                              ) : (
                                "Pregunta abierta (se responde con texto libre)"
                              )
                            }
                            action={
                              <>
                                <IconButton
                                  onClick={() => {
                                    setDialogDeleteEstaPreguntaOpen(true);
                                  }}
                                >
                                  <DeleteOutlined />
                                </IconButton>
                                <ConfirmationDialog
                                  title={`¿Está seguro de que desea eliminar la pregunta?`}
                                  open={dialogDeletePregOpen[iPreg]}
                                  setOpen={setDialogDeleteEstaPreguntaOpen}
                                  onConfirm={() => {
                                    setLoading(true);
                                    deletePreguntaSintoma(
                                      especialidad,
                                      modeloSintoma.sintoma,
                                      pregunta.pregunta
                                    )
                                      .then(() => {
                                        onCompleteModificacion();
                                        setLoading(false);
                                      })
                                      .catch((e) => {
                                        console.log(e);
                                      });
                                  }}
                                >
                                  <>
                                    <Paper
                                      style={{
                                        padding: 10,
                                        backgroundColor:
                                          theme.paletaColores.fondo.segundo,
                                      }}
                                    >
                                      <Typography>
                                        {pregunta.pregunta}
                                      </Typography>
                                    </Paper>
                                  </>
                                </ConfirmationDialog>
                              </>
                            }
                          />
                        </Card>
                      </Grid>
                    );
                  })}
                  {nuevasPreguntas.map((pregunta, iPregunta) => {
                    const handleSetTextoPregunta = (texto: string) => {
                      let tmp = [...nuevasPreguntas];
                      tmp[iPregunta].pregunta = texto;
                      setNuevasPreguntas(tmp);
                    };
                    const handleSetPreguntaCerrada = (cerrada: boolean) => {
                      let tmp = [...nuevasPreguntas];
                      tmp[iPregunta].cerrada = cerrada;
                      setNuevasPreguntas(tmp);
                    };
                    const handleBorrarPregunta = () => {
                      let tmp = [...nuevasPreguntas];
                      tmp.splice(iPregunta, 1);
                      setNuevasPreguntas(tmp);
                    };
                    return (
                      <CardNuevaPreguntaTriaje
                        textoPregunta={pregunta.pregunta}
                        preguntaCerrada={pregunta.cerrada}
                        setTextoPregunta={handleSetTextoPregunta}
                        setPreguntaCerrada={handleSetPreguntaCerrada}
                        onBorrarPregunta={handleBorrarPregunta}
                      />
                    );
                  })}
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineOutlined />}
                    onClick={() => {
                      let temp = [...nuevasPreguntas];
                      temp.push({cerrada: false, pregunta: ""});
                      setNuevasPreguntas(temp);
                    }}
                  >
                    Agregar pregunta
                  </Button>
                </Grid>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleEnviarNuevasPreguntas}
            disabled={!habilitadoEnviarNuevasPreguntas}
          >
            Enviar nuevas preguntas
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleCloseDialog}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardSintomaAdmin as React.ComponentType<PublicProps>;
