import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import {ArrowDownwardOutlined} from "@material-ui/icons";
import {KeyboardDatePicker} from "@material-ui/pickers";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {IFinanciador} from "../../../../../Interfaces/IFinanciador";

interface OwnProps {
  onHabilitarSiguiente: (habilitado: boolean) => void;
  siguienteHabilitado: boolean;

  motivos: Array<string>;
  motivo: string;
  setMotivo: (newMot: string) => void;

  cuil: string;
  setCuil: (newCuil: string) => void;

  fechaNac: Date | null;
  setFechaNac: (newFec: Date) => void;

  genero: "M" | "F" | "X" | undefined;
  setGenero: (newGenero: "M" | "F" | "X" | undefined) => void;

  nombre: string;
  setNombre: (newNombre: string) => void;
  apellido: string;
  setApellido: (newApellido: string) => void;

  email: string;
  setEmail: (newEmail: string) => void;
  celular: string;
  setCelular: (newCel: string) => void;
  numCredencial: string;
  setNumCredencial: (newAsociado: string) => void;

  financiador: IFinanciador;

  loadingBuscarCUIL: boolean;
  buscarCUIL: (cuil: string) => void;

  datosLocked: boolean;

  credencialLocked: boolean;
}

const alphabet = "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ";

function DatosPaciente(props: OwnProps) {
  const {
    siguienteHabilitado,
    onHabilitarSiguiente,
    motivos,
    motivo,
    setMotivo,
    cuil,
    setCuil,
    fechaNac,
    setFechaNac,
    genero,
    setGenero,
    nombre,
    setNombre,
    apellido,
    setApellido,
    email,
    setEmail,
    celular,
    setCelular,
    numCredencial,
    setNumCredencial,
    financiador,
    loadingBuscarCUIL,
    buscarCUIL,
    datosLocked,
    credencialLocked,
  } = props;

  const theme = useTheme();

  let cuilError = cuil !== "" && (cuil.length < 11 || cuil.length > 11);

  let emailError = false;
  if (email) {
    const emailSeparado = email.split("@");

    if (emailSeparado.length !== 2) {
      emailError = true;
    } else {
      emailSeparado.forEach((parte) => {
        if (parte.length === 0) {
          emailError = true;
        }
      });
      const domSeparado = emailSeparado[1].split(".");
      if (domSeparado.length < 2) {
        emailError = true;
      } else {
        domSeparado.forEach((parte) => {
          if (parte.length === 0) {
            emailError = true;
          }
        });
      }
    }
  }

  let celularError = false;
  if (celular.length > 3) {
    if (celular.length <= 4) {
      celularError = true;
    } else if (celular.startsWith("+54")) {
      if (celular.length != 13) {
        celularError = true;
      }
    }
  }

  let niEmailNiCelular = false;
  if (!email && (!celular || celular.length <= 4)) {
    niEmailNiCelular = true;
  }

  React.useEffect(() => {
    let puedeContinuar = true;
    if (!cuil || cuilError) {
      puedeContinuar = false;
    }
    if (!fechaNac) {
      puedeContinuar = false;
    }
    if (!numCredencial) {
      puedeContinuar = false;
    }
    if (!genero) {
      puedeContinuar = false;
    }
    if (!nombre || !apellido) {
      puedeContinuar = false;
    }
    if (celularError || emailError) {
      puedeContinuar = false;
    }
    if (niEmailNiCelular) {
      puedeContinuar = false;
    }
    if (!motivo) {
      puedeContinuar = false;
    }
    onHabilitarSiguiente(puedeContinuar);
  }, [
    cuil,
    cuilError,
    fechaNac,
    numCredencial,
    genero,
    nombre,
    apellido,
    email,
    emailError,
    celular,
    celularError,
    motivo,
  ]);

  return (
    <div style={{padding: "0px 30px 0px 30px"}}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={2}>
          <TextField
            label="CUIL"
            style={{width: "100%", maxWidth: "300px"}}
            value={cuil}
            onChange={(event) => {
              const newCuil = Number.parseInt(event.target.value as string);
              if (!newCuil) {
                setCuil("");
              } else {
                const newCuilSt = newCuil.toString();
                if (newCuilSt.length < 11) {
                  setCuil(newCuilSt);
                } else if (newCuilSt.length === 11) {
                  setCuil(newCuilSt);
                  buscarCUIL(newCuilSt);
                }
              }
            }}
            error={cuilError}
            helperText={cuilError ? "CUIL Invalido" : null}
            required
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {loadingBuscarCUIL ? <LoadingBubbles size={30} /> : null}
                </React.Fragment>
              ),
            }}
            disabled={datosLocked}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <KeyboardDatePicker
            variant="inline"
            format="dd/MM/yyyy"
            label="Fecha de Nacimiento"
            disabled={loadingBuscarCUIL || cuil.length < 11 || datosLocked}
            value={fechaNac}
            onChange={(event) => {
              setFechaNac(event as Date);
            }}
            style={{width: "100%"}}
            required
            defaultValue={null}
            disableFuture
            invalidDateMessage="Fecha inválida"
            maxDateMessage="La fecha debe ser anterior a hoy"
            InputAdornmentProps={{style: {visibility: "hidden"}}}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            label={"Cred. " + financiador.descripcion}
            disabled={loadingBuscarCUIL || cuil.length < 11 || credencialLocked}
            style={{width: "100%"}}
            value={numCredencial}
            onChange={(event) => {
              let newCredencial = event.target.value as string;
              newCredencial = JSON.stringify(newCredencial).replace(
                /[^a-zA-Z0-9]/g,
                ""
              );
              if (newCredencial.length <= 20) {
                setNumCredencial(newCredencial);
              }
            }}
            required
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <RadioGroup
            aria-label="genero"
            name="genero"
            value={genero}
            onChange={(event) => {
              setGenero(event.target.value as "M" | "F" | "X");
            }}
          >
            <InputLabel required>Género</InputLabel>
            <Grid container>
              <Grid item xs={3}>
                <FormControlLabel
                  disabled={
                    loadingBuscarCUIL || cuil.length < 11 || datosLocked
                  }
                  value={"F"}
                  control={
                    <Radio size="small" checked={genero === "F"} value={"F"} />
                  }
                  label={<span style={{margin: -5}}>F</span>}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  disabled={
                    loadingBuscarCUIL || cuil.length < 11 || datosLocked
                  }
                  value={"M"}
                  control={
                    <Radio size="small" checked={genero === "M"} value={"M"} />
                  }
                  label={<span style={{margin: -5}}>M</span>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  disabled={
                    loadingBuscarCUIL || cuil.length < 11 || datosLocked
                  }
                  value={"X"}
                  control={
                    <Radio size="small" checked={genero === "X"} value={"X"} />
                  }
                  label={<span style={{margin: -5}}>Otro/NC</span>}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Nombre"
            style={{width: "100%"}}
            value={nombre}
            disabled={loadingBuscarCUIL || cuil.length < 11 || datosLocked}
            onChange={(event) => {
              let newNombre = event.target.value as string;
              newNombre = JSON.stringify(newNombre).replace(/[^a-zA-Z\s]/g, "");
              setNombre(newNombre);
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Apellido"
            style={{width: "100%"}}
            disabled={loadingBuscarCUIL || cuil.length < 11 || datosLocked}
            value={apellido}
            onChange={(event) => {
              let newApellido = event.target.value as string;
              newApellido = JSON.stringify(newApellido).replace(
                /[^a-zA-Z\s]/g,
                ""
              );
              setApellido(newApellido);
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <TextField
            label="Email"
            style={{width: "100%"}}
            value={email}
            disabled={loadingBuscarCUIL || cuil.length < 11}
            onChange={(event) => {
              let newEmail = event.target.value as string;
              setEmail(newEmail);
            }}
            error={emailError}
            helperText={emailError ? "Email Invalido" : null}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MuiPhoneNumber
            autoFormat={false}
            defaultCountry={"ar"}
            disabled={loadingBuscarCUIL || cuil.length < 11}
            onChange={(newCel) => {
              setCelular(newCel);
            }}
            disableAreaCodes={false}
            style={{width: "100%"}}
            label="Celular"
            value={celular}
            error={celularError}
            helperText={celularError ? "Celular Invalido" : null}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControl required fullWidth>
            <InputLabel>Motivo de la consulta</InputLabel>
            <Select
              required
              label="Motivo de la consulta"
              disabled={loadingBuscarCUIL || cuil.length < 11}
              value={motivo}
              onChange={(event) => {
                setMotivo(event.target.value as string);
              }}
            >
              {motivos.map((motivo, iMotivo) => {
                return (
                  <MenuItem key={iMotivo} value={motivo}>
                    {motivo}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {niEmailNiCelular ? (
        <Typography
          style={{
            marginTop: 20,
            color: theme.paletaColores.error,
            fontSize: 13,
          }}
        >
          Por favor ingrese un celular o un email.
        </Typography>
      ) : null}
    </div>
  );
}

export default DatosPaciente;
