import React, {useEffect, useState} from "react";
import {
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {getFirestore} from "../../../../db";

import {styles} from "./SeleccionarSintomasStyles";
import {
  setPaso,
  setSintomaGuardado,
} from "../../../../store/paciente/ver-medico-online/verMedicoOnlineSlice";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import Siguiente from "../../../../components/buttons/Siguiente/Siguiente";
import CardSintoma from "../../../../components/CardSintomas/CardSintoma";
import {getPreguntasTriaje} from "../../../../apis/especialidadAPI";
import {ISintoma} from "../../../../Interfaces/ISintoma";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";

const SeleccionarSintomas: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const onSiguienteClicked = () => {
    dispatch(setSintomaGuardado(undefined));
    dispatch(setPaso(4));
    history.push(`/dashboard/ver-medico-online/confirmar`);
  };
  const [cantidadesEspera, setCantidadesEspera] = useState(121);
  const [medicosEspera, setMedicosEspera] = useState(0);
  const [consultaExistente, setConsultaExistente] = useState(false);

  const [listaSintomasApi, setListaSintomasApi] = useState<Array<ISintoma>>([]);
  const [loading, setLoading] = useState(false);
  const [loadingActivas, setLoadingActivas] = useState(false);
  const [consultasActivas, setConsultasActivas] = useState([] as any);

  const {especialidad} = useSelector(
    (state: RootState) => state.verMedicoOnline
  );
  const {cuilPacienteSeleccionado} = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    obtenerDatos();
    getFirestore()
      .collection("CitasInstantaneas")
      .doc(especialidad)
      .collection("sesiones")
      .where("usuario", "==", `${cuilPacienteSeleccionado}`)
      .get()
      .then((consultas) => {
        if (consultas.docs.length > 0) {
          let activas = [...consultasActivas];
          for (let consulta of consultas.docs) {
            if (consulta.data().estado == "P") {
              setConsultaExistente(true);
              activas.push(consulta.id);
            }
          }
          setConsultasActivas(activas);
        } else {
          setConsultasActivas([]);
        }
      });
    let pacientesEnEspera = getFirestore()
      .collection("UsuariosConectados")
      .doc("paciente")
      .collection("Lista")
      .where("especialidad", "==", especialidad)
      .onSnapshot((pacientes) => {
        setCantidadesEspera(pacientes.docs.length);
      });
    let medicosAtendiendo = getFirestore()
      .collection("UsuariosConectados")
      .doc("profesional")
      .collection("Lista")
      .onSnapshot((medicos) => {
        let medicosEspTemp = medicos.docs.filter((val) => {
          if (val.data().estado != "EstadoDeGuardia") {
            let esps: Array<string> = val.data().especialidad.split(", ");
            return esps.includes(especialidad);
          } else {
            return false;
          }
        });
        setMedicosEspera(medicosEspTemp.length);
      });
    return () => {
      pacientesEnEspera();
      medicosAtendiendo();
    };
  }, []);

  const cancelarConsultasActivas = async () => {
    setLoadingActivas(true);
    for (let consulta of consultasActivas) {
      await getFirestore()
        .collection("CitasInstantaneas")
        .doc(especialidad)
        .collection("sesiones")
        .doc(consulta)
        .set(
          {
            estado: "C",
            status: "cancelado-por-boton-al-sacar-otra-sesion",
          },
          {
            merge: true,
          }
        );
    }
    setLoadingActivas(false);
    setConsultaExistente(false);
  };

  const obtenerDatos = async () => {
    setLoading(true);
    const data = await getPreguntasTriaje(especialidad); //especialidad
    setLoading(false);
    setListaSintomasApi(data as any);
  };

  if (consultaExistente) {
    return (
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogContent>
          <DialogContentText>
            {`Ya existe una sesión con tu usuario en sala de espera.`}
          </DialogContentText>
          <DialogContentText>
            En caso decidas continuar con esta se cancelará la otra.
          </DialogContentText>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => {
                history.goBack();
              }}
            >
              CANCELAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={loadingActivas}
              onClick={() => {
                cancelarConsultasActivas();
              }}
            >
              {loadingActivas ? "PROCESANDO..." : "CONTINUAR"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  if (cantidadesEspera >= medicosEspera * 10 && medicosEspera !== 0) {
    return (
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText>
            {`Hola. Pedimos disculpas pero en este momento hay un volumen muy grande de pacientes en sala de espera de “${especialidad}” ${medicosEspera} medicos. Le pedimos por favor que aguarde unos minutos y vuelva a intentar.`}
          </DialogContentText>
          <DialogContentText>
            Ante urgencias o emergencias contacte al 0800-333-0075.
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                history.goBack();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Typography variant="h5">Seleccioná un motivo</Typography>
      <Typography variant="body1" paragraph>
        Elegí una opción o hacé click aquí si no encontras listado el motivo o
        prefieres no especificarlo
      </Typography>
      <Divider style={{marginTop: 10}} />
      {loading ? (
        <LoadingBubbles />
      ) : (
        <>
          <Grid container spacing={1}>
            {listaSintomasApi.map((sintoma, index) => {
              return (
                <>
                  <CardSintoma key={index} modeloSintoma={sintoma} />
                </>
              );
            })}
          </Grid>
          <Divider />
          <div className={classes.buttonsRoot}>
            <Volver onClickHandler={() => history.goBack()} />
            <Siguiente onClickHandler={onSiguienteClicked} />
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(SeleccionarSintomas);
