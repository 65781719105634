import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: 16,
      maxWidth: 500,
    },
    buttonsRoot: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      "& button": {
        textTransform: "none",
        width: 250,
        margin: 10,
      },
      "& button:nth-child(2)": {
        color: "white",
        backgroundColor: "grey",
      },
    },
    sample: {
      display: "flex",
      "input, button": {
        font: "inherit",
      },
      header: {
        backgroundColor: "rgb(50, 54, 57)",
        boxShadow: "0 0 8px rgba(0, 0, 0, .5)",
        padding: "20px",
        color: "white",
        h1: {
          fontSize: "inherit",
          margin: "0",
        },
      },
      "&__container": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        margin: "10px 0",
        padding: "10px",
        "& > *": {
          "& > *": {
            margin: "10px",
          },
        },
        "&__content": {
          display: "flex",
          maxWidth: "100%",
          flexBasis: "420px",
          flexDirection: "column",
          flexGrow: 100,
          alignItems: "stretch",
          paddingTop: "1em",
          "react-calendar": {
            margin: "0 auto",
          },
        },
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 1130,
      width: "100%",
      margin: "0 auto",
      backgroundColor: theme.paletaColores.fondo.primero,
    },
    menuButton: {
      marginRight: 20,
    },
    title: {
      flexGrow: 1,
    },
  });
