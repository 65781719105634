import axios from "axios";
import firebase from "firebase";
import {IPractica} from "../Interfaces/IPractica";

const practicasAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

practicasAPI.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
practicasAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("Practicas.ts");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);

export async function buscarPractica(
  descripcion: string,
  tipoPractica:
    | "LaboratorioAmbulatorio"
    | "ImagenesAmbulatorio"
    | "EstudiosPracticasAmbulatorio"
    | "ConsultasAmbulatorio"
    | "ConsultasOdontologia"
    | "PeriodonciaOdontologia"
    | "RadiologiaOdontologia"
) {
  const {data} = await practicasAPI.get<Array<IPractica>>(
    `/${tipoPractica}Dw?descripcion=${descripcion.toLowerCase()}`
  );
  return data;
}
