import React, {useState, useEffect} from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  MenuItem,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  WithStyles,
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

import {styles} from "./SeleccionarEspecialidadStyles";
import {
  setPaso,
  setEspecialidad,
} from "../../../../store/paciente/ver-medico-online/verMedicoOnlineSlice";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import Siguiente from "../../../../components/buttons/Siguiente/Siguiente";
import PerfilPaciente from "../../../../components/PerfilPaciente/PerfilPaciente";
import {IEspecialidad} from "../../../../Interfaces/IEspecialidad";
import {IHorariosEspecialidades} from "../../../../Interfaces/IHorariosEspecialidades";
import {getEspecialidades} from "../../../../apis/especialidadAPI";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {useTime} from "../../../../hooks/useTime";
import {getFirestore} from "../../../../db";
import {convertDateToDatestring} from "../../../../utils/dateTimeHelper";

const SeleccionarEspecialidad: React.FC<WithStyles<typeof styles>> = (
  props
) => {
  const {classes} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  //const now = useTime();
  const db = getFirestore();
  const [alerta, setAlerta] = useState(false);
  const [listaEspecialidadesApi, setListaEspecialidadesApi] = useState<
    Array<IEspecialidad>
  >([]);
  const [especialidadesPrincipales, setListaEspecialidadesPrincipales] =
    useState<Array<IEspecialidad>>([]);
  const [horarios, setHorarios] = useState<Array<IHorariosEspecialidades>>([]);
  const [alertaDia, setAlertaDia] = useState(false);
  const [btnEspecialidad, setBtnEspecialidad] = useState(false);

  useEffect(() => {
    obtenerDatos();
  }, []);

  const {cuilPacienteSeleccionado, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );

  const obtenerDatos = async () => {
    setLoading(true);
    const paciente = pacientesByCuil[cuilPacienteSeleccionado];
    const data = await getEspecialidades(
      paciente.CODIGO_SEXO,
      paciente.EDAD,
      "espontanea"
    );
    let vals = await db
      .collection("HorarioAtencion")
      .doc("espontanea")
      .collection("especialidades")
      .get();
    if (!vals.empty) {
      let horario = [];
      for (let val of vals.docs) {
        let id = val.id;
        let data: IHorariosEspecialidades = val.data() as any;
        data.id = id;

        horario.push(data);
      }
      setHorarios(horario);
    }
    setLoading(false);
    setListaEspecialidadesApi(data as any);

    //solo la primera es principal
    // console.log('datos de especialidades')
    // console.log(data)
    const index = data.findIndex((val: any) => val.descripcion === 'CLINICA ADULTOS')
    // const especialidadesPrincipales = data.slice(0, 1);
    const especialidadesPrincipales = data.splice(index, 1)
    setListaEspecialidadesPrincipales(especialidadesPrincipales);
  };

  const [especialidad, guardarEspecialidad] = useState("");
  const [loading, setLoading] = useState(false);

  const onEspecialidadClicked = (nombreEspecialidad: string) => {
    guardarEspecialidad(nombreEspecialidad);
    setBtnEspecialidad(true);
    // dispatch(setPaso(3))
    // dispatch(setEspecialidad(nombreEspecialidad))
    // history.push(`/dashboard/ver-medico-online/sintomas`)
  };

  const onEspecialidadChanged = (event: any) => {
    guardarEspecialidad(event.target.value);
  };

  useEffect(() => {
    let res = validarHorario();
    if (res) {
      setAlerta(true);
      setBtnEspecialidad(false);
    } else {
      setAlerta(false);
      setAlertaDia(false);
      if (btnEspecialidad) {
        dispatch(setPaso(3));
        dispatch(setEspecialidad(especialidad));
        setBtnEspecialidad(false);
        history.push(`/dashboard/ver-medico-online/sintomas`);
      }
    }
  }, [especialidad]);

  const validarHorario = () => {
    let now = new Date();
    let numeroDia = now.getDay();
    let nowStr = convertDateToDatestring(now);

    if (especialidad === "") return true;

    if (
      horarios.filter(
        (val) => val!.id!.toLowerCase() === especialidad!.toLowerCase()
      ).length
    ) {
      let horario = horarios.filter(
        (val) => val!.id!.toLowerCase() === especialidad!.toLowerCase()
      )[0];

      // valido que sea el dia y que no sea excepcion
      if (
        !horario.dias.includes(numeroDia) ||
        horario.excepciones.includes(nowStr)
      ) {
        setAlertaDia(true);
        return true;
      }

      // valido horario
      let inicio = new Date();
      let final = new Date();
      inicio.setHours(
        parseInt(horario.inicio.split(":")[0]),
        parseInt(horario.inicio.split(":")[1])
      );
      final.setHours(
        parseInt(horario.fin.split(":")[0]),
        parseInt(horario.fin.split(":")[1])
      );

      if (now <= inicio || now >= final) {
        return true;
      }
    }
  };

  const onSiguienteClicked = () => {
    if (especialidad === "") {
      return;
    }
    dispatch(setPaso(3));
    dispatch(setEspecialidad(especialidad));
    history.push(`/dashboard/ver-medico-online/sintomas`);
  };

  if (alerta) {
    let horario = horarios.filter(
      (val) => val!.id!.toLowerCase() === especialidad!.toLowerCase()
    )[0];
    if (horario) {
      return (
        <Dialog open={true} fullWidth maxWidth="xs">
          <DialogContent>
            <DialogContentText>
              {alertaDia
                ? `Teleconsulta de ${especialidad} no disponible el dia de hoy.`
                : `Teleconsulta de ${especialidad} de ${horario.inicio}hs a ${horario.fin}hs`}
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  guardarEspecialidad("");
                }}
              >
                OK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      );
    }
  }

  return (
    <>
      <Divider />
      <Box my={3}>
        <PerfilPaciente paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
      </Box>
      <Divider />
      <h2>Seleccione la especialidad</h2>

      {loading ? (
        <LoadingBubbles />
      ) : (
        <>
          <List>
            {especialidadesPrincipales.map((especialidad, index) => (
              <ListItem
                button
                onClick={() => {
                  onEspecialidadClicked(especialidad.descripcion);
                }}
                key={index}
              >
                <ListItemIcon>
                  <CheckCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={especialidad.descripcion} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      onEspecialidadClicked(especialidad.descripcion);
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <p>O podés elegir una especialidad de la lista</p>
          <FormControl fullWidth style={{marginBottom: 50}}>
            <InputLabel htmlFor="especialidad">
              Selecioná una especialidad
            </InputLabel>
            <Select
              value={especialidad}
              onChange={onEspecialidadChanged}
              label="Selecioná una especialidad"
              inputProps={{
                name: "especialidad",
              }}
            >
              {listaEspecialidadesApi.map((especialidad, index) => {
                return (
                  <MenuItem key={index} value={especialidad.descripcion}>
                    {especialidad.descripcion}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <div className={classes.buttonsRoot}>
            <Volver onClickHandler={() => history.goBack()} />
            <Siguiente
              disabled={especialidad === ""}
              onClickHandler={onSiguienteClicked}
            />
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(SeleccionarEspecialidad);
