import axios from "axios";
import {IEspecialidad} from "../Interfaces/IEspecialidad";
import {ISintoma} from "../Interfaces/ISintoma";
import firebase from "firebase";

const especialidadApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

especialidadApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
especialidadApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("Especialidad.ts");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);

export async function getEspecialidades(
  sexo: "F" | "M",
  edad: number,
  tipo: "espontanea" | "programada",
  plan?: string | null
): Promise<Array<IEspecialidad>> {
  let tempEdad = edad.toString().split(" ");
  let edadVerdadera = "";
  if (tempEdad[1] === "meses" || tempEdad[1] === "mes") {
    edadVerdadera = "0";
  } else {
    edadVerdadera = tempEdad[0];
  }

  if (plan) {
    let {data, statusText} = await especialidadApi.get<Array<IEspecialidad>>(
      `/especialidades?sexo=${sexo}&edad=${edadVerdadera}&tipo=${tipo}&plan=${plan}`
    );
    return data;
  } else {
    let {data, statusText} = await especialidadApi.get<Array<IEspecialidad>>(
      `/especialidades?sexo=${sexo}&edad=${edadVerdadera}&tipo=${tipo}`
    );
    return data;
  }
}

export async function getEspecialidadesInter(): Promise<Array<string>> {
  const {data, statusText} = await especialidadApi.get<Array<string>>(
    `/getEspecialidadesInter`
  );
  return data;
}

export async function getPreguntasTriaje(
  especialidad: string
): Promise<Array<ISintoma>> {
  const {data} = await especialidadApi.get<Array<ISintoma>>(
    `/PreguntasTriaje?especialidad=${especialidad}`
  );
  return data;
}

export async function obtenerEspecialidadesEspontaneas(): Promise<
  Array<IEspecialidad>
> {
  const {data, statusText} = await especialidadApi.get<Array<IEspecialidad>>(
    `/especialidadesEspontaneas`
  );
  return data;
}

export async function agregarPreguntasAlSintoma(
  especialidad: string,
  sintoma: string,
  preguntas: Array<{pregunta: string; cerrada: boolean}>
): Promise<string> {
  let formData = new FormData();
  formData.append("triaje", especialidad);
  formData.append("sintoma", sintoma);
  preguntas.forEach((pregunta) => {
    let preguntaText = pregunta.pregunta;
    if (pregunta.cerrada) {
      preguntaText = "--" + preguntaText;
    }
    formData.append("preguntas", preguntaText);
  });
  const {data} = await especialidadApi.post(
    `cargarNuevoSintomaTriaje`,
    formData
  );
  return data;
}

export async function cargarNuevoSintoma(
  especialidad: string,
  sintoma: string,
  preguntas: Array<{pregunta: string; cerrada: boolean}>,
  imagen: File
): Promise<string> {
  let formData = new FormData();
  formData.append("triaje", especialidad);
  formData.append("sintoma", sintoma);
  formData.append("file", imagen);
  preguntas.forEach((pregunta) => {
    let preguntaText = pregunta.pregunta;
    if (pregunta.cerrada) {
      preguntaText = "--" + preguntaText;
    }
    formData.append("preguntas", preguntaText);
  });
  const {data} = await especialidadApi.post(
    `cargarNuevoSintomaTriaje`,
    formData
  );
  return data;
}

export async function cambiarImagenSintoma(
  especialidad: string,
  sintoma: string,
  imagen: File
): Promise<string> {
  let formData = new FormData();
  formData.append("triaje", especialidad);
  formData.append("sintoma", sintoma);
  formData.append("file", imagen);
  const {data} = await especialidadApi.post(
    `cargarNuevoSintomaTriaje`,
    formData
  );
  return data;
}

export async function deletePreguntaSintoma(
  especialidad: string,
  sintoma: string,
  pregunta: string
): Promise<string> {
  const enviar = {
    triaje: especialidad,
    sintoma: sintoma,
    pregunta: pregunta,
  };
  const {data} = await especialidadApi.delete(`borrarTriaje`, {data: enviar});
  return data;
}

export async function deleteSintoma(
  especialidad: string,
  sintoma: string
): Promise<string> {
  const enviar = {
    triaje: especialidad,
    sintoma: sintoma,
  };
  const {data} = await especialidadApi.delete(`borrarTriaje`, {data: enviar});
  return data;
}
