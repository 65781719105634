import axios from "axios";
import firebase from "firebase";
import {getFirebase} from "../db";
import {IAceptarTYC} from "../Interfaces/IAceptarTYC";
import {ICrearCitaProgramadaFinochietto} from "../Interfaces/ICrearCitaProgramadaFinochietto";
import {IEspecialidad} from "../Interfaces/IEspecialidad";
import {IFinanciador} from "../Interfaces/IFinanciador";
import {IPacienteFinochietto} from "../Interfaces/IPacienteFinochietto";
import {IProfesional} from "../Interfaces/IProfesional";
import {IResultadoBusquedaCitaFinochietto} from "../Interfaces/IResultadoBusquedaCitaFinochietto";
import {ITurnoFinochietto} from "../Interfaces/ITurnoFinochietto";
import {IUsuarioSesion} from "../Interfaces/IUsuarioSesion";
import {convertDateToDatestring} from "../utils/dateTimeHelper";

const finochiettoApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

finochiettoApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

finochiettoApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("Finochietto.ts");
      localStorage.setItem("LoggedIn", "false");
    } else if (error.response.status === 400) {
      localStorage.setItem("Error400", error.response.data);
    }
    return error;
  }
);

export async function obtenerFinanciadores(): Promise<Array<IFinanciador>> {
  const {data} = await finochiettoApi.get<Array<IFinanciador>>(
    `/obtenerFinanciadores?activo=S`
  );
  return data;
}

export async function especialidadesFinochietto(): Promise<
  Array<IEspecialidad>
> {
  const {data} = await finochiettoApi.get<Array<IEspecialidad>>(
    `/especialidadesFinochietto`
  );
  return data;
}

export async function crearCitaProgramadaFinochietto(
  datos: ICrearCitaProgramadaFinochietto
): Promise<string> {
  const {data} = await finochiettoApi.post<string>(
    `/crearCitaProgramadaFinochietto`,
    datos
  );
  return data;
}

export async function obtenerTurnoFinochietto(
  codigo: string
): Promise<ITurnoFinochietto> {
  const {data} = await finochiettoApi.get<ITurnoFinochietto>(
    `/obtenerTurnoFinochietto?cs=${codigo}`
  );
  return data;
}

export async function setEnEspera(
  usuarioSesion: IUsuarioSesion
): Promise<{estado: string; tiempoEsperaPromedio: number}> {
  const {data} = await finochiettoApi.post(
    `/SetEnEsperaFinochietto`,
    usuarioSesion
  );
  return data;
}
export async function enviarEmailRecordatorio(
  cuil: string,
  sesion: string
): Promise<string> {
  const {data} = await finochiettoApi.get(
    `/enviarEmailRecordatorio?sesion=${sesion}&cuil=${cuil}`
  );
  return data;
}

export async function buscarCitasFinochietto(
  especialidad?: string,
  profesional?: string,
  fechaDesde?: Date | null,
  fechaHasta?: Date | null,
  nombre?: string,
  apellido?: string,
  operador?: string,
  cuil?: string
): Promise<Array<IResultadoBusquedaCitaFinochietto>> {
  let query = "/buscarCitasFinochietto?";
  let ampersand = false;
  if (especialidad) {
    query += `especialidad=${especialidad!}`;
    ampersand = true;
  }
  if (profesional) {
    query += ampersand
      ? `&profesional=${profesional!}`
      : `profesional=${profesional!}`;
    ampersand = true;
  }
  if (fechaDesde) {
    query += ampersand
      ? `&fechaDesde=${convertDateToDatestring(fechaDesde!)}`
      : `fechaDesde=${convertDateToDatestring(fechaDesde!)}`;
    ampersand = true;
  } else {
    query += ampersand ? `&fechaDesde=1980/01/01` : `fechaDesde=1980/01/01`;
    ampersand = true;
  }
  if (fechaHasta) {
    query += ampersand
      ? `&fechaHasta=${convertDateToDatestring(fechaHasta!)}`
      : `fechaHasta=${convertDateToDatestring(fechaHasta!)}`;
    ampersand = true;
  } else {
    query += ampersand
      ? `&fechaHasta=${convertDateToDatestring(new Date())}`
      : `fechaHasta=${convertDateToDatestring(new Date())}`;
    ampersand = true;
  }
  if (nombre) {
    query += ampersand ? `&nombre=${nombre!}` : `nombre=${nombre!}`;
    ampersand = true;
  }
  if (apellido) {
    query += ampersand ? `&apellido=${apellido!}` : `apellido=${apellido!}`;
    ampersand = true;
  }
  if (operador) {
    query += ampersand ? `&operador=${operador!}` : `operador=${operador!}`;
    ampersand = true;
  }
  if (cuil) {
    query += ampersand ? `&cuil=${cuil!}` : `cuil=${cuil!}`;
    ampersand = true;
  }

  const {data} = await finochiettoApi.get(query);
  return data;
}

export async function obtenerListaProfesionalesFinochietto(): Promise<
  Array<IProfesional>
> {
  const {data} = await finochiettoApi.get<Array<IProfesional>>(
    `/ObtenerListaProfesionalesFinochietto`
  );
  return data;
}

export async function aceptarTerminosyCondiciones(
  datos: IAceptarTYC
): Promise<string> {
  const {data} = await finochiettoApi.post<string>(
    `/AceptarTerminosyCondiciones`,
    datos
  );
  return data;
}

export async function devolverDatosPaciente(
  cuil: string,
  idFinanciador: number
): Promise<{
  encontrado: boolean;
  mensaje?: string;
  paciente?: IPacienteFinochietto;
}> {
  const {data} = await finochiettoApi.get(
    `/devolverDatosPaciente?cuil=${cuil}&financiador=${idFinanciador}`
  );
  return data;
}
