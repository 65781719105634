import React from "react";
import {useHistory} from "react-router-dom";
//MATERIAL-UI
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import {IServicio} from "../../../Interfaces/IServicio";
import imgCamDoctor1 from "../../../assets/images/imgCamDoctor1.png";
import {
  HistoryOutlined,
  ScheduleOutlined,
  WorkOutlineOutlined,
  InsertChartOutlinedRounded,
  LocalPharmacyOutlined,
} from "@material-ui/icons";

const servicios: ReadonlyArray<IServicio> = [
  {
    id: "GESTIONAR_CONSULTORIOS",
    primaryText: "Gestionar consultorios médicos",
    secondaryText: "Manejar la modalidad de atención. Facturación Unificada",
    icon: WorkOutlineOutlined,
    to: "/dashboard/admin/administrar-consultorios-medicos",
  },
  {
    id: "GESTIONAR_ESPONTANEAS",
    primaryText: "Gestionar videoconsultas de guardia",
    secondaryText: "Manejar la atención del consultorio de demanda espontánea",
    icon: VideocamOutlinedIcon,
    to: "/dashboard/admin/administrar-guardia",
  },
  {
    id: "GESTIONAR_SINTOMAS",
    primaryText: "Gestionar sintomas",
    secondaryText:
      "Agregar, quitar y modificar sintomas y preguntas del triaje",
    icon: WorkOutlineOutlined,
    to: "/dashboard/admin/administrar-sintomas",
  },
  {
    id: "GESTIONAR_HORARIO_ATENCION",
    primaryText: "Gestionar horario de atención de guardia",
    secondaryText: "Establecer, modificar o quitar horarios de atención",
    icon: ScheduleOutlined,
    to: "/dashboard/admin/administrar-horarios",
  },
  {
    id: "GESTIONAR_HISTORIAL",
    primaryText: "Historial de Consultas",
    secondaryText: "Buscar pacientes y consultar su historial médico",
    icon: HistoryOutlined,
    to: "/dashboard/admin/administrar-historial-offline",
  },
  // {
  //     id: 'PLACEHOLDER_FARMACIA',
  //     primaryText: "Revisar solicitudes de medicamentos",
  //     secondaryText: "Estado de los pedidos de medicamentos de los pacientes",
  //     icon: LocalPharmacyOutlined,
  //     to: "/dashboard/admin/farmacia"
  // },
  {
    id: "REVISAR_CALIFICACIONES_PROFESIONALES",
    primaryText: "Revisar calificaciones profesionales",
    secondaryText:
      "Gestionar las calificaciones para el cálculo de valoraciones",
    icon: InsertChartOutlinedRounded,
    to: "/dashboard/admin/administrar-calificaciones-profesionales",
  },
];

const WelcomeAdmin = () => {
  const history = useHistory();

  const onItemClicked = (servicio: IServicio) => {
    history.push(servicio.to);
  };

  const serviciosList = servicios.map((servicio, index) => (
    <Box key={index} borderBottom="1px solid #efefef">
      <ListItem
        button
        style={{paddingTop: 20, paddingBottom: 20}}
        onClick={() => onItemClicked(servicio)}
      >
        <ListItemIcon>
          <servicio.icon fontSize="large" />
        </ListItemIcon>
        <ListItemText
          primary={servicio.primaryText}
          secondary={servicio.secondaryText}
        />
        <ListItemSecondaryAction onClick={() => onItemClicked(servicio)}>
          <IconButton edge="end">
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  ));

  return (
    <Grid container justify="center" spacing={3}>
      <Grid item xs={12} md={7} lg={7}>
        <List>{serviciosList}</List>
      </Grid>
      <Grid item xs={10} md={5} lg={5}>
        <img src={imgCamDoctor1} alt="imagen app mobile" width="100%" />
      </Grid>
    </Grid>
  );
};

export default WelcomeAdmin;
