import axios from "axios";
import {IPaciente} from "../Interfaces/IPaciente";
import {IRespuestaObtenerDatosUsuarioCS} from "../Interfaces/IRespuestaObtenerDatosUsuarioCS";
import firebase from "firebase";

const pacientesApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

pacientesApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
pacientesApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("Paciente.ts");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);

export async function fetchPacientes(ID_GRUPO_FAMILIAR: number) {
  const {data} = await pacientesApi.get<IPaciente[]>(
    `/getAsociados?id=${ID_GRUPO_FAMILIAR}`
  );
  return data;
}

export async function validateDNI(dni: string) {
  const {data} = await pacientesApi.post<IPaciente[]>(
    `/validarDNI`,
    {
      dni,
    }
  );
  return data;
}

export async function obtenerDatosUsuarioCs(codigoSeguro: string) {
  const {data} = await pacientesApi.get<IRespuestaObtenerDatosUsuarioCS>(
    `/ObtenerDatosUsuarioCs?cs=${codigoSeguro}`
  );
  return data;
}

export async function getIdGF(dni: string) {
  const {data} = await pacientesApi.get<{idGF: number}>(`/getIdGF?dni=${dni}`);
  return data;
}

export async function obtenerDatosUsuario(dni: string, cuil: string) {
  const {data} = await pacientesApi.get<{paciente: IPaciente}>(
    `/ObtenerDatosUsuario?dni=${dni}&cuil=${cuil}`
  );
  return data;
}

//export default pacientesApi;
