import React from "react";
import {useTheme} from "@material-ui/core";
import ReactLoading from "react-loading";

interface PublicProps {
  size?: string | number;
  color?: string;
  useCircle?: boolean;
}

const root = {
  display: "flex",
  justifyContent: "center",
};

const LoadingBubbles: React.FC<PublicProps> = (props) => {
  const {size, color, useCircle} = props;
  const theme = useTheme();

  return (
    <div style={root}>
      <ReactLoading
        type={useCircle ? "spinningBubbles" : "bubbles"}
        color={color ? color : theme.paletaColores.naranja.tercero}
        width={size ? size : "50px"}
        height={size ? size : "50px"}
      />
    </div>
  );
};

export default LoadingBubbles as React.ComponentType<PublicProps>;
