import React, {useState} from "react";
import {
  Switch,
  Divider,
  WithStyles,
  withStyles,
  Toolbar,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Button,
  Collapse,
  TextField,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {styles} from "./ValoracionesPorPacienteStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {
  ChatBubbleOutline,
  InsertChartOutlinedRounded,
  ReplayOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
  SearchOutlined,
  StarOutlined,
  CloseOutlined,
} from "@material-ui/icons";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  selectedGridRowsSelector,
} from "@mui/x-data-grid";
import {IProfesionalPromedio} from "../../../../Interfaces/IProfesionalPromedio";
import {
  aplicarValoracion,
  viewOfCommentsByProf,
} from "../../../../apis/valoracionAPI";
import {IProfesionalValoraciones} from "../../../../Interfaces/IProfesionalValoraciones";
import {_getDateAsIs} from "../../../../utils/dateTimeHelper";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {ISesionEstado} from "../../../../Interfaces/ISesionEstado";

interface OwnProps {
  medico: IProfesionalPromedio;
  espontanea: boolean;
  setPromedio: (medico: string, newPromedio: number) => void;
}

type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const ValoracionesPorPaciente: React.FC<Props> = (props) => {
  const {classes} = props;
  const {medico, espontanea, setPromedio} = props;
  const history = useHistory();
  const theme = useTheme();

  interface Row {
    id: number;
    fecha_consulta: string;
    calificacion: number;
    paciente: string;
    comentarios: string;
    apli_valoracion: boolean;
  }

  const [datos, setDatos] = React.useState<
    IProfesionalValoraciones | undefined
  >(undefined);
  const [rows, setRows] = React.useState<Array<Row>>([]);
  const [loading, setLoading] = React.useState(false);

  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(-1);

  const [datosACambiar, setDatosACambiar] = React.useState<
    Array<ISesionEstado>
  >([]);

  const selectedDato =
    selectedId !== -1 ? datos?.comentarios[selectedId] : undefined;

  const buscarDatos = () => {
    setLoading(true);
    viewOfCommentsByProf(medico.email, espontanea)
      .then((resp) => {
        setDatos(resp);

        let temp: Array<Row> = [];

        resp.comentarios.forEach((comentario, iComentario) => {
          let row: Row = {
            id: iComentario,
            paciente: comentario.paciente,
            calificacion: comentario.calificacion,
            comentarios: comentario.nota,
            fecha_consulta: _getDateAsIs(comentario.fecha),
            apli_valoracion: comentario.estado,
          };
          temp.push(row);
        });

        setRows(temp);
        setPromedio(medico.email, resp.promedio);
        setLoading(false);
        setDetailsOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    buscarDatos();
  }, []);

  const handleChange = (idSesion: string, checked: boolean) => {
    let datoEnDescargados = datos!.comentarios.find(
      (x) => x.idsession === idSesion
    );
    const indexDatoEnviar = datosACambiar.findIndex(
      (x) => x.sesion === idSesion
    );
    setDetailsOpen(false);

    if (datoEnDescargados!.estado !== checked) {
      //difiere, tiene que estar en la lista a enviar
      if (indexDatoEnviar === -1) {
        let temp = [...datosACambiar];
        temp.push({sesion: idSesion, estado: checked});
        setDatosACambiar(temp);
      } else {
        let temp = [...datosACambiar];
        temp[indexDatoEnviar].estado = checked;
        setDatosACambiar(temp);
      }
    } else {
      //coincide, no tiene que estar en la lista a enviar
      if (indexDatoEnviar !== -1) {
        let temp = [...datosACambiar];
        temp.splice(indexDatoEnviar, 1);
        setDatosACambiar(temp);
      }
    }
    // setLoading(true);
    // aplicarValoracion(idSesion, checked)
    //     .then((respuestaCambio) => {
    //         buscarDatos();
    //     })
    //     .catch(e => { console.log(e); })
  };

  const enviarDatos = () => {
    setLoading(true);
    aplicarValoracion({sesiones: datosACambiar})
      .then((respuestaCambio) => {
        setDatosACambiar([]);
        buscarDatos();
      })
      .catch((e) => {
        console.log(e);
      });
    // aplicarValoracion(idSesion, checked)
    //     .then((respuestaCambio) => {
    //         buscarDatos();
    //     })
    //     .catch(e => { console.log(e); })
  };

  const renderDetailsButton = (params: GridRenderCellParams) => {
    let indexDatoDescargado = params.getValue(params.id, "id") as number;
    let idSesion = datos!.comentarios[indexDatoDescargado].idsession;

    let checked: boolean;
    let indexDatoCambiar = datosACambiar.findIndex(
      (x) => x.sesion === idSesion
    );
    let enviando = false;
    if (indexDatoCambiar === -1) {
      checked = params.getValue(params.id, "apli_valoracion") as boolean;
    } else {
      enviando = true;
      checked = datosACambiar[indexDatoCambiar].estado;
    }
    return (
      <>
        <Switch
          checked={checked}
          onChange={(event, checked) => {
            handleChange(idSesion, checked);
          }}
          name="checkedA"
          inputProps={{"aria-label": "secondary checkbox"}}
        />
        {enviando && <>*</>}
      </>
    );
  };

  const [filtros, setFiltros] = useState(false);

  const columns: GridColDef[] = [
    {field: "id", headerName: "ID", minWidth: 50, width: 50},
    {
      field: "fecha_consulta",
      headerName: "Fecha",
      width: 120,
      minWidth: 120,
    },
    {
      field: "calificacion",
      headerName: "Calif",
      width: 110,
      minWidth: 110,
    },
    {
      field: "paciente",
      headerName: "Paciente",
      minWidth: 250,
      width: 150,
    },
    {
      field: "comentarios",
      headerName: "Comentarios",
      minWidth: 70,
      flex: 600,
    },
    {
      field: "apli_valoracion",
      headerName: "Aplica",
      renderCell: renderDetailsButton,
      width: 120,
      minWidth: 120,
    },
  ];

  // const rows = [
  //     { id: 1, fecha_consulta: '10/10/2021', calificacion: 4.5, paciente: 'Pepe', comentarios: 'Buen profesional' },
  //     { id: 2, fecha_consulta: '10/10/2021', calificacion: 5, paciente: 'Pablo', comentarios: 'Medio medio' },
  // ];

  if (loading) {
    return (
      <>
        <div
          style={{
            height: 400,
            width: "100%",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingBubbles />
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{height: 400, width: "100%"}}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          onRowClick={(params) => {
            const id = params.getValue(params.id, "id") as number;

            setDetailsOpen(true);
            setSelectedId(id);
          }}
        />
      </div>
      <Toolbar>
        <div style={{flexGrow: 1}} />
        <Button
          disabled={datosACambiar.length === 0}
          variant="contained"
          disableElevation
          color="primary"
          onClick={enviarDatos}
        >
          {datosACambiar.length > 0
            ? `Enviar cambios en ${datosACambiar.length} datos`
            : `No hay cambios`}
        </Button>
      </Toolbar>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={detailsOpen}
        onClose={() => {
          setDetailsOpen(false);
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <span style={{marginRight: 5}}>Profesional:</span>
              <span style={{color: theme.paletaColores.naranja.segundo}}>
                {datos?.Nombre}
              </span>
            </div>
            <div style={{flexGrow: 1}} />
            <IconButton
              onClick={() => {
                setDetailsOpen(false);
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>{`Paciente: ${selectedDato?.paciente}`}</Typography>
            <Typography>
              {`Calificacion: ${selectedDato?.calificacion}`}
            </Typography>
            <Typography>
              {`Fecha: ${
                selectedDato?.fecha ? _getDateAsIs(selectedDato?.fecha!) : ""
              }`}
            </Typography>
            <Typography>
              {selectedDato?.nota ? selectedDato?.nota : "(Sin comentarios)"}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ValoracionesPorPaciente);
