import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  WithStyles,
  useTheme,
  withStyles,
  Box,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {ITurnoAgendado} from "../../../Interfaces/ITurnoAgendado";
import LoadingBubbles from "../../../components/LoadingBubbles/LoadingBubbles";
import VerFichaPaciente from "./VerFichaPaciente";
import {styles} from "./TurnosDiaMedicoStyles";

interface OwnProps {
  fechaSeleccionada: Date;
  citasAgendadas: Array<ITurnoAgendado | string> | null;
  atenderPaciente: (turno: ITurnoAgendado) => void;
}
type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const TurnosDiaMedico: React.FC<Props> = (props) => {
  const {classes, citasAgendadas, fechaSeleccionada, atenderPaciente} = props;
  const theme = useTheme();
  const [citasLoading, setCitasLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [paciente, setPaciente] = useState({
    nombre: "",
    cuil: "",
  });

  useEffect(() => {
    if (citasAgendadas === null) {
      setCitasLoading(true);
    } else if (citasAgendadas.length >= 0) {
      setCitasLoading(false);
    }
  }, [citasAgendadas]);

  const buscar = (cuil: string, nombre: string) => {
    setPaciente({
      cuil: cuil,
      nombre: nombre,
    });
    setShowDialog(true);
  };

  const capitalizeWords = (string: string | undefined) => {
    const arr = string?.split(" ") || [];
    const capitalized = arr.map(
      (x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()
    );
    return capitalized.join(" ");
  };

  const initialsName = (string: string) => {
    if (string === undefined) return "";
    return string
      .replace(/[^a-zA-Z ]/g, "")
      .split(" ")
      .map((n) => n[0])
      .join("")
      .substring(0, 3);
  };

  const turnosList = citasLoading ? (
    <div style={{width: "100%"}}>
      <LoadingBubbles />
    </div>
  ) : (
    <>
      {citasAgendadas?.length === 0 ? (
        <ListItem>
          <ListItemText primary="No tiene turnos para la fecha seleccionada" />
        </ListItem>
      ) : (
        <>
          {citasAgendadas?.map((posibleTurno, indexTurno) => {
            if (!posibleTurno) return null;
            const turno = posibleTurno as ITurnoAgendado;
            return (
              <div key={indexTurno}>
                <ListItem>
                  <Avatar
                    className={
                      turno?.estado !== "Activa"
                        ? classes.avatarPacienteDisabled
                        : classes.avatarPaciente
                    }
                  >
                    <Typography>
                      {initialsName(turno?.nombrePaciente!)}
                    </Typography>
                  </Avatar>
                  <div
                    style={{
                      flexDirection: "column",
                      flex: "auto",
                      padding: "8px 0 0 16px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={
                        turno.estado !== "Activa"
                          ? classes.nombrePacienteDisabled
                          : classes.nombrePaciente
                      }
                    >
                      {capitalizeWords(turno.nombrePaciente)}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        turno.estado !== "Activa"
                          ? classes.horasPacienteDisabled
                          : classes.horasPaciente
                      }
                    >
                      {turno.turno.horas} hs.
                    </Typography>
                    <Box
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Link
                        onClick={() =>
                          buscar(turno.paciente!, turno.nombrePaciente!)
                        }
                        underline="hover"
                        align="left"
                        className={
                          turno.estado !== "Activa"
                            ? classes.verHistorialDisabled
                            : classes.verHistorial
                        }
                      >
                        {"Ver historial"}
                      </Link>
                      {turno.enEspera ? (
                        <Link
                          underline="hover"
                          align="right"
                          onClick={() => {
                            atenderPaciente(turno);
                          }}
                          className={
                            turno.estado !== "Activa"
                              ? classes.verHistorialDisabled
                              : classes.verHistorial
                          }
                        >
                          {"Atender paciente"}
                        </Link>
                      ) : null}
                    </Box>
                  </div>
                </ListItem>
                <Divider className={classes.divider} />
              </div>
            );
          })}
        </>
      )}
    </>
  );

  return (
    <>
      <Grid item container>
        <Card style={{flexGrow: 1, maxHeight: "672px"}}>
          <CardContent
            style={{
              backgroundColor: theme.paletaColores.fondo.primero,
              padding: 0,
            }}
          >
            <Toolbar variant="dense">
              <Typography variant="subtitle1" style={{fontWeight: "bold"}}>
                {/* Turnos del día */}
                {`Turnos para el dia ${
                  fechaSeleccionada.toLocaleDateString() ===
                  new Date().toLocaleDateString()
                    ? `de hoy`
                    : fechaSeleccionada.toLocaleDateString()
                }`}
              </Typography>
            </Toolbar>
          </CardContent>
          <CardContent className={classes.turnosContainer}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{paddingTop: 0}}
            >
              {turnosList}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <VerFichaPaciente
        open={showDialog}
        onClose={() => setShowDialog(false)}
        paciente={paciente}
      />
    </>
  );
};

export default withStyles(styles)(
  TurnosDiaMedico
) as React.ComponentType<PublicProps>;
