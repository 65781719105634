//
// usStillLoggedIn hook
//
import { useEffect, useState } from 'react';

export const getLocalStorage = (itemKey) => {
  const itemValue = localStorage.getItem(itemKey);
  if (itemValue) {
    return itemValue;
  }
  return undefined;
};

export const useLocalStorage = (item, refreshCycle = 100) => {
  const [localStorage, setLocalStorage] = useState(getLocalStorage(item));

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(
      () => setLocalStorage(getLocalStorage(item)),
      refreshCycle,
    );

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, [refreshCycle, setInterval, clearInterval, setLocalStorage, getLocalStorage]);

  return localStorage;
};