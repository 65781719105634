import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  useTheme,
} from "@material-ui/core";
import {
  Grid,
  Button,
  Card,
  Typography,
  CardMedia,
  CardActions,
  Paper,
} from "@material-ui/core";
import {HistoryRounded, Person, PlayArrowRounded} from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import {
  setSesionLogeo,
  setUser,
  setSesionLogeoInstancia,
  setSaque,
} from "../../../../../store/user/userSlice";
import UserImg from "../../../../../assets/images/usuario.png";
import {IResultadoObtenerCitaSiguiente} from "../../../../../Interfaces/IResultadoObtenerCitaSiguiente";
import {
  asignarCitaInstantanea,
  desasignarCitaInstantanea,
  obtenerCitaSiguiente,
  recibirCita,
} from "../../../../../apis/citaInstantaneaAPI";
import {
  setCuilPacienteSeleccionado,
  setIdSalaVideollamada,
  setPassSalaVideollamada,
  setEspecialidad,
} from "../../../../../store/profesional-medico/guardia-medico/guardiaMedicoSlice";
import CardHistoriaClinica from "../../../../../components/CardHistoriaClinica/CardHistoriaClinica";
import {getFirestore} from "../../../../../db";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {Pagination} from "@material-ui/lab";
import {RootState} from "../../../../../store/rootReducer";
import {io} from "socket.io-client";
import {getCurrentTime} from "../../../../../hooks/useTime";
import {_calculateAgeDetailed} from "../../../../../utils/dateTimeHelper";

const ProximoPaciente: React.FC = () => {
  let unsubscribePacientes = () => {};
  const history = useHistory();
  const dispatch = useDispatch();
  const [citaSiguiente, setCitaSiguiente] = useState<
    IResultadoObtenerCitaSiguiente | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const [mensajeError, setMensajeError] = useState("");
  const [dialogErrorOpen, setDialogErrorOpen] = useState(false);

  const db = getFirestore();

  const {NOMBRE, APELLIDO, email, especialidades, matricula, sesionLogeo} =
    useSelector((state: RootState) => state.user);

  const [sessions, setSessions] = useState([
    {
      id: "1",
      sesion: "",
      especialidad: "",
      emailProfesional: "",
      usuario: "123",
    },
  ]);

  useEffect(() => {
    window.onpopstate = null;
    let unsubscribe: () => void = () => {};

    setLoading(true);

    let especialidadesStrings: Array<string> = [];
    especialidades!.forEach((esp) => {
      especialidadesStrings.push(esp.descripcion);
    });

    let stringEsps = "";
    especialidades!.forEach((esp) => {
      if (esp.consultaEspontanea) {
        stringEsps += `${esp.descripcion}, `;
      }
    });
    stringEsps = stringEsps.slice(0, stringEsps.length - 2);

    const socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
      reconnectionDelayMax: 10000,
      secure: true,
      query: {
        tipo: "profesional",
        usuario: email,
        nombre: NOMBRE,
        apellido: APELLIDO,
        matricula: matricula!.toString(),
        especialidad: stringEsps,
        estado: "Leyendo Historial Clinico",
        ambiente: `${process.env.REACT_APP_URL_PACIENTES}`,
        // "horaConexion": getCurrentTime().getTime().toString(),
        sesionLogeoID: sesionLogeo!,
      },
    });

    const preguntar = () => {
      console.log("empece");
      if (stringEsps.length > 0) {
        socket.emit(
          "enEspera",
          JSON.stringify({
            medico: email,
            //especialidad
            especialidades: stringEsps,
          })
        );
        socket.on("resEnEspera", (data) => {
          data = JSON.parse(data);
          if (data.medico === email) {
            if (data.status === 1) {
              // que si trajo un paciente
              let vali = data.sesion;
              asignarCitaInstantanea(vali.usuario, vali.id, email)
                .then((dataAsignar) => {
                  obtenerCitaSiguiente(vali.usuario, vali.id).then(
                    (dataCitaSiguiente) => {
                      dataCitaSiguiente.usuario = vali.usuario;
                      dataCitaSiguiente.sesion = vali.id;
                      dataCitaSiguiente.historialClinico?.sort((a, b) => {
                        return b.fecha._seconds - a.fecha._seconds;
                      });
                      setCitaSiguiente(dataCitaSiguiente);
                      setLoading(false);
                    }
                  );

                  unsubscribe = db
                    .collection("CitasInstantaneas")
                    .doc(vali.especialidad!)
                    .collection("sesiones")
                    .doc(vali.id)
                    .onSnapshot((datosSesion) => {
                      if (!datosSesion.exists) {
                        console.log("La sesion no existe");
                        return;
                      }
                      if (datosSesion!.data()!.estado === "C") {
                        alert(
                          "El paciente cancelo la cita.\nSerá redirigido a la guardia."
                        );
                        dispatch(setSaque(true));
                        history.push("/dashboard/guardia/vista-guardia");
                      } else {
                        console.log(
                          `El estado es ${datosSesion!.data()!.estado}`
                        );
                      }
                    });
                })
                .catch((e) => {
                  console.log(e);

                  setDialogErrorOpen(true);
                  setMensajeError(e.response.data);
                  setLoading(false);
                });
            } else {
              console.log("volver a la sala");
              dispatch(setSaque(true));
              history.push(`/dashboard/guardia/vista-guardia`);
            }
          }
        });
      } else {
        console.log("volver a la sala");
        dispatch(setSaque(true));
        history.push(`/dashboard/guardia/vista-guardia`);
      }
    };

    const activar = () => {
      preguntar();
    };

    activar();

    return () => {
      unsubscribePacientes();
      unsubscribe();
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickAtenderPaciente = async () => {
    dispatch(setIdSalaVideollamada(citaSiguiente!.sesion));
    dispatch(setPassSalaVideollamada(citaSiguiente!.password));
    dispatch(setCuilPacienteSeleccionado(parseInt(citaSiguiente!.usuario)));
    dispatch(setEspecialidad(citaSiguiente!.especialidad!));
    let usuarioSesion = {
      usuario: citaSiguiente!.usuario,
      sesion: citaSiguiente!.sesion,
    };
    setLoading(true);
    await recibirCita(usuarioSesion);
    setLoading(false);
    history.push(`/dashboard/guardia/consultorio-medico`);
  };

  const offsetHours = -(new Date().getTimezoneOffset() / 60);
  let offset = offsetHours < 0 ? "-" : "+";
  offset += Math.abs(offsetHours) < 10 ? "0" : "";
  offset += Math.floor(Math.abs(offsetHours));
  const offsetHourDecimal =
    Math.abs(offsetHours) - Math.floor(Math.abs(offsetHours));
  const offsetMinutes = offsetHourDecimal * 60;
  offset += offsetMinutes < 10 ? "0" : "";
  offset += offsetMinutes;

  const edad = citaSiguiente
    ? _calculateAgeDetailed(
        new Date(citaSiguiente!.fechaNacimiento.replace("+0000", offset))
      )
    : "";
  const dni = citaSiguiente
    ? citaSiguiente!.usuario.substring(2, citaSiguiente!.usuario.length - 1)
    : "";
  const triaje = citaSiguiente ? citaSiguiente!.triaje : null;

  const theme = useTheme();

  const elementosPerPag = 5;
  const [pagina, setPagina] = useState(0);

  if (loading) {
    return <LoadingBubbles />;
  }

  return (
    <>
      <Grid container>
        <Grid item container direction="column" xs>
          <Grid item>
            <Box>
              <Box>
                <h1>
                  <Person />
                  Próximo Paciente
                </h1>
              </Box>
            </Box>
            <Divider />
          </Grid>
          <Grid item container>
            <Grid item container xs>
              <Grid item container xs={12} lg={3}>
                <Grid item container xs={12}>
                  <Card elevation={0} style={{width: "100%"}}>
                    <CardMedia
                      image={UserImg}
                      style={{margin: "10px", height: "256px"}}
                    />

                    <CardActions>
                      <Grid direction="column" container spacing={1}>
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={loading}
                            onClick={onClickAtenderPaciente}
                            startIcon={<PlayArrowRounded />}
                            style={
                              loading
                                ? theme.buttons.okDisabledButton
                                : theme.buttons.okButton
                            }
                          >
                            <Typography style={{flex: 1}}>
                              Atender Paciente
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            fullWidth
                            onClick={() => {
                              if (citaSiguiente) {
                                setLoading(true);
                                desasignarCitaInstantanea(
                                  citaSiguiente.usuario,
                                  citaSiguiente.sesion,
                                  email
                                )
                                  .then((datosRecibidos) => {
                                    setLoading(false);
                                    dispatch(setSaque(true));
                                    history.push(
                                      "/dashboard/guardia/vista-guardia"
                                    );
                                  })
                                  .catch((e) => {
                                    console.log(e);
                                  });
                              } else {
                                dispatch(setSaque(true));
                                history.push(
                                  "/dashboard/guardia/vista-guardia"
                                );
                              }
                            }}
                            startIcon={<ArrowBackIosIcon />}
                            style={theme.buttons.cancelButton}
                          >
                            <Typography style={{flex: 1}}>Volver</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
              <Grid item container xs direction="column">
                <Grid item>
                  <Box m={1}>
                    <Typography variant="h5">
                      {citaSiguiente ? citaSiguiente!.nombre : ""}
                    </Typography>
                    <Typography variant="h6">
                      {edad}, DNI: {dni}
                    </Typography>
                    <Divider />
                    {/* <Typography variant="body1">
                                            <Grid container direction="row" alignItems="center">
                                                <AccessTimeIcon style={{ margin: "7px" }} />
                                                <Typography variant="body2">
                                                    Tiempo en espera: {tiempoEspera}
                                                </Typography>
                                            </Grid>
                                        </Typography>
                                        <Divider /> */}
                    <Typography variant="body1">
                      Motivo de consulta:{" "}
                      {citaSiguiente ? citaSiguiente!.motivo : ""}
                    </Typography>
                    <Divider />
                    {triaje?.map((elemento, indexElementoTriaje) => {
                      return (
                        <>
                          <Typography
                            variant="body2"
                            style={{fontWeight: "bold"}}
                          >
                            {elemento.pregunta}
                          </Typography>
                          <Typography variant="body2" paragraph color="primary">
                            {elemento.respuesta
                              ? elemento.respuesta
                              : "(no contesta)"}
                          </Typography>
                        </>
                      );
                    })}
                    <Divider />
                  </Box>
                </Grid>
                <Grid item>
                  <Box m={1}>
                    <Paper elevation={2}>
                      <Box m={1}>
                        <Box>
                          <Grid container item alignItems="center">
                            <HistoryRounded style={{margin: "7px"}} />
                            <Typography variant="h5">Historial</Typography>
                          </Grid>
                        </Box>
                        <h4>
                          Consultas anteriores:{" "}
                          {citaSiguiente
                            ? citaSiguiente!.historialClinico.length
                            : "0"}
                        </h4>
                        {citaSiguiente
                          ? citaSiguiente!.historialClinico.map(
                              (elementoHistorial, index) => {
                                if (
                                  index >= pagina * elementosPerPag &&
                                  index < (pagina + 1) * elementosPerPag
                                ) {
                                  return (
                                    <>
                                      <Divider key={index + "divider"} />
                                      <CardHistoriaClinica
                                        mostrarNotasPrivadas={true}
                                        key={index + "card"}
                                        elementoHistorial={elementoHistorial}
                                        elevation={0}
                                      />
                                    </>
                                  );
                                }
                                return null;
                              }
                            )
                          : null}
                      </Box>
                      {citaSiguiente ? (
                        <Grid container justify="center">
                          <Pagination
                            style={{margin: 10}}
                            count={Math.ceil(
                              citaSiguiente!.historialClinico.length /
                                elementosPerPag
                            )}
                            page={pagina + 1}
                            onChange={(event, value) => {
                              setLoading(true);
                              setTimeout(() => {
                                setPagina(value - 1);
                                setLoading(false);
                              }, 500);
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={dialogErrorOpen}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{mensajeError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setSaque(true));
              history.push("/dashboard/guardia/vista-guardia");
            }}
            variant="contained"
            disableElevation
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProximoPaciente;
