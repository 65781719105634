import axios from "axios";
import {IEspecialidad} from "../Interfaces/IEspecialidad";
import {ISintoma} from "../Interfaces/ISintoma";
import firebase from "firebase";
import {IMedicoFav} from "../Interfaces/IMedicoFav";

const medicosFavoritosApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

medicosFavoritosApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
medicosFavoritosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("MedicoFavorito.ts");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);

export async function agregarMedicoAFavoritos(
  usuario: string,
  medico: string,
  especialidad?: string,
  sesion?: string
): Promise<string> {
  const {data, statusText} = await medicosFavoritosApi.post<string>(
    `/agregarMedicoAFavoritos`,
    {
      usuario: usuario,
      medico: medico,
      especialidad: especialidad,
      sesion: sesion,
    }
  );
  return data;
}

export async function quitarMedicoDeFavoritos(
  usuario: string,
  medico: string,
  especialidad?: string,
  sesion?: string
): Promise<string> {
  const {data, statusText} = await medicosFavoritosApi.post<string>(
    `/quitarMedicoDeFavoritos`,
    {
      usuario: usuario,
      medico: medico,
      especialidad: especialidad,
      sesion: sesion,
    }
  );
  return data;
}

export async function esMedicoFavorito(
  usuario: string,
  medico: string,
  especialidad: string
): Promise<boolean> {
  const {data, statusText} = await medicosFavoritosApi.get<boolean>(
    `/esMedicoFavorito?medico=${medico}&usuario=${usuario}&especialidad=${especialidad}`
  );
  return data;
}

export async function obtenerMailMedicoAtendiendo(
  usuario: string
): Promise<string> {
  const {data, statusText} = await medicosFavoritosApi.get<string>(
    `/obtenerMailMedicoAtendiendo?usuario=${usuario}`
  );
  return data;
}

export async function getFavoritosDetallado(
  usuario: string
): Promise<{Medicos: Array<IMedicoFav>}> {
  const {data, statusText} = await medicosFavoritosApi.get(
    `getFavoritosDetallado?usuario=${usuario}`
  );
  return data;
}
