import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  Switch,
  Avatar,
  Card,
  CardHeader,
  Grid,
  TextField,
  useTheme,
  IconButton,
} from "@material-ui/core";
import {AddOutlined, DeleteOutlined} from "@material-ui/icons";

interface OwnProps {
  textoPregunta: string;
  setTextoPregunta: (texto: string) => void;
  preguntaCerrada: boolean;
  setPreguntaCerrada: (cerrada: boolean) => void;
  onBorrarPregunta: () => void;
}

type PublicProps = OwnProps;
type Props = PublicProps;

const CardNuevaPreguntaTriaje: React.FC<Props> = (props) => {
  const {
    textoPregunta,
    setTextoPregunta,
    preguntaCerrada,
    setPreguntaCerrada,
    onBorrarPregunta,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} style={{margin: "5px 0px 5px 0px"}}>
        <Card>
          <CardHeader
            avatar={
              <Avatar>
                <AddOutlined />
              </Avatar>
            }
            title={
              <TextField
                fullWidth
                multiline
                label="Nueva pregunta"
                value={textoPregunta}
                onChange={(event) => {
                  setTextoPregunta(event.target.value as string);
                }}
              />
            }
            subheader={
              <div style={{margin: "10px 0px 10px 0px"}}>
                <Switch
                  checked={preguntaCerrada}
                  onChange={(event) => {
                    setPreguntaCerrada(event.target.checked);
                  }}
                />
                {preguntaCerrada
                  ? "Pregunta cerrada (se responde con Si/No)"
                  : "Pregunta abierta (se responde con texto libre)"}
              </div>
            }
            action={
              <IconButton onClick={onBorrarPregunta}>
                <DeleteOutlined />
              </IconButton>
            }
          />
        </Card>
      </Grid>
    </>
  );
};

export default CardNuevaPreguntaTriaje as React.ComponentType<PublicProps>;
