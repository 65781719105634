import React, {useEffect, useState} from "react";
import {
  List,
  ListItem,
  Divider,
  WithStyles,
  withStyles,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  ListItemText,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {styles} from "./ContainerBusquedaPacienteHistorialStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {
  CalendarTodayOutlined,
  SettingsOutlined,
  SearchOutlined,
  HistoryOutlined,
} from "@material-ui/icons";
import {IProfesional} from "../../../../Interfaces/IProfesional";
import {obtenerListaProfesionales} from "../../../../apis/adminAPI";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {useDispatch} from "react-redux";
import {setEspecialidades} from "../../../../store/administrador/administrar-consultorios-medicos/administrarConsultoriosMedicosSlice";
import {obtenerListaProfesionalesFinochietto} from "../../../../apis/finochiettoAPI";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import BusquedaPacienteHistorial from "../../../../components/BusquedaPacienteHistorial/BusquedaPacienteHistorial";

const ContainerBusquedaPacienteHistorial: React.FC<
  WithStyles<typeof styles>
> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <Card style={{flexGrow: 1, marginBottom: 20}}>
        <CardHeader
          avatar={
            <Avatar>
              <HistoryOutlined />
            </Avatar>
          }
          title={<Typography variant="h6">Historial de Consultas</Typography>}
        />
        <Divider />
        <CardContent
          style={{backgroundColor: theme.paletaColores.fondo.segundo}}
        >
          <Card style={{flexGrow: 1, marginBottom: 20}}>
            <CardContent>
              <BusquedaPacienteHistorial />
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <div className={classes.buttonsRoot}>
        <Volver
          onClickHandler={() => {
            history.goBack();
          }}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(ContainerBusquedaPacienteHistorial);
