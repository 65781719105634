import {
  Card,
  CardContent,
  Link,
  Toolbar,
  Typography,
  useTheme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import CalendarMedico from "./CalendarMedico";
import {ITurnoAgendado} from "../../../Interfaces/ITurnoAgendado";
import LoadingBubbles from "../../../components/LoadingBubbles/LoadingBubbles";
import {RootState} from "../../../store/rootReducer";
import {
  convertDateToDatestring,
  convertDiaHoraToDate,
} from "../../../utils/dateTimeHelper";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {styles} from "./TurnosDiaMedicoStyles";
import ring from "../../../../src/assets/sfx/notif_medico_guardia.wav";
const urlReporteAusencias =
  "https://docs.google.com/forms/d/e/1FAIpQLSeVr7zVFio5U6B8UGUSsnrGHvAhixyB7IScry-xkHXNu_uUsg/viewform";
interface OwnProps {
  proximoTurno: ITurnoAgendado | undefined | null;
  fechaSeleccionada: Date;
  onTurnoExpired: () => void;
  onChangeFecha: (fecha: Date) => void;
}

interface Time {
  dias: number;
  horas: string;
  minutos: string;
  segundos: string;
  fecha?: string;
}

type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const SectionCalendario: React.FC<Props> = (props) => {
  const {
    classes,
    proximoTurno,
    fechaSeleccionada,
    onTurnoExpired,
    onChangeFecha,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const [tiempoRestante, setTiempoRestante] = React.useState<Time | null>(null);
  const flagTurnoExpirado = React.useRef(false);
  const [countdownFiveMinutes, setCountdownFiveMinutes] = React.useState(false);
  const ringAudio = new Audio(ring);

  const EditarAgenda = () => {
    localStorage.setItem("LastItemClicked", "GESTIONAR_CONSULTORIO");
    history.push("/dashboard/administrar-consultorio");
  };

  useEffect(() => {
    if (proximoTurno) {
      const tiempoRestante = calcTiempoRestante();
      if (tiempoRestante.dias === 0) {
        const interval = setInterval(() => {
          setTiempoRestante(calcTiempoRestante());
        }, 1000);

        return () => clearInterval(interval);
      } else {
        const fechaTurno =
          proximoTurno.turno.día.split("/")[2] +
          "/" +
          proximoTurno.turno.día.split("/")[1];
        setTiempoRestante({...tiempoRestante, fecha: fechaTurno});
      }
    } else if (proximoTurno === null) {
      setTiempoRestante({
        dias: 0,
        horas: "00",
        minutos: "00",
        segundos: "00",
      });
    }
  }, [/*tiempoRestante,*/ proximoTurno]);

  const calcTiempoRestante = () => {
    try {
      const fechaTurno = convertDiaHoraToDate({
        día: proximoTurno!.turno.día,
        horas: proximoTurno!.turno.horas,
      });
      const fechaActual = new Date().getTime();
      const tiempoFaltante = fechaTurno.getTime() - fechaActual;

      const days = Math.floor(tiempoFaltante / (1000 * 60 * 60 * 24));
      const hours = zeroPad(
        Math.floor((tiempoFaltante % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      const minutes = zeroPad(
        Math.floor((tiempoFaltante % (1000 * 60 * 60)) / (1000 * 60))
      );
      const seconds = zeroPad(
        Math.floor((tiempoFaltante % (1000 * 60)) / 1000)
      );
      if (Number(minutes) < 5 && hours === "00") {
        setCountdownFiveMinutes(true);
      }
      if (Number(minutes) === 5 && hours === "00" && seconds === "00") {
        ringAudio.play();
      }
      if (
        (hours === "00" && minutes === "00" && seconds === "00") ||
        tiempoFaltante < 0
      ) {
        if (!flagTurnoExpirado.current) {
          flagTurnoExpirado.current = true;
          setCountdownFiveMinutes(false);
          onTurnoExpired();
        }
        return {
          dias: 0,
          horas: "00",
          minutos: "00",
          segundos: "00",
        };
      } else {
        flagTurnoExpirado.current = false;
      }
      return {
        dias: days,
        horas: hours,
        minutos: minutes,
        segundos: seconds,
      };
    } catch (e) {
      console.log(e);
      return {
        dias: 0,
        horas: "00",
        minutos: "00",
        segundos: "00",
      };
    }
  };

  const zeroPad = (num: Number) => {
    return ("0" + num.toFixed(0)).slice(-2);
  };

  return (
    <Card style={{flexGrow: 1}}>
      <CardContent
        style={{display: "flex", flexDirection: "column", padding: 0}}
      >
        <Toolbar variant="dense">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flex: "auto",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" style={{fontWeight: "bold"}}>
              {tiempoRestante?.fecha
                ? "El próximo turno es el:"
                : "El próximo turno es en:"}
            </Typography>
            <AccessAlarmIcon />
          </div>
        </Toolbar>
        <div style={{padding: "0 16px"}}>
          <Typography
            variant="h4"
            style={{
              color: theme.paletaColores.negro.tercero,
              fontWeight:
                countdownFiveMinutes && !tiempoRestante?.fecha
                  ? "bold"
                  : "normal",
              fontSize: countdownFiveMinutes ? "40px" : "34px",
            }}
          >
            {tiempoRestante === null ? (
              <LoadingBubbles />
            ) : tiempoRestante.fecha ? (
              tiempoRestante.fecha
            ) : (
              `${tiempoRestante.horas}:${tiempoRestante.minutos}:${tiempoRestante.segundos}`
            )}
          </Typography>
          <hr className="MuiDivider-root" />
          <CalendarMedico
            fechaSeleccionada={fechaSeleccionada}
            onChangeDate={(fecha) => onChangeFecha(fecha)}
          />
        </div>
        <Link
          onClick={() => {
            // EditarAgenda();
            window.open(urlReporteAusencias, "_blank");
          }}
          underline="hover"
          align="right"
          style={{
            color: theme.paletaColores.naranja.segundo,
            cursor: "pointer",
            margin: "15px",
            alignSelf: "self-end",
          }}
        >
          {"Notificar ausencias"}
        </Link>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(
  SectionCalendario
) as React.ComponentType<PublicProps>;
