import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../store";
import {fetchPacientes} from "../../../apis/pacientesAPI";
import {IPaciente} from "../../../Interfaces/IPaciente";

interface IverTurnosAgendadosState {
  error: string | null;
}

let initialState: IverTurnosAgendadosState = {
  error: null,
};

const verTurnosAgendadosSlice = createSlice({
  name: "verTurnosAgendados",
  initialState,
  reducers: {},
});

export const {} = verTurnosAgendadosSlice.actions;

export default verTurnosAgendadosSlice.reducer;
