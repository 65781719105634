import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {WithStyles, withStyles} from "@material-ui/styles";
import {styles} from "./SidebarStyles";
import {ReactComponent as LogoFacebook} from "../../../assets/logos/logo-facebook.svg";
import {ReactComponent as LogoInstagram} from "../../../assets/logos/logo-instagram.svg";
import {ReactComponent as LogoLinkedin} from "../../../assets/logos/logo-linkedin.svg";
import {ReactComponent as LogoYoutube} from "../../../assets/logos/logo-youtube.svg";
import {ReactComponent as ExitIcon} from "../../../assets/icons/icon_exit.svg";
import {ReactComponent as HomeIcon} from "../../../assets/icons/icon_home.svg";
import {ReactComponent as CamdoctorIcon} from "../../../assets/icons/icon_camdoctor.svg";
import {ReactComponent as ConsultaIcon} from "../../../assets/icons/icon_consulta.svg";
import {ReactComponent as GenerartokenIcon} from "../../../assets/icons/icon_generartoken.svg";

const ROUTES_1 = [
  {name: "MIS AUTORIZACIONES", to: "portal/autorizaciones"},
  {name: "FACTURACION Y PAGOS", to: "portal/facturaciones"},
  {name: "CREDENCIALES", to: "portal/credenciales"},
  {name: "PROGRAMAS", to: "portal/programas"},
];

const ROUTES_2 = [
  {name: "SUCURSALES", to: "portal/sucursales"},
  {name: "CARTILLA MÉDICA", to: "portal/cartilla"},
  {name: "BENEFICIOS", to: "portal/mis-gestiones"},
  {name: "NOTICIAS DE LA COMUNIDAD", to: "portal/mis-gestiones"},
];

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
}
type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const Sidebar: React.FC<Props> = (props) => {
  const {classes, isOpen, onClose} = props;
  const {url} = useRouteMatch();
  const mainURL = process.env.REACT_APP_URL_MEDIFE;
  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pl={5}
        pt={4}
        pb={5}
        className={classes.container}
      >
        <Box display="flex" justifyContent="flex-end" mb={6}>
          <IconButton className={classes.exitIcon} onClick={onClose}>
            <ExitIcon />
          </IconButton>
        </Box>
        <List className={classes.list}>
          <ListItem
            className={classes.listItem}
            button
            onClick={() => {
              onClose();
              window.location.href = `${mainURL}portal/mis-gestiones`;
            }}
          >
            <ListItemText primary="INICIO" />
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
          </ListItem>
          <Divider />
          {ROUTES_1.map((route) => (
            <ListItem
              key={route.name}
              className={classes.listItem}
              button
              onClick={() => {
                onClose();
                window.location.href = `${mainURL}${route.to}`;
              }}
            >
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
          <Divider />
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to={`${url}`}
            onClick={onClose}
          >
            <ListItemText primary="CAM DOCTOR" />
            <ListItemIcon>
              <CamdoctorIcon />
            </ListItemIcon>
          </ListItem>
          <Divider />
          {ROUTES_2.map((route) => (
            <ListItem
              key={route.name}
              className={[classes.listItem, classes.listItemSmall].join(" ")}
              button
              // component={Link}
              // to={`${mainURL}${route.to}`}
              onClick={() => {
                onClose();
                window.location.href = `${mainURL}${route.to}`;
              }}
            >
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              onClose();
              window.location.href = `${mainURL}portal/consultas`;
            }}
          >
            <ListItemText primary="CONSULTAS Y SUGERENCIAS" />
            <ListItemIcon>
              <ConsultaIcon />
            </ListItemIcon>
          </ListItem>
          <Divider />
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              onClose();
              window.location.href = `${mainURL}portal/token`;
            }}
          >
            <ListItemText primary="GENERAR TOKEN" />
            <ListItemIcon>
              <GenerartokenIcon />
            </ListItemIcon>
          </ListItem>
        </List>
        <Box display="flex" mt={9} className={classes.redesSociales}>
          <LogoFacebook />
          <LogoLinkedin />
          <LogoInstagram />
          <LogoYoutube />
        </Box>
      </Box>
    </Drawer>
  );
};

export default withStyles(styles)(Sidebar) as React.ComponentType<PublicProps>;
