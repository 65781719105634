import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: 16,
      maxWidth: 500,
    },
    turnosContainer: {
      overflowY: "auto",
      height: "100%",
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: "40px !important",
      margin: "0 5px 5px 0",
      scrollbarWidth: "auto",
      /* Chrome, Edge, and Safari */
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#ededed",
        borderRadius: "10px",
        marginBottom: "56px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        border: "px solid #ffffff",
        background: "#cccccc",
      },
    },
    avatarPaciente: {
      backgroundColor: theme.paletaColores.naranja.tercero,
    },
    avatarPacienteDisabled: {
      backgroundColor: theme.paletaColores.negro.cuarto,
    },
    horasPaciente: {
      display: "inline-block",
      marginRight: "5px",
    },
    horasPacienteDisabled: {
      display: "inline-block",
      marginRight: "5px",
      color: theme.paletaColores.negro.cuarto,
    },
    nombrePaciente: {
      fontWeight: "bold",
    },
    nombrePacienteDisabled: {
      fontWeight: "bold",
      color: theme.paletaColores.negro.cuarto,
    },

    verHistorial: {
      color: theme.paletaColores.naranja.segundo,
      cursor: "pointer",
      width: "35%",
      alignSelf: "self-end",
      fontWeight: 700,
      flex: 1,
    },
    verHistorialDisabled: {
      color: theme.paletaColores.negro.cuarto,
      cursor: "pointer",
      width: "35%",
      alignSelf: "self-end",
      fontWeight: 700,
      flex: 1,
    },
    cardHome: {
      "&:last-child": {
        paddingBottom: 10,
      },
    },
    iconResumenContainer: {
      width: "34px",
      height: "34px",
    },
    iconResumen: {
      color: "white",
      height: "100%",
      width: "100%",
      borderRadius: "4px",
    },
    textName: {
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    divider: {
      marginLeft: 73,
      marginRight: 15,
    },
    buttonsRoot: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      "& button": {
        textTransform: "none",
        width: 250,
        margin: 10,
      },
      "& button:nth-child(2)": {
        color: "white",
        backgroundColor: "grey",
      },
    },
    sample: {
      display: "flex",
      "input, button": {
        font: "inherit",
      },
      header: {
        backgroundColor: "rgb(50, 54, 57)",
        boxShadow: "0 0 8px rgba(0, 0, 0, .5)",
        padding: "20px",
        color: "white",
        h1: {
          fontSize: "inherit",
          margin: "0",
        },
      },
      "&__container": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        margin: "10px 0",
        padding: "10px",
        "& > *": {
          "& > *": {
            margin: "10px",
          },
        },
        "&__content": {
          display: "flex",
          maxWidth: "100%",
          flexBasis: "420px",
          flexDirection: "column",
          flexGrow: 100,
          alignItems: "stretch",
          paddingTop: "1em",
          "react-calendar": {
            margin: "0 auto",
          },
        },
      },
    },
  });
