import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import ListadoMedicos from "../../../pages/Paciente/VerMedicosFavoritos/ListadoMedicos/ListadoMedicos";
import SeleccionarPaciente from "../../../pages/Paciente/VerMedicosFavoritos/SeleccionarPaciente/SeleccionarPaciente";

const VerMedicosFavoritos = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/seleccionar-paciente`} exact />
      <PrivateRoute
        component={SeleccionarPaciente}
        path={`${path}/seleccionar-paciente`}
      />
      <PrivateRoute component={ListadoMedicos} path={`${path}/medicos`} />
    </Switch>
  );
};

export default VerMedicosFavoritos;
