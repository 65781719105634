import React, {useState} from "react";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Switch,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {WithStyles, withStyles} from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import LoadingBubbles from "../../../components/LoadingBubbles/LoadingBubbles";
import Collapse from "@material-ui/core/Collapse";

import {styles} from "./MiCuentaStyles";
import {ReactComponent as SettingsIcon} from "../../../assets/icons/icon_settings.svg";
import {ReactComponent as UseremptyIcon} from "../../../assets/icons/icon_userempty.svg";
import {ReactComponent as CerrarSesionIcon} from "../../../assets/icons/icon_cerrarsesion.svg";
import {useDispatch, useSelector} from "react-redux";
import {setRol, setUser, setLog} from "../../../store/user/userSlice";
import firebase from "firebase";
import {RootState} from "../../../store/rootReducer";
import {persistStore} from "redux-persist";
import {setUserClaims} from "../../../apis/adminAPI";

interface OwnProps {
  NOMBRE: string;
  APELLIDO: string;
  plan?: string;
  nroCredencial?: string;
  matricula?: number;
  isOpen: boolean;
  onClose: () => void;
}
type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const MiCuenta: React.FC<Props> = (props) => {
  const {
    classes,
    NOMBRE,
    APELLIDO,
    nroCredencial,
    isOpen,
    onClose,
    matricula,
    plan,
  } = props;
  const {url} = useRouteMatch();
  const mainURL = process.env.REACT_APP_URL_MEDIFE;
  const dispatch = useDispatch();
  const {rol, especialidades, email} = useSelector(
    (state: RootState) => state.user
  );
  const history = useHistory();
  const [cargando, setCargando] = useState(false);

  let canChangeRol = false;
  if (especialidades) {
    if (
      especialidades.find((x) => {
        const esp = x.descripcion.toLowerCase();
        return (
          esp === "administrador" ||
          esp === "administrador medife" ||
          esp === "administrador camdoctor"
        );
      }) &&
      especialidades!.length > 1
    ) {
      canChangeRol = false;
    }
  }
  let ROUTES = [];

  if (rol === "PACIENTE") {
    ROUTES = [
      {
        name: "CAMBIO MAIL",
        to: "",
        icon: SettingsIcon,
        disabled: true,
        onClick: () => {
          onClose();
          window.location.href = `${mainURL}portal/cambio-de-email`;
        },
      },
      {
        name: "CAMBIO CONTRASEÑA",
        to: "",
        icon: SettingsIcon,
        disabled: true,
        onClick: () => {
          onClose();
          window.location.href = `${mainURL}portal/cambio-de-contrasenia`;
        },
      },
      {
        name: "DATOS PERSONALES",
        to: "",
        icon: UseremptyIcon,
        disabled: true,
        onClick: () => {
          onClose();
          window.location.href = `${mainURL}portal/datos-personales`;
        },
      },
      {
        name: "CERRAR SESIÓN",
        to: "",
        icon: CerrarSesionIcon,
        onClick: async () => {
          dispatch(setLog(true));
          setCargando(true);
          await setUserClaims(email, "");

          // localStorage.removeItem("token");
          // dispatch(setUser({
          //   NOMBRE: '',
          //   APELLIDO: '',
          //   email: '',
          //   rol: null,
          //   NRO_DOCUMENTO: '',
          //   isUserLogin: false,
          //   warningSarsCov2: false,
          //   pacientesByCuil: {},
          //   cuilsDePacientes: [],
          //   cuilPacienteSeleccionado: -1,
          //   error: null,
          // }));
        },
        //onClose();
      },
    ];
  } else {
    ROUTES = [
      {
        name: "CERRAR SESIÓN",
        to: "",
        icon: CerrarSesionIcon,
        onClick: async () => {
          dispatch(setLog(true));
          setCargando(true);
          //await setUserClaims(email, '');

          // localStorage.removeItem("token");
          // dispatch(setUser({
          //   NOMBRE: '',
          //   APELLIDO: '',
          //   email: '',
          //   rol: null,
          //   NRO_DOCUMENTO: '',
          //   isUserLogin: false,
          //   warningSarsCov2: false,
          //   pacientesByCuil: {},
          //   cuilsDePacientes: [],
          //   cuilPacienteSeleccionado: -1,
          //   error: null,
          // }));
        },
        //onClose();
      },
    ];
  }

  let rolMostrar = "";
  switch (rol) {
    case "MEDICO": {
      rolMostrar = "PROFESIONAL";
      break;
    }
    case "ADMINISTRADOR": {
      rolMostrar = "COORDINADOR";
      break;
    }
    case "OPERADOR": {
      rolMostrar = "OPERADOR";
      break;
    }
    default: {
      rolMostrar = "ASOCIADO";
      break;
    }
  }

  const showMatricula = matricula !== undefined && rol === "MEDICO";

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pl={5}
        pt={4}
        pb={5}
        className={classes.container}
      >
        <Box display="flex" justifyContent="flex-end" mb={6}>
          <IconButton className={classes.exitIcon} onClick={onClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mb={12}
          className={classes.datos}
        >
          <span className={classes.asociado}>
            <b>{rolMostrar}</b>
          </span>
          <span className={classes.nombre}>
            <b>{`${NOMBRE} ${APELLIDO}`}</b>
          </span>
          {plan && <span>{plan}</span>}
          {nroCredencial && <span>Credencial Nro: {nroCredencial}</span>}
          {showMatricula && <span>Matricula: {matricula}</span>}
        </Box>
        <Divider className={classes.divider} />
        <span>Preferencias</span>
        <List>
          {canChangeRol && (
            <>
              <ListItem className={classes.listItem}>
                <ListItemText primary="MODO COORDINADOR" />
                <ListItemIcon>
                  <Switch
                    edge="end"
                    checked={rol === "ADMINISTRADOR"}
                    onChange={async (event) => {
                      setCargando(true);

                      let admin = event.target.checked;
                      dispatch(setRol(admin ? "ADMINISTRADOR" : "MEDICO"));
                      await setUserClaims(email, rol as string);
                      await setUserClaims(email, rol as string);
                      setCargando(false);
                      history.push("/dashboard");
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            </>
          )}
          <Collapse in={cargando}>
            <LoadingBubbles />
          </Collapse>
          {ROUTES.map((route: any) => {
            if (!route.disabled) {
              return (
                <ListItem
                  button
                  key={route.name}
                  className={classes.listItem}
                  disabled={cargando}
                  onClick={route.onClick}
                >
                  <ListItemText primary={route.name} />
                  <ListItemIcon>
                    <route.icon />
                  </ListItemIcon>
                </ListItem>
              )
            } else {
              return (<></>)
            }
          })}
        </List>
        <h1>{cargando ? "Cerrando la sesion, por favor espere" : ""}</h1>
      </Box>
    </Drawer>
  );
};

export default withStyles(styles)(MiCuenta) as React.ComponentType<PublicProps>;
