import React, {useState} from "react";
import {
  Divider,
  WithStyles,
  withStyles,
  Toolbar,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Button,
  Collapse,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Checkbox,
  Icon,
} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {styles} from "./ContainerAdministrarCalificacionProfStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {
  InsertChartOutlinedRounded,
  ReplayOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
  SearchOutlined,
  ArrowForwardIosOutlined,
  CloseOutlined,
} from "@material-ui/icons";
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import {obtenerValoracionProfProm20} from "../../../../apis/valoracionAPI";
import {
  convertDatestringToDate,
  convertDatestringToLocaleShortDateOnly,
  _getDateAsIs,
  _getDateOrderBy,
} from "../../../../utils/dateTimeHelper";
import {IProfesionalPromedio} from "../../../../Interfaces/IProfesionalPromedio";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import ValoracionesPorPaciente from "../ValoracionesPorPaciente/ValoracionesPorPaciente";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {parse} from "uuid";

const ContainerAdministrarCalificacionProf: React.FC<
  WithStyles<typeof styles>
> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();

  const [medicos, setMedicos] = React.useState<Array<IProfesionalPromedio>>([]);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(-1);

  const setPromedio = (medico: string, newPromedio: number) => {
    let tempMedicos = [...medicos];
    let index = tempMedicos.findIndex((x) => x.email === medico);
    if (index === -1) {
      return;
    }

    let tempMedico = {...tempMedicos[index]};
    tempMedico.promedio = newPromedio;
    tempMedicos[index] = tempMedico;

    armarRows(tempMedicos);
    setMedicos(tempMedicos);
  };

  const closeDetails = () => {
    setDetailsOpen(false);
    setSelectedId(-1);
  };

  const selectedMedico = selectedId >= 0 ? medicos[selectedId] : undefined;

  const obtenerTodos = () => {
    setFechaBusqueda(null);
    setApellidoBusqueda("");
    setMinBusqueda(undefined);
    setMaxBusqueda(undefined);
    setEspontanea(false);

    setLoading(true);

    setUltimaBusquedaEspontanea(false);

    obtenerValoracionProfProm20(false)
      .then((response) => {
        armarRows(response);
        setMedicos(response);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const armarRows = (listadoMedicos: Array<IProfesionalPromedio>) => {
    let temp: Array<Row> = [];
    listadoMedicos.forEach((prof, iProf) => {
      let row: Row = {
        id: iProf,
        valoracion: prof.promedio,
        profesional: prof.apellidoProfesional + " " + prof.nombreProfesional,
        ult_calif: _getDateAsIs(prof.ultimaValoracion),
      };
      temp.push(row);
    });
    setRows(temp);
  };

  const obtenerFiltrados = () => {
    setLoading(true);

    setUltimaBusquedaEspontanea(espontanea);

    obtenerValoracionProfProm20(
      espontanea,
      apellidoBusqueda,
      minBusqueda,
      maxBusqueda
    )
      .then((response) => {
        armarRows(response);
        setMedicos(response);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    obtenerTodos();
  }, []);

  const renderDetailsButton = (params: GridRenderCellParams) => {
    const open = () => {
      const id = params.getValue(params.id, "id") as number;
      const medico = medicos[id];

      setDetailsOpen(true);
      setSelectedId(id);
    };
    return (
      <Button onClick={open} fullWidth endIcon={<ArrowForwardIosOutlined />}>
        {`Ver`}
      </Button>
      // <strong>
      //     <Link to='/dashboard/admin/administrar-valoraciones-paciente/administrar-valoraciones-paciente'>
      //     </Link>
      // </strong>
    );
  };

  const [filtros, setFiltros] = useState(false);

  const [espontane, setEspontane] = useState(false);

  const columns = [
    {field: "id", headerName: "ID", width: 50, minWidth: 50},
    {
      field: "profesional",
      headerName: "Profesional",
      width: 250,
      flex: 200,
    },
    {
      field: "valoracion",
      headerName: "Valoracion",
      width: 150,
      minWidth: 150,
    },
    {
      field: "ult_calif",
      headerName: "Ultima calificación",
      minWidth: 200,
      width: 200,
      sortComparator: (v1: any, v2: any, param1: any, param2: any) => {
        var d1 = new Date(param1.value.split("/").reverse().join("/"));
        var d2 = new Date(param2.value.split("/").reverse().join("/"));
        return d1.getTime() > d2.getTime()
          ? 1
          : d1.getTime() < d2.getTime()
          ? -1
          : 0;
      },
    },
    {
      field: "calificaciones",
      headerName: "Calificaciones",
      renderCell: renderDetailsButton,
      minWidth: 200,
      width: 200,
    },
  ];

  interface Row {
    id: number;
    profesional: string;
    valoracion: number;
    ult_calif: string;
  }

  const [rows, setRows] = React.useState<Array<Row>>([]);

  //filtros
  const [fechaBusqueda, setFechaBusqueda] = React.useState<Date | null>(null);
  const [apellidoBusqueda, setApellidoBusqueda] = React.useState<string>("");
  const [minBusqueda, setMinBusqueda] = React.useState<number | undefined>(
    undefined
  );
  const [maxBusqueda, setMaxBusqueda] = React.useState<number | undefined>(
    undefined
  );
  const [espontanea, setEspontanea] = React.useState(false);
  const [ultimaBusquedaEspontanea, setUltimaBusquedaEspontanea] =
    React.useState(false);

  // const rows = [
  //     { id: 1, profesional: 'Snow', valoracion: 4.5, ult_calif: 30, calificaciones: '' },
  //     { id: 2, profesional: 'Lannister', valoracion: 5, ult_calif: 20, calificaciones: '' },
  // ];

  return (
    <>
      <Card style={{flexGrow: 1, marginBottom: 20}}>
        <CardHeader
          avatar={
            <Avatar>
              <InsertChartOutlinedRounded />
            </Avatar>
          }
          title={
            <Typography variant="h6">Valoracion de profesionales</Typography>
          }
          action={
            <>
              <Button
                disabled={loading}
                onClick={obtenerTodos}
                aria-label="settings"
                variant="contained"
                disableElevation
                startIcon={<ReplayOutlined />}
              >
                Actualizar
              </Button>
              <Button
                style={{margin: 5}}
                variant="contained"
                disableElevation
                onClick={() => {
                  setFiltros(!filtros);
                }}
                endIcon={
                  filtros ? (
                    <KeyboardArrowUpOutlined />
                  ) : (
                    <KeyboardArrowDownOutlined />
                  )
                }
              >
                Filtros
              </Button>
              <Button
                style={{margin: 5, paddingLeft: 8}}
                variant="contained"
                disableElevation
                onClick={() => {
                  setEspontane(!espontane);
                }}
                endIcon={
                  espontane ? (
                    <KeyboardArrowUpOutlined />
                  ) : (
                    <KeyboardArrowDownOutlined />
                  )
                }
              ></Button>
            </>
          }
        />
        <Divider />
        <Collapse in={espontane} timeout="auto" unmountOnExit>
          <Toolbar>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  value={espontanea}
                  onChange={(event, checked) => {
                    setEspontanea(checked);
                  }}
                  color="primary"
                />
              }
              label={"Espontaneas"}
              labelPlacement="start"
            />
          </Toolbar>
        </Collapse>
        <Divider />
        <Collapse in={filtros} timeout="auto" unmountOnExit>
          <Toolbar>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                size="small"
                                format="dd/MM/yyyy"
                                label="Fecha solicitud"
                                value={fechaBusqueda}
                                onChange={(event) => {
                                    setFechaBusqueda(event as Date)
                                }}
                                inputVariant="outlined"
                                style={{ margin: 5, width: 170 }}
                                defaultValue={null}
                                disableFuture
                                invalidDateMessage="Fecha inválida"
                                maxDateMessage="La fecha debe ser anterior a hoy"
                                InputAdornmentProps={{ style: { visibility: "hidden" } }}
                            />
                        </MuiPickersUtilsProvider> */}
            <TextField
              // value={especialidad}
              label="Apellido Profesional"
              // onChange={(event) => {
              //     setEspecialidad(event.target.value as string);
              // }}
              variant="outlined"
              style={{margin: 5, maxWidth: 300}}
              size="small"
              value={apellidoBusqueda}
              onChange={(event) => {
                setApellidoBusqueda(event.target.value as string);
              }}
            />
            <TextField
              // value={operador}
              label="Valoración Min."
              // onChange={(event) => {
              //     setOperador(event.target.value as string);
              // }}
              variant="outlined"
              style={{margin: 5, maxWidth: 150}}
              size="small"
              type="number"
              required={maxBusqueda !== undefined}
              value={minBusqueda ? minBusqueda : ""}
              error={maxBusqueda !== undefined && minBusqueda === undefined}
              onKeyDown={(evt) =>
                (evt.key.toLowerCase() === "e" ||
                  evt.key === "+" ||
                  evt.key === "-") &&
                evt.preventDefault()
              }
              onChange={(event) => {
                if (event.target.value === "") {
                  setMinBusqueda(undefined);
                  return;
                }
                var newValue = Number.parseFloat(event.target.value);
                if (newValue > 5) return;
                if (newValue < 0) return;
                // if (maxBusqueda !== undefined && newValue > maxBusqueda) return;
                setMinBusqueda(newValue);
              }}
            />
            <TextField
              // value={operador}
              label="Valoración Max."
              // onChange={(event) => {
              //     setOperador(event.target.value as string);
              // }}
              variant="outlined"
              style={{margin: 5, maxWidth: 150}}
              size="small"
              type="number"
              required={minBusqueda !== undefined}
              value={maxBusqueda ? maxBusqueda : ""}
              error={minBusqueda !== undefined && maxBusqueda === undefined}
              onKeyDown={(evt) =>
                (evt.key.toLowerCase() === "e" ||
                  evt.key === "+" ||
                  evt.key === "-") &&
                evt.preventDefault()
              }
              onChange={(event) => {
                if (event.target.value === "") {
                  setMaxBusqueda(undefined);
                  return;
                }
                var newValue = Number.parseFloat(event.target.value);
                if (newValue > 5) return;
                if (newValue < 0) return;
                // if (minBusqueda !== undefined && newValue < minBusqueda) return;
                setMaxBusqueda(newValue);
              }}
            />
            <div style={{flexGrow: 1}} />
            <Button
              // disabled={loading}
              // onClick={() => { onBuscar(true) }}
              variant="contained"
              color="primary"
              disableElevation
              endIcon={<SearchOutlined />}
              disabled={loading || !minBusqueda || !maxBusqueda}
              onClick={obtenerFiltrados}
            >
              {`BUSCAR`}
            </Button>
          </Toolbar>
        </Collapse>
        <Divider />
        <CardContent
          style={{backgroundColor: theme.paletaColores.fondo.segundo}}
        >
          <Card style={{flexGrow: 1, marginBottom: 20}}>
            <CardContent>
              {loading ? (
                <div style={{marginTop: 100, marginBottom: 100}}>
                  <LoadingBubbles />
                </div>
              ) : (
                <div style={{height: 400, width: "100%"}}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    onRowClick={(params) => {
                      const id = params.getValue(params.id, "id") as number;
                      // const medico = medicos[id];

                      setDetailsOpen(true);
                      setSelectedId(id);
                    }}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <div className={classes.buttonsRoot}>
        <Volver
          onClickHandler={() => {
            history.goBack();
          }}
        />
      </div>
      <Dialog fullWidth maxWidth="lg" open={detailsOpen} onClose={closeDetails}>
        <DialogTitle>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div>
              <div style={{color: theme.paletaColores.naranja.segundo}}>
                {selectedMedico?.profesional}
              </div>
              <Typography>
                {`Valoración: ${selectedMedico?.promedio}`}
              </Typography>
            </div>
            <div style={{flexGrow: 1}} />
            <IconButton onClick={closeDetails}>
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <ValoracionesPorPaciente
            medico={selectedMedico!}
            espontanea={ultimaBusquedaEspontanea}
            setPromedio={setPromedio}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ContainerAdministrarCalificacionProf);
