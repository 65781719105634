import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import {CalendarTodayOutlined, PersonOutline} from "@material-ui/icons";
import React from "react";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {IEspecialidad} from "../../../../../Interfaces/IEspecialidad";
import {IFinanciador} from "../../../../../Interfaces/IFinanciador";
import {IProfesionalTurnos} from "../../../../../Interfaces/IProfesionalHorario";
import {convertDateToLocaleLongDateOnly} from "../../../../../utils/dateTimeHelper";

interface OwnProps {
  loading: boolean;
  financiador: IFinanciador;
  especialidad: IEspecialidad;
  profesional: string;
  matricula: number;
  selectedTurno: string;
  fecha: Date;
  cuil: string;
  fechaNac: Date;
  genero: string;
  motivo: string;
  nombre: string;
  apellido: string;
  email: string;
  numAsociado: string;
  celular: string;
}

function TurnoConfirmado(props: OwnProps) {
  const {
    loading,
    financiador,
    especialidad,
    profesional,
    matricula,
    selectedTurno,
    fecha,
    cuil,
    fechaNac,
    genero,
    motivo,
    nombre,
    apellido,
    email,
    numAsociado,
    celular,
  } = props;

  if (loading) {
    return (
      <div>
        <LoadingBubbles />
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs />
      <Grid item container xs={12} sm={11} md={10} lg={9} spacing={2}>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography variant="h6">{`${nombre} ${apellido}`}</Typography>
              }
              avatar={
                <Avatar>
                  <PersonOutline />
                </Avatar>
              }
              subheader={`CUIL: ${cuil}`}
            />
            <CardContent>
              <Divider />
              <div style={{padding: "10px 0px 10px 0px"}}>
                <Typography variant="h6">Contacto</Typography>
                <Typography>Mail: {email}</Typography>
                <Typography>Celular: {celular}</Typography>
              </div>
              <Divider />
              <div style={{padding: "10px 0px 10px 0px"}}>
                <Typography variant="h6">{`Financiador: ${financiador.descripcion}`}</Typography>
                <Typography>Credencial: {numAsociado}</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardHeader
              title={<Typography variant="h6">{`${profesional}`}</Typography>}
              avatar={
                <Avatar>
                  <CalendarTodayOutlined />
                </Avatar>
              }
              subheader={`Matricula: ${matricula}`}
            />
            <CardContent>
              <Divider />
              <div style={{padding: "10px 0px 10px 0px"}}>
                <Typography variant="h6">Turno Confirmado</Typography>
                <Typography>
                  {convertDateToLocaleLongDateOnly(fecha)}, {selectedTurno}
                </Typography>
                <Typography>
                  Especialidad: {especialidad.descripcion}
                </Typography>
                <Typography>Motivo: {motivo}</Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs />
    </Grid>
  );
}

export default TurnoConfirmado;
