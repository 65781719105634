import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  DialogContent,
  DialogContentText,
  TextField
} from "@material-ui/core";
import firebase from "firebase";
import React, {useState, useRef} from "react";
import {useDispatch} from "react-redux";
import LoadingBubbles from "../../components/LoadingBubbles/LoadingBubbles";
import loginMedico from "../../../src/assets/images/img-login.png";
import {IEspecialidad} from "../../Interfaces/IEspecialidad";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {ReactComponent as LogoCamdoctorGrey} from "../../assets/logos/logos_svg/logo_camdoctor_g.svg";


import {
  resetPacientes,
  setIsUserLogin,
  setLog,
  setUser,
} from "../../store/user/userSlice";
import {resetPacientes as resetPacientesAtender} from "../../store/profesional-medico/atender-consultorio/atenderConsultorioSlice";
import {login} from "../../login/login";
import {InfoLoginGoogle} from "../../apis/profesionalAPI";
import {SetSesionActiva} from "../../apis/profesionalAPI";
import { validateDNI } from "../../apis/pacientesAPI";
import {setUserClaims} from "../../apis/adminAPI";
import {getFirestore} from "../../db";
import packageJson from "../../../package.json";

type Color = "success" | "info" | "warning" | "error";
let emailActivo = "";
let sesionLogeoActivo = "";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    height: "100vh",
  },
  leftSide: {
    background: `url(${loginMedico}) center top no-repeat`,
    backgroundSize: `cover`,
  },
});

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openD, setOpenD] = useState(false);
  
  // login dni
  const [asociadoLoginValidation, setAsociadoLoginValidation] = useState(false);
  const [asociadoLoginLoading, setAsociadoLoginLoading] = useState(false);
  const [dniAsociado, setDniAsociado] = useState('')
  const [emailAsociado, setEmailAsociado] = useState('')

  const [titleD, setTitleD] = useState("");
  const [severity, setSeverity] = useState("error" as Color);
  const history = useHistory();

  const [dialogSeleccionRolOpen, setDialogSeleccionRolOpen] = useState(false);
  const [dialogDobleSession, setDialogDobleSession] = useState(false);

  const datosLoginTardio = useRef<
    | {
        NOMBRE: string;
        APELLIDO: string;
        email: string;
        especialidades: Array<IEspecialidad>;
        matricula: number;
        photoURL: string;
      }
    | undefined
  >(undefined);

  const setSesionActiva = async () => {
    await SetSesionActiva(emailActivo, sesionLogeoActivo);
    await dispatch(setIsUserLogin(true));
  };

  const validarDNI = async () => {
    try {
      setAsociadoLoginLoading(true)
      const datos = await validateDNI(dniAsociado) as any
      console.log(datos)
      
      if (datos && datos[0] && datos[0].length) {
        if (datos[0][0].nombre) {
          const nombreSeparado = datos[0][0].nombre.split(", ");
          login(
            datos[0][0].apellido,
            nombreSeparado[0],
            datos[0][0].mail ? datos[0][0].mail : '',
            "PACIENTE",
            [],
            undefined,
            undefined, //datosDevueltos.datos_medife.a.plan,
            datos[0][0].id_grupo_familiar,
            datos[0][0].nro_credencial,
            dniAsociado
          );
          dispatch(setIsUserLogin(true));
          localStorage.setItem("LoggedIn", "true");
        } else {
          const nombreSeparado = datos[0][0].NOMBRE.split(", ");
          login(
            datos[0][0].APELLIDO,
            nombreSeparado[0],
            datos[0][0].MAIL ? datos[0][0].MAIL : '',
            "PACIENTE",
            [],
            undefined,
            undefined, //datosDevueltos.datos_medife.a.plan,
            datos[0][0].ID_GRUPO_FAMILIAR,
            datos[0][0].NRO_CREDENCIAL,
            dniAsociado
          );
          dispatch(setIsUserLogin(true));
          localStorage.setItem("LoggedIn", "true");
        }
      } else {
        console.log('no encontrado')
        setTitleD("Usuario no registrado");
        setOpenD(true);
        setSeverity("error" as Color);
        console.log("no registrado");
        setDniAsociado('')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setAsociadoLoginLoading(false)
    }
  }

  const loginTardio = async (
    rol:
      | "PACIENTE"
      | "MEDICO"
      | "OPERADOR"
      | "ADMINISTRADOR"
      | "ADMINISTRADOR FINOCHIETTO"
  ) => {
    await setUserClaims(datosLoginTardio!.current!.email, rol);
    // Esta funcion que sigue fuerza el refresh del token cuando cambia el rol.
    await firebase
      .auth()
      .currentUser?.getIdTokenResult(true)
      .then(function (result) {
        console.log("fuezo refresh token:");
      });

    let user = await login(
      datosLoginTardio!.current!.NOMBRE,
      datosLoginTardio!.current!.APELLIDO,
      datosLoginTardio!.current!.email,
      rol,
      datosLoginTardio!.current!.especialidades,
      datosLoginTardio!.current!.matricula,
      undefined,
      undefined,
      undefined,
      undefined,
      datosLoginTardio!.current!.photoURL
    );

    //Validacion Doble Session
    console.log("Validacion Doble Session");
    emailActivo = user!.email as string;
    sesionLogeoActivo = user!.sesionLogeo as string;
    getFirestore()
      .collection("LogEventos")
      .doc("estadoLoginProfesionales")
      .collection(datosLoginTardio!.current!.email)
      .doc("estado")
      .get()
      .then((consultas) => {
        if (consultas.data() !== undefined) {
          console.log(
            "sesionLogeoIdActivo",
            consultas.data()!.sesionLogeoIdActivo
          );
          if (consultas.data()!.sesionLogeoIdActivo === "") {
            console.log("no hay sesion activa");
            setSesionActiva();
          } else {
            if (consultas.data()!.sesionLogeoIdActivo !== user!.sesionLogeo) {
              console.log("sesion activa pero no coincide");
              setDialogDobleSession(true);
            } else {
              console.log("sesion activa y coincide");
              setSesionActiva();
            }
          }
        }else{
          getFirestore()
          .collection("LogEventos")
          .doc("estadoLoginProfesionales")
          .collection(datosLoginTardio!.current!.email)
          .doc('estado')
          .set({
            logueado: true,
            sesionLogeoIdActivo:''
          }, {merge: true}).then((response) => {
            setSesionActiva();
          })
        }
      });
    // Fin Validacion Doble Session
  };

  //const [userAuthData, setUserAuthData] = useState<IUserAuthData | undefined>(undefined);
  /*
  const onPacienteClicked = async () => {
    setLoading(true);
    const dni = '31048190'; // otro dni de prueba: santiago balsano 31048190 veronica del valle lopez 28863795
    const objetoRecibidoIdGF = await getIdGF(dni);

    dispatch(setUser({
      nombre: 'Santiago',
      apellido: 'Balsano',
      email: 'test@zentricx.com',
      rol: 'PACIENTE',
      dni: dni,
      idGrupoFamiliar: objetoRecibidoIdGF.idGF,
      isUserLogin: true
    }));
    setLoading(false);
  };

  const onAdminClicked = () => {
    dispatch(setUser({
      nombre: 'Administrador',
      apellido: 'Clinica',
      email: 'admin@zentricx.com',
      rol: 'ADMINISTRADOR',
      isUserLogin: true
    }));
  };
  */

  const onGoogleAuthClicked = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    setOpenD(false);
    setLoading(true);
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        var user = result.user;

        let info = await InfoLoginGoogle(user!.email!, user!.photoURL!);
        if (info.empty) {
          setTitleD("Usuario no registrado");
          setOpenD(true);
          setSeverity("error" as Color);
          console.log("no registrado");
          return;
        }

        //let bearerToken = await result.user?.getIdToken();

        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential as firebase.auth.OAuthCredential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = credential!.accessToken;
        let beaner = await firebase.auth().currentUser?.getIdToken();
        // The signed-in user info.

        const datos = {
          email: user!.email!,
          displayName: user!.displayName!,
          oAuthToken: token!,
          photoURL: user!.photoURL!,
        };

        const loginData = info;

        let matricula: number = parseInt(info.matricula);

        const especialidades: Array<IEspecialidad> = loginData.especialidad;
        let isMedico = false;
        let isAdmin = false;
        let isOperador = false;
        let isAdminFinochietto = false;
        let isFarmacia = false;
        console.log("0. por hacer");
        especialidades.forEach((especialidad) => {
          let esp = especialidad.descripcion.toLowerCase();

          if (
            esp === "administrador" ||
            esp === "administrador medife" ||
            esp === "administrador camdoctor"
          ) {
            isAdmin = true;
          } else if (esp === "administrador finochietto") {
            isAdminFinochietto = true;
          } else if (esp === "administrativo turnos") {
            isOperador = true;
          } else if (esp === "farmaceutico") {
            isFarmacia = true;
          } else {
            isMedico = true;
          }
        });
        const nombre = loginData.nombre;
        const apellido = loginData.apellido;
        // setUserAuthData(datos);

        //resetear datos de pacientes de sesiones anteriores
        resetPacientes();
        resetPacientesAtender();

        //aca llamaria al endpoint de mandarle estos datos al back
        //el back deberia devolverme minimamente: nombre, apellido, email, cuil, y rol
        //(DE LA PERSONA LOGEADA)
        // var datosDelUsuario = await enviarLogeadoConGoogle(datos);
        let rol:
          | "PACIENTE"
          | "MEDICO"
          | "OPERADOR"
          | "ADMINISTRADOR"
          | "ADMINISTRADOR FINOCHIETTO"
          | "FARMACEUTICO";

        datosLoginTardio.current = {
          NOMBRE: nombre,
          APELLIDO: apellido,
          email: datos.email,
          especialidades: especialidades,
          matricula: matricula,
          photoURL: datos.photoURL,
        };

        let loginTardioBandera = false;
        if (isMedico && isAdmin) {
          setDialogSeleccionRolOpen(true);
          loginTardioBandera = true;
        } else if (isAdmin) {
          rol = "ADMINISTRADOR";
        } else if (isAdminFinochietto) {
          rol = "ADMINISTRADOR FINOCHIETTO";
        } else if (isOperador) {
          rol = "OPERADOR";
        } else if (isFarmacia) {
          rol = "FARMACEUTICO";
        } else if (isMedico) {
          rol = "MEDICO";
          loginTardioBandera = true;
          await loginTardio("MEDICO");
        } else {
          console.log("La cuenta no es de medico ni administrador");
        }

        localStorage.setItem("LoggedIn", "true");

        if (!loginTardioBandera) {
          login(
            nombre,
            apellido,
            datos.email,
            rol!,
            especialidades,
            matricula,
            undefined,
            undefined,
            undefined,
            undefined,
            datos.photoURL
          );
          await dispatch(setIsUserLogin(true));
        }
        setLoading(false);
        //console.log(token);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
        setTitleD("Ocurrio un error al loguear".toString());
        setOpenD(true);
        setSeverity("error" as Color);
        console.log(error);
        setLoading(false);
      });
  };

  const classes = useStyles();

  /*  if (loading) {
      return (
        <div style={{ marginTop: "40vh" }}>
          <LoadingBubbles />
        </div>
      );
    } */

  const windowSize = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={7}
          className={classes.leftSide}
          style={{height: windowSize.width > 768 ? "100vh" : "50vh"}}
        >
          {/* <img
            src={loginMedico}
            alt="imagen login medico"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          /> */}
          <Grid item xs={1} md={2} />

          <Grid item xs style={{paddingTop: "10vh"}}>
            {/* <Typography variant="h3"style={{ color: "#FFFF",fontFamily: "Syntax LT Std Bold" }}>Cam Doctor</Typography> */}
            <LogoCamdoctorGrey style={{width: 350, marginTop: -50}} />

            {/* <hr
              style={{
                boxSizing: "border-box",
                height: "1px",
                width: "100%",
                border: "2px solid #FFFFFF",
              }}
            /> */}
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={5}
          justify="center"
          alignItems="center"
          style={{background: "#FFFF"}}
        >
          <Grid item>
            <Typography variant="h5">Login Usuarios</Typography>
            <Divider style={{margin: "20px 0px 20px 0px"}} />
            <Collapse in={loading}>
              <LoadingBubbles />
            </Collapse>
            <Collapse in={openD}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenD(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity={severity}
              >
                {titleD}
              </Alert>
              <Divider style={{margin: "20px 0px 20px 0px"}} />
            </Collapse>
            {!loading && !asociadoLoginValidation && (
              <>
                <Grid
                  item
                  style={{
                    boxSizing: "border-box",
                    height: "45px",
                    width: "339px",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    variant="outlined"
                    disableElevation
                    fullWidth
                    color="primary"
                    href={process.env.REACT_APP_URL_LOGIN_ASOCIADOS}
                    // onClick={() => {
                    //   setAsociadoLoginValidation(true)
                    // }}
                  >
                    Asociado
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    boxSizing: "border-box",
                    height: "45px",
                    width: "339px",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    variant="outlined"
                    disableElevation
                    fullWidth
                    color="primary"
                    onClick={onGoogleAuthClicked}
                  >
                    Prestador
                  </Button>
                </Grid>
              </>
            )}

            {
              asociadoLoginValidation && (
                <>
                  <Grid
                    item
                    style={{
                      boxSizing: "border-box",
                      height: "45px",
                      width: "339px",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "15px",
                    }}
                  >
                    <TextField disabled={asociadoLoginLoading} autoFocus={true} id="standard-basic" type="number" label="Ingresa tu número de documento" fullWidth value={dniAsociado} onChange={(event) => {
                      setDniAsociado(event.target.value as string);
                    }} onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        if (!!dniAsociado.length && !!emailAsociado.length) {
                          validarDNI()
                        }
                      }
                    }} />
                  </Grid>
                  <Grid
                    item
                    style={{
                      boxSizing: "border-box",
                      height: "45px",
                      width: "339px",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      disabled={asociadoLoginLoading || !dniAsociado.length}
                      color="primary"
                      onClick={validarDNI}
                    >
                      Ingresar
                    </Button>
                  </Grid>
                  <Grid
                    item
                    style={{
                      boxSizing: "border-box",
                      height: "45px",
                      width: "339px",
                      backgroundColor: "#FFFFFF",
                      marginBottom: "15px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      disableElevation
                      fullWidth
                      disabled={asociadoLoginLoading}
                      color="primary"
                      onClick={() => {
                        setAsociadoLoginValidation(false)
                      }}
                    >
                      Volver
                    </Button>
                  </Grid>
                  {
                    asociadoLoginLoading && (
                      <>
                        <Grid
                          item
                          style={{
                            boxSizing: "border-box",
                            height: "45px",
                            width: "339px",
                            backgroundColor: "#FFFFFF",
                            marginBottom: "5px",
                          }}
                        >
                          <LoadingBubbles />
                        </Grid>
                      </>
                    )
                  }
                </>
              )
            }

            <p
              style={{
                height: "141px",
                width: "339px",
                color: "#58575C",
                fontFamily: "Syntax LT Std",
                fontSize: "14px",
                letterSpacing: "0",
                lineHeight: "18px",
                marginTop: "60px",
              }}
            >
              <a href="#!" style={{color: "#EF7F41"}}>
                Política de Privacidad
              </a>
              &nbsp;|
              <a href="#!" style={{color: "#EF7F41"}}>
                &nbsp;DNPDP
              </a>
              &nbsp;|&nbsp;
              <a href="#!" style={{color: "#EF7F41"}}>
                Defensa y Protección al Consumidor
              </a>{" "}
              Superintendencia de Servicios de Salud Órgano de Control de Obras
              Sociales y Entidades de Medicina Prepaga 0800 222 SALUD (72583) |{" "}
              <a href="#!" style={{color: "#EF7F41"}}>
                www.sssalud.gob.ar
              </a>{" "}
              | R.N.E.M.P: Nro. 1199/15 V. {packageJson.version}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={dialogSeleccionRolOpen}>
        <DialogTitle>Por favor seleccione un rol para ingresar</DialogTitle>
        <DialogActions>
          <Collapse in={loading}>
            <LoadingBubbles />
          </Collapse>
          {!loading && (
            <>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={async () => {
                  setLoading(true);
                  setDialogSeleccionRolOpen(false);
                  await loginTardio("ADMINISTRADOR");
                }}
              >
                COORDINADOR
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={async () => {
                  setLoading(true);
                  setDialogSeleccionRolOpen(false);
                  await loginTardio("MEDICO");
                }}
              >
                MEDICO
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={dialogDobleSession} fullWidth maxWidth="xs">
        <DialogContent>
          <DialogContentText>
            {`Estas conectado en otra sesión.Podes cancelarla y continuar aquí, o permanecer en la otra conexión.`}
          </DialogContentText>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => {
                setLoading(false);
                setDialogDobleSession(false);
              }}
            >
              PERMANECER EN LA OTRA SESIÓN
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                setLoading(true);
                setDialogDobleSession(false);
                setSesionActiva();
              }}
            >
              NUEVA CONEXIÓN
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SignIn as React.ComponentType;
