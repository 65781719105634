import React, {useEffect, useState} from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  WithStyles,
  withStyles,
  useTheme,
  Grid,
  Typography,
  MenuItem,
  Chip,
  Avatar,
  TextField,
  Tooltip,
  Box,
  Tabs,
  Tab,
  Toolbar,
  Card,
  CardHeader,
  Button,
  Collapse,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {styles} from "./SeleccionarTurnoStyles";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import Siguiente from "../../../../components/buttons/Siguiente/Siguiente";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {
  Done,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Person,
} from "@material-ui/icons";
import InteractiveCalendar from "../../../../components/InteractiveCalendar/InteractiveCalendar";
import {getDiasLibres, getInfoDia} from "../../../../apis/citaProgramadaAPI";
import {IProfesionalTurnos} from "../../../../Interfaces/IProfesionalHorario";
import {
  setFechaTurno,
  setHorarioTurno,
  setIdProfesional,
  setMatriculaMedico,
  setMotivo,
  setNombreMedico,
  setImagenMedicoURL,
  setMedicoFiltrado,
} from "../../../../store/paciente/consultas-programadas/consultasProgramadasSlice";
import {
  convertDatestringToDate,
  convertDateToDatestring,
} from "../../../../utils/dateTimeHelper";
import SwipeableViews from "react-swipeable-views";
import MedicoTurnosDisponibles from "../../../../components/MedicoTurnosDisponibles/MedicoTurnosDisponibles";

const SeleccionarTurno: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useTheme();

  const {especialidad, medicoFiltrado, horariosDisponibles} = useSelector(
    (state: RootState) => state.consultasProgramadas
  );

  const {cuilPacienteSeleccionado, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );
  const [loading, setLoading] = useState(false);
  const [loadingHorarios, setLoadingHorarios] = useState(false);
  const [profesionalSeleccionado, setProfesionalSeleccionado] = useState<
    string | undefined
  >(undefined);
  const [selectedProfesionalTurno, setSelectedProfesionalTurno] = useState<
    IProfesionalTurnos | undefined
  >(undefined);
  const [selectedTurno, setSelectedTurno] = useState<string>("");
  const [selectedSobreturno, setSelectedSobreturno] = useState<boolean>(false);

  const [verFavoritos, setVerFavoritos] = useState(false);

  const [tabsSeleccionadas, setTabsSeleccionadas] = React.useState<
    Array<number>
  >([]);
  const handleChangeTab = (newTab: number, tabIndex: number) => {
    let temp = [...tabsSeleccionadas];
    temp[tabIndex] = newTab;
    setTabsSeleccionadas(temp);
  };

  const [filtros, setFiltros] = useState(false);

  const onProfesionalChanged = (event: any) => {
    // if (event.target.value) {
    //     const profesionalTurno = posiblesProfesionalTurnos.find(x => x.profesional === event.target.value);
    //     const primerTurno = profesionalTurno!.turnos[0];
    //     const horaDelTurno = Number.parseInt(primerTurno!.split(":")[0]);

    //     turnosHorarios.forEach((turnoHorario, iTurnoHorario) => {
    //         if (horaDelTurno >= turnoHorario.desde && horaDelTurno < turnoHorario.hasta) {
    //             setTabSeleccionada(iTurnoHorario);
    //         }
    //     })
    // }

    setProfesionalSeleccionado(event.target.value);
    dispatch(setMedicoFiltrado(event.target.value));
    if (event.target.value) {
      setVerFavoritos(false);
    }
  };

  const onFechaSeleccionadaChanged = (newValue: Date | Date[]) => {
    const newDate = newValue as Date;
    setFechaSeleccionada(newDate);
  };

  const [fechaSeleccionada, setFechaSeleccionada] = useState<Date | undefined>(
    undefined
  );

  const [posiblesProfesionalTurnos, setPosiblesProfesionalTurnos] = useState<
    Array<IProfesionalTurnos>
  >([]);

  const [fechasLibres, setFechasLibres] = useState<Array<string>>([]);

  useEffect(() => {
    if (medicoFiltrado) {
      setProfesionalSeleccionado(medicoFiltrado);
      setFiltros(true);
    } else {
      setFiltros(false);
    }
  }, [medicoFiltrado]);

  useEffect(() => {
    setLoading(true);
    if (horariosDisponibles.length > 0) {
      let aux = [...horariosDisponibles];
      aux = aux.sort((a, b) => {
        return (
          convertDatestringToDate(a).getTime() -
          convertDatestringToDate(b).getTime()
        );
      });
      setFechasLibres(aux);
      if (aux.length > 0) {
        setFechaSeleccionada(convertDatestringToDate(aux[0]));
      }
      setLoading(false);
    } else {
      getDiasLibres(especialidad)
        .then((recibido) => {
          if (recibido.length > 0) {
            recibido.sort((a, b) => {
              return (
                convertDatestringToDate(a).getTime() -
                convertDatestringToDate(b).getTime()
              );
            });
          }
          setFechasLibres(recibido);
          if (recibido.length > 0) {
            setFechaSeleccionada(convertDatestringToDate(recibido[0]));
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (fechaSeleccionada) {
      setLoadingHorarios(true);
      setSelectedProfesionalTurno(undefined);
      getInfoDia(
        especialidad,
        fechaSeleccionada!,
        cuilPacienteSeleccionado.toString()
      )
        .then((valoresRecibidos) => {
          valoresRecibidos = valoresRecibidos.filter(
            (x) => x.turnos.length > 0 || x.sobreturnos.length > 0
          );
          // respuesta.sort((a, b) => { return b.fecha._seconds - a.fecha._seconds; });
          valoresRecibidos = valoresRecibidos.sort((a, b) => {
            if (!a.favorito && b.favorito) return 1;
            if (!b.favorito && a.favorito) return -1;

            if (!a.promedio && b.promedio) return 1;
            if (!b.promedio && a.promedio) return -1;
            if (!b.promedio && !a.promedio) return 0;

            return b.promedio - a.promedio;
          });
          setPosiblesProfesionalTurnos(valoresRecibidos);

          const tabs: Array<number> = [];

          valoresRecibidos.forEach((profesionalTurno, iProfesionalTurno) => {
            const primerTurno = profesionalTurno!.turnos[0];
            const horaDelTurno = Number.parseInt(primerTurno!.split(":")[0]);

            turnosHorarios.forEach((turnoHorario, iTurnoHorario) => {
              if (
                horaDelTurno >= turnoHorario.desde &&
                horaDelTurno < turnoHorario.hasta
              ) {
                tabs.push(iTurnoHorario);
              }
            });
          });
          setTabsSeleccionadas(tabs);

          setLoadingHorarios(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [fechaSeleccionada]);

  const [motivoConsulta, setMotivoConsulta] = useState<string>("");
  const motivoLimiteCaracteres = 150;

  const onSiguientePressed = () => {
    dispatch(setHorarioTurno(selectedTurno));
    dispatch(setIdProfesional(selectedProfesionalTurno!.profesional));
    dispatch(setFechaTurno(convertDateToDatestring(fechaSeleccionada!)));
    dispatch(setMotivo(motivoConsulta));
    dispatch(setNombreMedico(selectedProfesionalTurno!.nombre));
    dispatch(setImagenMedicoURL(selectedProfesionalTurno!.photoURL));
    dispatch(setMatriculaMedico(selectedProfesionalTurno!.matricula));
    history.push("/dashboard/consultas-programadas/confirmar-turno");
  };

  let hayTurnos = false;
  posiblesProfesionalTurnos.forEach((profturno) => {
    if (
      (profesionalSeleccionado === undefined ||
        profesionalSeleccionado === profturno.profesional) &&
      profturno.turnos.length > 0
    ) {
      hayTurnos = true;
    }
  });

  const turnosHorarios = [
    {
      nombre: "MAÑANA",
      desde: 0,
      hasta: 12,
    },
    {
      nombre: "TARDE",
      desde: 12,
      hasta: 18,
    },
    {
      nombre: "NOCHE",
      desde: 18,
      hasta: 24,
    },
  ];

  return (
    <>
      {loading ? (
        <LoadingBubbles />
      ) : (
        <>
          <Typography paragraph>
            Seleccione profesional y/o fecha de su preferencia
          </Typography>
          <Typography paragraph>
            Seleccione la fecha en la que desea realizar la consulta en el
            calendario, y podrá ver los horarios disponibles. Seleccione un
            profesional si lo desea, o deje seleccionada la opción "cualquier
            profesional" para visualizar todos los turnos de ese día.
          </Typography>
          <Typography paragraph>
            Una vez haya seleccionado un horario, presione siguiente para
            continuar.
          </Typography>
          <Divider style={{margin: "20px 0px 20px 0px"}} />
          <Grid container spacing={2} style={{marginTop: 15, marginBottom: 20}}>
            <Grid item xs={12} sm={10} md={8}>
              <TextField
                multiline
                label="Motivo de la consulta"
                fullWidth
                variant="outlined"
                inputProps={{
                  maxLength: motivoLimiteCaracteres,
                }}
                helperText={`${motivoConsulta.length}/${motivoLimiteCaracteres} (opcional)`}
                onChange={(e) => setMotivoConsulta(e.target.value)}
              />
            </Grid>
          </Grid>

          <Divider />
          <Grid container>
            <Grid item xs={12} md={4}>
              <InteractiveCalendar
                minDate={
                  fechasLibres.length > 0
                    ? convertDatestringToDate(fechasLibres[0])
                    : new Date()
                }
                maxDate={
                  fechasLibres.length > 0
                    ? convertDatestringToDate(
                        fechasLibres[fechasLibres.length - 1]
                      )
                    : undefined
                }
                tileDisabled={(props: {date: Date; view: string}) => {
                  if (loadingHorarios) return true
                  const dateString = convertDateToDatestring(props.date);
                  if (fechasLibres.find((x) => x === dateString)) {
                    return false;
                  }
                  //if (props.date.getDate() === 10) return true;
                  //if (props.date.getDay() === 0) return true;
                  return true;
                }}
                value={fechaSeleccionada}
                onChange={onFechaSeleccionadaChanged}
              />
            </Grid>
            <Grid item xs={12} md={8} style={{padding: 15}}>
              {loadingHorarios ? (
                <div style={{marginTop: 100}}>
                  <LoadingBubbles />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Horarios Disponibles</Typography>
                    <div style={{flexGrow: 1}} />
                    <Button
                      onClick={() => {
                        setFiltros(!filtros);
                      }}
                      style={{width: 100}}
                      endIcon={
                        filtros ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                      }
                    >
                      Filtros
                    </Button>
                  </div>
                  <Collapse in={filtros} timeout="auto" unmountOnExit>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <div style={{flexGrow: 1}} />
                      <FormControl
                        style={{width: 200}}
                        variant="outlined"
                        size="small"
                      >
                        <InputLabel>Profesional</InputLabel>
                        <Select
                          label="Profesional"
                          value={profesionalSeleccionado}
                          onChange={onProfesionalChanged}
                          inputProps={{
                            name: "profesional",
                          }}
                        >
                          <MenuItem aria-label="None" value={undefined}>
                            (Cualquier Profesional)
                          </MenuItem>
                          {posiblesProfesionalTurnos.map(
                            (profesionalTurno, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={profesionalTurno.profesional}
                                >
                                  {profesionalTurno.nombre}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={verFavoritos}
                            onChange={(event, checked) => {
                              setVerFavoritos(checked);
                              if (checked) {
                                setProfesionalSeleccionado(undefined);
                              }
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Mostrar Favoritos"
                      />
                    </div>
                  </Collapse>
                  <Divider style={{marginTop: 15, marginBottom: 15}} />
                  {hayTurnos ? (
                    <>
                      {posiblesProfesionalTurnos.map(
                        (profesionalTurno, indexProfesionalTurno) => {
                          const isProfesionalTurnosSelected =
                            selectedProfesionalTurno === profesionalTurno;
                          const show =
                            (profesionalSeleccionado === undefined ||
                              profesionalSeleccionado ===
                                profesionalTurno.profesional) &&
                            profesionalTurno.turnos.length > 0;
                          if (!show) {
                            return null;
                          }
                          return (
                            <MedicoTurnosDisponibles
                              profesionalTurno={profesionalTurno}
                              key={indexProfesionalTurno}
                              handleChangeTab={(newTab: number) => {
                                handleChangeTab(newTab, indexProfesionalTurno);
                              }}
                              tabSeleccionada={
                                tabsSeleccionadas[indexProfesionalTurno]
                              }
                              turnosHorarios={turnosHorarios}
                              selectedTurno={selectedTurno}
                              isProfesionalTurnosSelected={
                                isProfesionalTurnosSelected
                              }
                              selectedSobreturno={selectedSobreturno}
                              setSelectedProfesionalTurno={
                                setSelectedProfesionalTurno
                              }
                              setSelectedTurno={setSelectedTurno}
                              setSelectedSobreturno={setSelectedSobreturno}
                              mostrar={verFavoritos ? "favoritos" : "todos"}
                            />
                          );
                        }
                      )}
                    </>
                  ) : (
                    <div style={{marginTop: 50}}>
                      <Typography>
                        No hay turnos para el dia seleccionado.
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <div className={classes.buttonsRoot} style={{marginTop: 15}}>
            <Volver onClickHandler={() => history.goBack()} />
            <Siguiente
              disabled={
                selectedProfesionalTurno === undefined || selectedTurno === ""
              }
              onClickHandler={onSiguientePressed}
            />
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(SeleccionarTurno);
