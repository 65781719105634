import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

//MATERIAL-UI
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {Box, Divider, Grid} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

import {RootState} from "../../../../store/rootReducer";
import PerfilPaciente from "../../../../components/PerfilPaciente/PerfilPaciente";
import {
  cancelarCita,
  registrarIngresoPaciente,
} from "../../../../apis/citaInstantaneaAPI";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {IPostRegistrarIngresoPaciente} from "../../../../Interfaces/IPostRegistrarIngresoPaciente";
import useGeolocation from "../../../../hooks/useGeolocation";
import {io} from "socket.io-client";

import ring from "../../../../assets/sfx/notif_paciente.wav";
import {getCurrentTime} from "../../../../hooks/useTime";
const ringAudio = new Audio(ring);

const LlamadoConsultorio: React.FC = () => {
  const history = useHistory();
  const location = useGeolocation();
  const {idSalaVideollamada, especialidad} = useSelector(
    (state: RootState) => state.verMedicoOnline
  );
  const {cuilPacienteSeleccionado, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );

  const playSound = (audioFile: HTMLAudioElement) => {
    audioFile.play();
  };

  useEffect(() => {
    playSound(ringAudio);
  }, []);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
      reconnectionDelayMax: 10000,
      secure: true,
      query: {
        tipo: "paciente",
        usuario: cuilPacienteSeleccionado.toString(),
        sesion: idSalaVideollamada,
        nombre: pacientesByCuil[cuilPacienteSeleccionado].NOMBRE,
        apellido: pacientesByCuil[cuilPacienteSeleccionado].APELLIDO,
        especialidad: especialidad,
        credencial: pacientesByCuil[cuilPacienteSeleccionado].NRO_CREDENCIAL,
        estado: `Llamado a Consultorio`,
        ambiente: `${process.env.REACT_APP_URL_PACIENTES}`,
        // "horaConexion": getCurrentTime().getTime().toString()
      },
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const onEntrarConsultorio = async () => {
    setLoading(true);

    let locationToSend = {
      latitud: location.coordinates!.lat,
      longitud: location.coordinates!.lng,
    };

    let registroIngreso: IPostRegistrarIngresoPaciente = {
      id: {
        usuario: cuilPacienteSeleccionado.toString(),
        sesion: idSalaVideollamada,
      },
      origen: "web",
      ubicacion: locationToSend,
    };

    let dataRecibida = await registrarIngresoPaciente(registroIngreso);
    setLoading(false);
    history.push("/dashboard/ver-medico-online/consultorio");
  };

  const [loading, setLoading] = React.useState(false);

  const onCancelarCita = async () => {
    setLoading(true);
    await cancelarCita({
      usuario: cuilPacienteSeleccionado.toString(),
      sesion: idSalaVideollamada,
    });
    setLoading(false);
    history.push("/dashboard");
  };

  const servicios = [
    {
      id: "INGRESAR",
      primaryText: "Ingresar al consultorio",
      icon: VideocamOutlinedIcon,
      onClick: onEntrarConsultorio,
    },
    {
      id: "CANCELAR",
      primaryText: "Cancelar la consulta",
      icon: CancelOutlinedIcon,
      onClick: onCancelarCita,
    },
  ];

  const opcionesList = servicios.map((item, index) => (
    <Box key={index} borderBottom="1px solid #efefef">
      <ListItem
        style={{padding: "20px 10px 20px 10px"}}
        button
        onClick={item.onClick}
      >
        <ListItemIcon>
          <item.icon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary={item.primaryText} />
        <ListItemSecondaryAction onClick={item.onClick}>
          <IconButton edge="end">
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  ));

  if (loading) {
    return <LoadingBubbles />;
  }

  // const Ubicacion = () => {
  //     let latitud = undefined;
  //     let longitud = undefined;
  //     navigator.geolocation.getCurrentPosition(
  //         (geoData) => {
  //             latitud = geoData.coords.latitude;
  //             longitud = geoData.coords.longitude;
  //             setUbicacion({ latitud: latitud, longitud: longitud });
  //         }
  //     )
  //     return (
  //         <>
  //             <Typography>
  //                 {latitud}
  //             </Typography>
  //             <Typography>
  //                 {longitud}
  //             </Typography>
  //         </>
  //     )
  // }

  if (!location.loaded) {
    return (
      <>
        <LoadingBubbles />
        <Box
          paddingY={2}
          borderBottom="1px solid #efefef"
          style={{marginTop: "30px"}}
        >
          <Typography variant="h5" style={{textAlign: "center"}}>
            Por favor, permitanos acceder a su ubicacion.
          </Typography>
        </Box>
      </>
    );
  }

  if (location.error !== undefined) {
    return (
      <Box
        paddingY={2}
        borderBottom="1px solid #efefef"
        style={{marginTop: "30vh"}}
      >
        <Typography variant="h5" style={{textAlign: "center"}}>
          {location.error!.code === 0
            ? "Por favor actualice su navegador."
            : location.error!.code === 1
            ? "Usted ha bloqueado el acceso a su ubicacion.\nPor favor, permitalo y actualice la página."
            : location.error!.message}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Divider />
      <Box my={3}>
        <PerfilPaciente paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
      </Box>
      <Divider />
      <h2>Ha sido llamado al consultorio</h2>
      <Divider />
      <Grid item xs={12} sm={10} md={7} lg={5}>
        <List>{opcionesList}</List>
      </Grid>
    </>
  );
};

export default LlamadoConsultorio;
