import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {
  convertDateToDatestring,
  convertDatestringToDate,
  convertDiaHoraToDate,
  convertDateToDiaHora,
} from "../../../utils/dateTimeHelper";
import {
  setCuilPacienteSeleccionado,
  setEspecialidad,
  setIdSalaVideollamada,
} from "../../../store/profesional-medico/atender-consultorio/atenderConsultorioSlice";
import {useDispatch, useSelector} from "react-redux";

//import {ReactComponent as GroupsIcon} from "../../../assets/images/groups.svg";
import {ITurnoAgendado} from "../../../Interfaces/ITurnoAgendado";
//import {ReactComponent as PermContactCalendarIcon} from "../../../assets/images/perm-contact-calendar.svg";
import PersonIcon from "@material-ui/icons/Person";
//import {ReactComponent as PersonalInjuryIcon} from "../../../assets/images/personal-injury.svg";
import {RootState} from "../../../store/rootReducer";
import SectionCalendario from "./SectionCalendario";
import SectionResumenPaciente from "./SectionResumenPaciente";
import SectionTurno from "./SectionTurno";
import TurnosDiaMedico from "./TurnosDiaMedico";
import {estaLibreParaInstantaneas} from "../../../apis/citaInstantaneaAPI";
import {getFirestore} from "../../../db";
import newhomemedico from "../../../assets/images/newhome-medico.png";
import {obtenerDatosUsuario} from "../../../apis/pacientesAPI";
import ring from "../../../../src/assets/sfx/notif_medico_guardia.wav";
import {useHistory} from "react-router-dom";
import {useTime} from "../../../hooks/useTime";

//MATERIAL-UI

const NewHomeMedico = () => {
  const {email, especialidades} = useSelector((state: RootState) => state.user);
  const [proximoTurno, setProximoTurno] = React.useState<
    ITurnoAgendado | undefined | null
  >(undefined);
  const [libreParaInstantaneas, setLibreParaInstantaneas] = useState(false);
  const [pacientesEnEsperaProgramados, setPacientesEnEsperaProgramados] =
    React.useState<Array<ITurnoAgendado>>([]);
  const [citasAgendadas, setCitasAgendadas] =
    useState<Array<ITurnoAgendado> | null>(null);
  const [cantidadesEnEspera, setCantidadesEnEspera] = React.useState<
    Array<number>
  >([]);
  const [pacientesAtendidosMes, setPacientesAtendidosMes] = React.useState(0);
  const [pacientesAtendidosAnio, setPacientesAtendidosAnio] = React.useState(0);
  const [fechaSeleccionada, setFechaSeleccionada] = React.useState(new Date());
  const [turnosAtendidosLoading, setTurnosAtendidosLoading] =
    React.useState(true);
  const [fechasConTurnos, setFechasConTurnos] = React.useState<Array<string>>(
    []
  );
  const [error, setError] = React.useState({
    error: false,
    title: "",
    message: "",
  });
  const now = useTime(60000);
  const ringAudio = new Audio(ring);
  const unsubscribesPacientes: Array<() => void> = [];
  const dispatch = useDispatch();
  const history = useHistory();
  const primerCarga = React.useRef(true);

  const findProximoTurno = () => {
    primerCarga.current = true;
    const fechaConverted = convertDateToDatestring(new Date()).replaceAll(
      "/",
      "-"
    );
    const unsubscribeFechas = getFirestore()
      .collection("CitasProgramadas")
      .doc(email)
      .collection("Turnos")
      .where("dia", ">=", fechaConverted)
      .onSnapshot((docs) => {
        const fechaActual = new Date();
        // Se agregan 5 segundos porque cuando toma la fecha actual la trae con unos milisegundos de menos
        fechaActual.setSeconds(fechaActual.getSeconds() + 5);
        const fechas: Array<string> = [];
        docs.forEach((doc) => {
          fechas.push(doc.id as string);
        });
        if (fechas.length > 0) {
          const traerTurnos = async () => {
            const fechasAnteriores = [];
            let turnoEncontrado = false;
            for (let fecha of fechas) {
              const turnosActivo = await getFirestore()
                .collection("CitasProgramadas")
                .doc(email)
                .collection("Turnos")
                .doc(fecha)
                .collection("sesiones")
                .where("estado", "==", "Activa")
                .orderBy("fechaTurnoTS", "asc")
                .get();

              if (turnosActivo.size > 0) {
                const proximoTurnoEncontrado = turnosActivo.docs.find(
                  (turnoSnapshot) => {
                    const turno = turnoSnapshot.data() as ITurnoAgendado;
                    return (
                      convertDiaHoraToDate(turno.turno).getTime() >
                      fechaActual.getTime()
                    );
                  }
                );
                if (proximoTurnoEncontrado) {
                  setProximoTurno({
                    ...(proximoTurnoEncontrado.data() as ITurnoAgendado),
                    sesion: proximoTurnoEncontrado.id,
                  });
                  let fechaASeleccionar = convertDatestringToDate(
                    proximoTurnoEncontrado.data().turno.día
                  );
                  if (
                    primerCarga.current ||
                    fechaASeleccionar < fechaSeleccionada
                  ) {
                    // setFechaSeleccionada(fechaASeleccionar);
                    primerCarga.current = false;
                  }
                  turnoEncontrado = true;
                  break;
                }
              }
              fechasAnteriores.push(fecha);
            }
            if (!turnoEncontrado) {
              setProximoTurno(null);
              setFechaSeleccionada(new Date());
            }
            setFechasConTurnos(fechasAnteriores);
          };
          traerTurnos();
        } else {
          setProximoTurno(null);
          setFechaSeleccionada(new Date());
        }
      });

    return () => unsubscribeFechas();
  };

  useEffect(() => {
    let unsubscribeTurnos: Array<() => void> = [];
    if (fechasConTurnos.length > 0) {
      for (let fecha of fechasConTurnos) {
        unsubscribeTurnos.push(
          getFirestore()
            .collection("CitasProgramadas")
            .doc(email)
            .collection("Turnos")
            .doc(fecha)
            .collection("sesiones")
            .onSnapshot((docs) => {
              const fechaActual = new Date();
              fechaActual.setSeconds(fechaActual.getSeconds() + 5);
              const turnos: Array<ITurnoAgendado> = [];
              docs.forEach((doc) => {
                turnos.push({...doc.data(), sesion: doc.id} as ITurnoAgendado);
              });
              const proximoTurnoEnFecha = turnos.find(
                (turno) =>
                  turno.estado === "Activa" &&
                  convertDiaHoraToDate(turno.turno).getTime() >
                    fechaActual.getTime()
              );
              if (proximoTurnoEnFecha) {
                setProximoTurno(proximoTurnoEnFecha);
                setFechaSeleccionada(
                  convertDatestringToDate(proximoTurnoEnFecha.turno.día)
                );
              }
            })
        );
      }
    }
    return () => {
      unsubscribeTurnos.forEach((unsubscribe) => unsubscribe());
    };
  }, [fechasConTurnos]);

  useEffect(() => {
    let mounted = true;
    const fechaSeleccionadaConverted = convertDateToDatestring(
      fechaSeleccionada
    ).replaceAll("/", "-");
    setCitasAgendadas(null);
    const unsubscribeCitas = getFirestore()
      .collection("CitasProgramadas")
      .doc(email)
      .collection("Turnos")
      .doc(fechaSeleccionadaConverted)
      .collection("sesiones")
      .where("estado", "!=", "Cancelada")
      .onSnapshot((datosRecibidos) => {
        if (mounted === false) return;

        getNombresPacientes(datosRecibidos.docs).then((datosR) => {
          if (mounted === false) return;
          let datos: Array<ITurnoAgendado> = [...datosR];

          //#Region - Calculo de numero de citas programadas
          const fechaActual = convertDateToDiaHora(new Date());
          const fechaSeleccionadaDiaHora =
            convertDateToDiaHora(fechaSeleccionada);
          if (fechaActual.día === fechaSeleccionadaDiaHora.día) {
            const pacientesEnEspera = datos.filter((paciente) => {
              return paciente.enEspera && paciente.estado === "Activa";
            });
            setPacientesEnEsperaProgramados(
              pacientesEnEspera as ITurnoAgendado[]
            );
          }
          //#EndRegion

          datos.sort((a, b) => {
            return (
              convertDiaHoraToDate(a.turno).getTime() -
              convertDiaHoraToDate(b.turno).getTime()
            );
          });
          if (proximoTurno) {
            const fechaProxTurno = proximoTurno?.turno;
            const fechaSelec = convertDateToDiaHora(fechaSeleccionada);
            if (fechaProxTurno.día === fechaSelec.día) {
              const proxTurno = datos.find(
                (d) => d.sesion === proximoTurno?.sesion
              );
              if (!proxTurno) {
                findProximoTurno();
              }
            }
          }
          setCitasAgendadas(datos);
        });
      });
    return () => {
      mounted = false;
      unsubscribeCitas();
    };
  }, [fechaSeleccionada]);

  useEffect(() => {
    const fechaActual = convertDateToDiaHora(new Date());
    const fechaSeleccionadaDiaHora = convertDateToDiaHora(fechaSeleccionada);
    if (fechaActual.día !== fechaSeleccionadaDiaHora.día) {
      const fechaSeleccionadaConverted = convertDateToDatestring(
        new Date()
      ).replaceAll("/", "-");
      const unsubscribePacientesEspera = getFirestore()
        .collection("CitasProgramadas")
        .doc(email)
        .collection("Turnos")
        .doc(fechaSeleccionadaConverted)
        .collection("sesiones")
        .where("enEspera", "==", true)
        .where("estado", "==", "Activa")
        .onSnapshot((datosRecibidos) => {
          const pacientesEnEspera = datosRecibidos.docs.map((doc) => {
            const turno = doc.data() as ITurnoAgendado;
            turno.sesion = doc.id;
            return turno;
          });

          setPacientesEnEsperaProgramados(
            pacientesEnEspera as ITurnoAgendado[]
          );

          if (pacientesEnEspera.length > 0) {
            findProximoTurno();
          }
        });
      return () => {
        unsubscribePacientesEspera();
      };
    }
  }, [fechaSeleccionada]);

  useEffect(() => {
    if (citasAgendadas === null) return;
    if (
      citasAgendadas.length > 0 &&
      proximoTurno !== undefined &&
      proximoTurno !== null
    ) {
      // Si existe un proximoTurno y se vence (estado = "Vencido") se busca el próximoTurno que exista llamando a findProximoTurno
      const proximoTurnoEncontrado = citasAgendadas.find((turno) => {
        if (turno) {
          if (turno.estado === "Activa") {
            const actualTime = new Date().getTime();
            const proximoTurnoTime = convertDiaHoraToDate(
              proximoTurno.turno
            ).getTime();
            const turnoTime = convertDiaHoraToDate(turno.turno).getTime();
            return turnoTime < proximoTurnoTime && turnoTime > actualTime;
          }
          return (
            turno.turno.día === proximoTurno.turno.día &&
            turno.turno.horas === proximoTurno.turno.horas
          );
        }
        return false;
      });
      if (proximoTurnoEncontrado) {
        if (
          proximoTurnoEncontrado.turno.día === proximoTurno.turno.día &&
          proximoTurnoEncontrado.turno.horas === proximoTurno.turno.horas
        ) {
          if (proximoTurnoEncontrado.estado !== proximoTurno.estado) {
            findProximoTurno();
          }
        } else {
          findProximoTurno();
        }
      }
    }
  }, [citasAgendadas]);

  const getNombresPacientes = async (turnos: any) => {
    if (turnos.length === 0) return [];
    return await Promise.all(
      turnos.map(async (doc: any) => {
        const turno = doc.data() as any;
        turno.sesion = doc.id;
        const usuario = await obtenerDatosUsuario(turno?.dni, turno?.paciente);
        if (usuario !== undefined) {
          turno.nombrePaciente =
            usuario.paciente.NOMBRE + " " + usuario.paciente.APELLIDO;
          return turno;
        }
      }) as unknown as Array<ITurnoAgendado>
    );
  };

  useEffect(() => {
    estaLibreParaInstantaneas(email)
      .then((datosRecibidos) => {
        setLibreParaInstantaneas(datosRecibidos);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [now, email]);

  useEffect(() => {
    let mounted = true;
    if (libreParaInstantaneas) {
      let cantidadesEnEsperaLocal = new Array<number>(especialidades!.length);

      for (let index = 0; index < cantidadesEnEsperaLocal.length; index++) {
        cantidadesEnEsperaLocal[index] = 0;
      }
      setCantidadesEnEspera(cantidadesEnEsperaLocal);

      if (mounted){
        especialidades!
        .filter((x) => x.consultaEspontanea)
        .forEach((especialidad, indexEspecialidad) => {
          setTimeout(() => {
            const unsubscribePacientes = getFirestore()
              .collection("CitasInstantaneas")
              .doc(especialidad.descripcion)
              .collection("sesiones")
              .where("estado", "==", "P")
              .where("profesional", "==", null)
              .onSnapshot((sesiones) => {
                let temp = [...cantidadesEnEsperaLocal];

                let playSound = false;
                if (
                  sesiones.size > cantidadesEnEsperaLocal[indexEspecialidad] ||
                  (cantidadesEnEsperaLocal[indexEspecialidad] === undefined &&
                    sesiones.size > 0)
                ) {
                  playSound = true;
                }
                if (playSound && mounted) {
                  ringAudio.play();
                }
                temp[indexEspecialidad] = sesiones.size;
                cantidadesEnEsperaLocal = [...temp];
                setCantidadesEnEspera(cantidadesEnEsperaLocal);
              });
            unsubscribesPacientes.push(unsubscribePacientes);
          }, indexEspecialidad * 2000);
        }); 
      }      
    }
    return () => {
      mounted = false;
      unsubscribesPacientes.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [libreParaInstantaneas]);

  useEffect(() => {
    findProximoTurno();
    let especialidadesCount = 0;
    const actualYear = new Date().getFullYear();
    getFirestore()
      .collection("CitasProgramadas")
      .doc(email)
      .collection("Turnos")
      .where("dia", ">=", `${actualYear}-01-01`)
      .get()
      .then((diasSnapshot) => {
        const diasConTurnos: Array<string> = [];
        diasSnapshot.docs.forEach((dia) => {
          diasConTurnos.push(dia.id);
        });
        diasConTurnos.forEach((dia) => {
          let pacientesMes = 0;
          let pacientesAnio = 0;
          getFirestore()
            .collection("CitasProgramadas")
            .doc(email)
            .collection("Turnos")
            .doc(dia)
            .collection("sesiones")
            .get()
            .then((sesiones) => {
              sesiones.docs.forEach((turno) => {
                if (turno.data().estado === "Terminada") {
                  const fechaProg = convertDiaHoraToDate(turno.data().turno);
                  if (fechaProg.getMonth() === now.getMonth()) {
                    pacientesMes++;
                  } else {
                    pacientesAnio++;
                  }
                }
              });
              setPacientesAtendidosMes((prevState) => prevState + pacientesMes);
              setPacientesAtendidosAnio(
                (prevState) => prevState + pacientesAnio
              );
            });
        });
      });
    especialidades!
      .filter((x) => x.consultaEspontanea)
      .forEach((especialidad, indexEspecialidad) => {
        setTimeout(() => {
          getFirestore()
            .collection("CitasInstantaneas")
            .doc(especialidad.descripcion)
            .collection("sesiones")
            .where("estado", "==", "T")
            .where("emailProfesional", "==", email)
            .get()
            .then((sesiones) => {
              let pacientesMes = 0;
              let pacientesAnio = 0;
              sesiones.docs.forEach((doc) => {
                if (doc) {
                  const fecha = new Date(
                    doc.data().fecha.seconds * 1000 +
                      doc.data().fecha.nanoseconds / 1000000
                  );
                  if (
                    fecha.getMonth() === now.getMonth() &&
                    fecha.getFullYear() === now.getFullYear()
                  ) {
                    pacientesMes++;
                  } else if (fecha.getFullYear() === now.getFullYear()) {
                    pacientesAnio++;
                  }
                }
              });
              setPacientesAtendidosMes((prevState) => prevState + pacientesMes);
              setPacientesAtendidosAnio(
                (prevState) => prevState + pacientesAnio
              );
              especialidadesCount++;
              if (
                especialidadesCount ===
                especialidades!.filter((x) => x.consultaEspontanea).length
              ) {
                setTurnosAtendidosLoading(false);
              }
            });
        }, indexEspecialidad * 2000);
      });
  }, []);

  let cantidadEspera = 0;
  cantidadesEnEspera.forEach((cantidad) => {
    cantidadEspera += cantidad;
  });

  const onAtenderPaciente = (turno?: ITurnoAgendado) => {
    if (pacientesEnEsperaProgramados.length <= 0) {
      showError("No hay pacientes en espera para atender");
      return;
    }
    let pacienteAtender = pacientesEnEsperaProgramados[0];
    if (turno) {
      const pacienteEncontrado = pacientesEnEsperaProgramados.find(
        (p) => p.paciente === turno.paciente
      );
      if (pacienteEncontrado) {
        pacienteAtender = pacienteEncontrado;
      } else {
        showError("No se encontró al paciente en espera");
        return;
      }
    }
    dispatch(
      setCuilPacienteSeleccionado(Number.parseInt(pacienteAtender.paciente!))
    );
    dispatch(setIdSalaVideollamada(pacienteAtender.sesion));
    dispatch(setEspecialidad(pacienteAtender.especialidad));
    history.push("/dashboard/atender-consultorio/datos-paciente");
  };

  const handleChangeFecha = (nuevaFecha: Date) => {
    if (nuevaFecha.toDateString() !== fechaSeleccionada.toDateString()) {
      setFechaSeleccionada(nuevaFecha);
    }
  };

  const showError = (message: string) => {
    setError({
      error: true,
      title: "Ha ocurrido un error",
      message: message,
    });
  };

  return (
    <>
      <Grid container justify="center" alignItems="stretch" spacing={2}>
        <Grid
          container
          item
          xs={7}
          md={4}
          lg={4}
          style={{display: "grid", gridAutoColumns: "1fr", gap: 16}}
        >
          <SectionCalendario
            proximoTurno={proximoTurno}
            fechaSeleccionada={fechaSeleccionada}
            onTurnoExpired={() => findProximoTurno()}
            onChangeFecha={(fecha) => handleChangeFecha(fecha)}
          />
          <Grid container item spacing={2}>
            <Grid item xs={6} style={{display: "grid", gridAutoColumns: "1fr"}}>
              <SectionResumenPaciente
                period="mes"
                Icon={PersonIcon}
                cantidadPacientes={pacientesAtendidosMes}
                loading={turnosAtendidosLoading}
              />
            </Grid>
            <Grid item xs={6} style={{display: "grid", gridAutoColumns: "1fr"}}>
              <SectionResumenPaciente
                period="año"
                Icon={PersonIcon}
                cantidadPacientes={pacientesAtendidosAnio}
                loading={turnosAtendidosLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={7}
          md={4}
          lg={4}
          style={{display: "grid", gridAutoColumns: "1fr"}}
        >
          <TurnosDiaMedico
            atenderPaciente={(turno) => onAtenderPaciente(turno)}
            fechaSeleccionada={fechaSeleccionada}
            citasAgendadas={citasAgendadas}
          />
        </Grid>
        <Grid
          item
          xs={7}
          md={4}
          lg={4}
          style={{display: "grid", gridAutoColumns: "1fr", gap: 16}}
        >
          <SectionTurno
            title="Turnos programados"
            icon={<PersonIcon />}
            onClick={() => setFechaSeleccionada(new Date())}
            pacientesEspera={pacientesEnEsperaProgramados.length}
            buttonText={
              convertDateToDatestring(fechaSeleccionada) !==
              convertDateToDatestring(new Date())
                ? "Ver pacientes"
                : ""
            }
          />
          <SectionTurno
            title="Turnos de guardia"
            icon={<PersonIcon />}
            onClick={() => {
              history.push("/dashboard/guardia");
            }}
            pacientesEspera={cantidadEspera}
            buttonText="Ir a la sala"
            isGuardia={libreParaInstantaneas}
          />
          <Card>
            <CardContent style={{padding: 0, width: "100%", height: "100%"}}>
              <img
                src={newhomemedico}
                alt="imagen app mobile"
                width="100%"
                height="100%"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={error.error}>
        <DialogTitle>{error.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{error.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setError({...error, error: false});
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewHomeMedico;
