import {
  Button,
  Card,
  CardHeader,
  Collapse,
  CardContent,
  TextField,
  Toolbar,
  Typography,
  useTheme,
  CardActionArea,
} from "@material-ui/core";
import {
  ArrowBackIos,
  ExpandLess,
  ExpandMore,
  PersonOutlined,
  SearchOutlined,
} from "@material-ui/icons";
import React from "react";
import {
  buscarPacientes,
  obtenerHistorialClinico,
} from "../../apis/historiaClinicaAPI";
import CardHistoriaClinica from "../CardHistoriaClinica/CardHistoriaClinica";
import LoadingBubbles from "../LoadingBubbles/LoadingBubbles";
import {IElementoHistorial} from "../../Interfaces/IElementoHistorial";
import {IResultadoBusquedaPaciente} from "../../Interfaces/IResultadoBuquedaPaciente";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Pagination} from "@material-ui/lab";
import {IPaciente} from "../../Interfaces/IPaciente";

interface Props {
  paciente?: string;
}

function BusquedaPacienteHistorial({paciente}: Props) {
  const [cuilPaciente, setCuilPaciente] = React.useState("");
  const [apellidoPaciente, setApellidoPaciente] = React.useState("");
  const [nombrePaciente, setNombrePaciente] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [pacientes, setPacientes] = React.useState<
    Array<IResultadoBusquedaPaciente>
  >([]);
  const theme = useTheme();
  const [pacienteSeleccionado, setPacienteSeleccionado] = React.useState<
    IResultadoBusquedaPaciente | undefined
  >(undefined);
  const [historialClinico, setHistorialClinico] = React.useState<
    Array<IElementoHistorial>
  >([]);
  const [loadingHistorial, setLoadingHistorial] = React.useState(false);

  const [filtros, setFiltros] = React.useState(false);
  const [apellidoProfesional, setApellidoProfesional] = React.useState("");
  const [especialidad, setEspecialidad] = React.useState("");
  const [fechaDesde, setFechaDesde] = React.useState<Date | null>(null);
  const [fechaHasta, setFechaHasta] = React.useState<Date | null>(null);

  const elementosPerPag = 5;
  const [pagina, setPagina] = React.useState(0);

  React.useEffect(() => {
    if (pacienteSeleccionado) {
      buscarHistorialPaciente();
    }
  }, [pacienteSeleccionado]);

  React.useEffect(() => {
    if (paciente) {
      obtenerHistorialClinico(
        paciente,
        fechaDesde,
        fechaHasta,
        especialidad,
        apellidoProfesional
      )
        .then((res) => {
          setLoadingHistorial(false);
          res.sort((a, b) => {
            return b.fecha._seconds - a.fecha._seconds;
          });
          setHistorialClinico(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [paciente]);

  const buscarHistorialPaciente = () => {
    setLoadingHistorial(true);
    setPagina(0);
    obtenerHistorialClinico(
      pacienteSeleccionado!.cuil,
      fechaDesde,
      fechaHasta,
      especialidad,
      apellidoProfesional
    )
      .then((respuesta) => {
        setLoadingHistorial(false);
        respuesta.sort((a, b) => {
          return b.fecha._seconds - a.fecha._seconds;
        });
        setHistorialClinico(respuesta);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const buscar = () => {
    setLoading(true);
    buscarPacientes(cuilPaciente, apellidoPaciente, nombrePaciente)
      .then((resultado) => {
        setLoading(false);
        setPacientes(resultado);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const limpiarFiltros = () => {
    setFechaDesde(null);
    setFechaHasta(null);
    setApellidoProfesional("");
    setEspecialidad("");
    setFiltros(false);
  };

  if (pacienteSeleccionado) {
    return (
      <div>
        <Toolbar variant="dense">
          <Button
            variant="contained"
            disableElevation
            startIcon={<ArrowBackIos />}
            onClick={() => {
              setPacienteSeleccionado(undefined);
              limpiarFiltros();
            }}
          >
            Atras
          </Button>
          <div style={{flexGrow: 1}} />
          <Typography style={{margin: 10}} variant="h6">
            {pacienteSeleccionado.apellido}, {pacienteSeleccionado.nombre}
          </Typography>
          <div style={{flexGrow: 1}} />
          <Typography variant="h6">{pacienteSeleccionado.cuil}</Typography>
        </Toolbar>
        <Card
          variant="outlined"
          style={{
            margin: 20,
            backgroundColor: theme.paletaColores.fondo.segundo,
          }}
        >
          <CardActionArea
            onClick={() => {
              setFiltros(!filtros);
            }}
          >
            <CardHeader
              title="Aplicar Filtros"
              action={filtros ? <ExpandLess /> : <ExpandMore />}
            />
          </CardActionArea>
          <Collapse in={filtros} timeout="auto" unmountOnExit>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CardContent>
                <KeyboardDatePicker
                  style={{margin: 5, maxWidth: 150}}
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Desde"
                  value={fechaDesde}
                  onChange={(event) => {
                    setFechaDesde(event as Date);
                  }}
                  required
                  defaultValue={null}
                  disableFuture
                  invalidDateMessage="Fecha inválida"
                  maxDateMessage="La fecha debe ser anterior a hoy"
                  InputAdornmentProps={{style: {visibility: "hidden"}}}
                />
                <KeyboardDatePicker
                  style={{margin: 5, maxWidth: 150}}
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Hasta"
                  value={fechaHasta}
                  onChange={(event) => {
                    setFechaHasta(event as Date);
                  }}
                  required
                  defaultValue={null}
                  disableFuture
                  invalidDateMessage="Fecha inválida"
                  maxDateMessage="La fecha debe ser anterior a hoy"
                  InputAdornmentProps={{style: {visibility: "hidden"}}}
                />
                <TextField
                  style={{margin: 5}}
                  value={apellidoProfesional}
                  label="Apellido Profesional"
                  onChange={(event) => {
                    setApellidoProfesional(event.target.value as string);
                  }}
                />
                <TextField
                  style={{margin: 5}}
                  value={especialidad}
                  label="Especialidad"
                  onChange={(event) => {
                    setEspecialidad(event.target.value as string);
                  }}
                />
                <Button
                  onClick={buscarHistorialPaciente}
                  color="primary"
                  variant="contained"
                  disableElevation
                  startIcon={<SearchOutlined />}
                  style={{margin: 5}}
                  size="large"
                  disabled={loadingHistorial}
                >
                  Buscar
                </Button>
              </CardContent>
            </MuiPickersUtilsProvider>
          </Collapse>
        </Card>
        {loadingHistorial ? (
          <div style={{marginTop: "50px", marginBottom: "50px"}}>
            <LoadingBubbles />
          </div>
        ) : (
          <div style={{width: "100%"}}>
            {historialClinico.length === 0 ? (
              <div style={{marginTop: "50px", marginBottom: "50px"}}>
                <Typography>
                  No hay datos para la búsqueda realizada.
                </Typography>
              </div>
            ) : (
              historialClinico.map((elemento, index) => {
                if (
                  index >= pagina * elementosPerPag &&
                  index < (pagina + 1) * elementosPerPag
                ) {
                  return (
                    <CardHistoriaClinica
                      mostrarNotasPrivadas={false}
                      key={index}
                      elementoHistorial={elemento}
                    />
                  );
                }
                return null;
              })
            )}
            <Pagination
              style={{
                alignSelf: "center",
                justifySelf: "center",
                flexGrow: 1,
                margin: 30,
              }}
              count={Math.ceil(historialClinico.length / elementosPerPag)}
              page={pagina + 1}
              onChange={(event, value) => {
                setLoadingHistorial(true);
                setTimeout(() => {
                  setPagina(value - 1);
                  setLoadingHistorial(false);
                }, 500);
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Toolbar>
        <TextField
          style={{margin: 5, width: 200}}
          size="small"
          variant="outlined"
          value={cuilPaciente}
          label="CUIL Paciente"
          onChange={(event) => {
            setCuilPaciente(event.target.value as string);
          }}
        />
        <TextField
          style={{margin: 5, width: 300}}
          size="small"
          variant="outlined"
          value={apellidoPaciente}
          label="Apellido Paciente"
          onChange={(event) => {
            setApellidoPaciente(event.target.value as string);
          }}
        />
        <TextField
          style={{margin: 5, width: 300}}
          size="small"
          variant="outlined"
          value={nombrePaciente}
          label="Nombre Paciente"
          onChange={(event) => {
            setNombrePaciente(event.target.value as string);
          }}
        />
        <Button
          onClick={buscar}
          color="primary"
          variant="contained"
          disableElevation
          startIcon={<SearchOutlined />}
          style={{margin: 5}}
          size="large"
          disabled={
            loading || (!cuilPaciente && !nombrePaciente && !apellidoPaciente)
          }
        >
          Buscar
        </Button>
      </Toolbar>
      {loading ? (
        <div style={{marginTop: "50px", marginBottom: "50px"}}>
          <LoadingBubbles />
        </div>
      ) : (
        <>
          {pacientes.length === 0 ? (
            <div style={{marginTop: "50px", marginBottom: "50px"}}>
              <Typography>No se han encontrado pacientes</Typography>
            </div>
          ) : (
            <div style={{minHeight: "100px"}}>
              <div>
                {pacientes.map((paciente, iPaciente) => {
                  return (
                    <Toolbar
                      style={{
                        backgroundColor:
                          iPaciente % 2
                            ? theme.paletaColores.fondo.primero
                            : theme.paletaColores.fondo.segundo,
                      }}
                      variant="dense"
                      key={iPaciente}
                    >
                      <Typography variant="h6" style={{margin: 20}}>
                        {paciente.apellido}, {paciente.nombre}
                      </Typography>
                      <div style={{flexGrow: 1}} />
                      <Typography style={{margin: 20}}>
                        CUIL: {paciente.cuil}
                      </Typography>
                      <Typography style={{margin: 20}}>
                        Genero: {paciente.genero}
                      </Typography>
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          setPacienteSeleccionado(paciente);
                        }}
                      >
                        Ver historial
                      </Button>
                    </Toolbar>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BusquedaPacienteHistorial;
