import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useTheme,
  Divider,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import {cargarEncuestaPaciente} from "../../../../../apis/citaInstantaneaAPI";
import {getFirestore} from "../../../../../db";
import Rating from "@material-ui/lab/Rating";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {
  convertDatestringToDate,
  convertDateToLocaleLongDateOnly,
} from "../../../../../utils/dateTimeHelper";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface OwnProps {
  nombre: string;
  setNombre: (newNombre: string) => void;
  apellido: string;
  setApellido: (newApellido: string) => void;
  credencial: string;
  setCredencial: (newApellido: string) => void;
  fechaNacimiento: Date;
  setFechaNacimiento: (newFecha: Date) => void;
  onAceptar: () => void;
  terminos: string;
  financiador: string;
}

const TerminosCondicionesFinochietto = (props: OwnProps) => {
  const history = useHistory();
  const theme = useTheme();
  const {
    nombre,
    setNombre,
    apellido,
    setApellido,
    credencial,
    setCredencial,
    fechaNacimiento,
    setFechaNacimiento,
    onAceptar,
    terminos,
    financiador,
  } = props;
  const [openTYC, setOpenTYC] = useState(false);
  const [rechazado, setRechazado] = useState(false);

  if (rechazado) {
    return (
      <Dialog open={true}>
        <DialogTitle>Ha rechazado los terminos y condiciones</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor cierre la pestaña para salir.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog open={true}>
        {financiador.toLowerCase() !== "medife" ? (
          <>
            <DialogTitle>Completa la siguiente información</DialogTitle>
            <DialogContent>
              <Paper style={{padding: 10, margin: 10}}>
                <TextField
                  label="Nombre"
                  value={nombre}
                  onChange={(event) => {
                    setNombre(event.target.value as string);
                  }}
                  variant="outlined"
                  style={{margin: 10}}
                  fullWidth
                />
                <TextField
                  fullWidth
                  label="Apellido"
                  value={apellido}
                  onChange={(event) => {
                    setApellido(event.target.value as string);
                  }}
                  variant="outlined"
                  style={{margin: 10}}
                />
                <TextField
                  fullWidth
                  label="Credencial"
                  value={credencial}
                  onChange={(event) => {
                    let newCredencial = event.target.value as string;
                    newCredencial = JSON.stringify(newCredencial).replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    );
                    if (newCredencial.length <= 20) {
                      setCredencial(newCredencial as string);
                    }
                  }}
                  variant="outlined"
                  style={{margin: 10}}
                />
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  value={fechaNacimiento}
                  onChange={(newFecha) => {
                    setFechaNacimiento(newFecha as Date);
                  }}
                  style={{margin: 10}}
                  label="Fecha de Nacimiento"
                  format="dd/MM/yyyy"
                />
              </Paper>
              <Button
                onClick={() => {
                  setOpenTYC(true);
                }}
              >
                Ver terminos y condiciones
              </Button>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>Términos y Condiciones</DialogTitle>
            <DialogContent>{terminos}</DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={onAceptar}
          >
            Aceptar Terminos y Condiciones
          </Button>
          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              setRechazado(true);
            }}
          >
            Salir
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openTYC}>
        <DialogContent>{terminos}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenTYC(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default TerminosCondicionesFinochietto;
