import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import {io} from "socket.io-client";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import ConsultorioMedicoGuardia from "../../../pages/ProfesionalMedico/AtenderGuardia/VistaGuardia/ConsultorioMedicoGuardia/ConsultorioMedicoGuardia";
import SeleccionarEspecialidad from "../../../pages/ProfesionalMedico/AtenderGuardia/VistaGuardia/ConsultorioMedicoGuardia/ConsultorioMedicoGuardia";
import ProximoPaciente from "../../../pages/ProfesionalMedico/AtenderGuardia/VistaGuardia/ProximoPaciente/ProximoPaciente";
import VistaGuardia from "../../../pages/ProfesionalMedico/AtenderGuardia/VistaGuardia/VistaGuardia";
import {RootState} from "../../rootReducer";

const GuardiaMedico = () => {
  const {path, url} = useRouteMatch();

  const {
    especialidades,
    NOMBRE,
    APELLIDO,
    email,
    matricula,
    sesionLogeo,
    sesionLogeoInstancia,
    saque,
  } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    //CitasInstantaneas/Clínica Médica Adultos/sesiones/f9a9e367-e3a0-4a13-82f9-fcc85bbffafc
    let stringEsps = "";
    especialidades!.forEach((esp) => {
      if (esp.consultaEspontanea == "1" || esp.consultaEspontanea == 1) {
        stringEsps += `${esp.descripcion}, `;
      }
    });
    stringEsps = stringEsps.slice(0, stringEsps.length - 2);

    const socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
      reconnectionDelayMax: 10000,
      secure: true,
      query: {
        tipo: "profesional",
        usuario: `tiempototalguardia_${email}`,
        nombre: NOMBRE,
        apellido: APELLIDO,
        matricula: matricula!.toString(),
        especialidad: stringEsps,
        estado: "EstadoDeGuardia",
        ambiente: `${process.env.REACT_APP_URL_PACIENTES}`,
        // "horaConexion": getCurrentTime().getTime().toString(),
        sesionLogeoID: sesionLogeo as string,
      },
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <Switch>
        <Redirect from={path} to={`${url}/vista-guardia`} exact />
        <PrivateRoute component={VistaGuardia} path={`${path}/vista-guardia`} />
        <PrivateRoute
          component={ProximoPaciente}
          path={`${path}/proximo-paciente`}
        />
        <PrivateRoute
          component={ConsultorioMedicoGuardia}
          path={`${path}/consultorio-medico`}
        />
      </Switch>
    </>
  );
};

export default GuardiaMedico;
