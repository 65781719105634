//
// useTime hook
//
import firebase from 'firebase/app';
import '@firebase/firestore';
import { useEffect, useState } from 'react';


//get time devuelve la hora del server en el momento en que se consulta
export const getCurrentTime = () => {
  // This implementation uses Luxon: https://moment.github.io/luxon/
  //return DateTime.local();

  // You can also use moment: https://momentjs.com
  // return moment();

  // Or just use native Date objects (in general, not a good move)
//actualmente usa la hora del cliente- despues cambiarlo
  return new Date();

  //
  //Or use server time (server time logic here!)


  // Or just use unix epoch timestamps (integers, no timezones)
  // return (new Date()).getTime();
};

//use time devuelve la hora del server en tiempo real con actualizacion cada (refreshCycle) milisegundos
export const useTime = (refreshCycle = 100) => {
  // Returns the current time
  // and queues re-renders every `refreshCycle` milliseconds (default: 100ms)

  const [now, setNow] = useState(getCurrentTime());

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(
      () => setNow(getCurrentTime()),
      refreshCycle,
    );

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, [refreshCycle, setInterval, clearInterval, setNow, getCurrentTime]);

  return now;
};