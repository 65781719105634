import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import Historial from "../../../pages/Paciente/VerConsultasAnteriores/Historial/Historial";
import SeleccionarPersona from "../../../pages/Paciente/VerConsultasAnteriores/SeleccionarPersona/SeleccionarPersona";

const VerConsultasAnteriores = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/persona`} exact />
      <PrivateRoute component={SeleccionarPersona} path={`${path}/persona`} />
      <PrivateRoute component={Historial} path={`${path}/historial`} />
    </Switch>
  );
};

export default VerConsultasAnteriores;
