import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import AgendaDeTurnos from "../../../pages/Paciente/VerTurnosAgendados/AgendaDeTurnos/AgendaDeTurnos";
import SeleccionarPersona from "../../../pages/Paciente/VerTurnosAgendados/SeleccionarPersona/SeleccionarPersona";

const VerTurnosAgendados = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/persona`} exact />
      <PrivateRoute component={SeleccionarPersona} path={`${path}/persona`} />
      <PrivateRoute component={AgendaDeTurnos} path={`${path}/agenda`} />
    </Switch>
  );
};

export default VerTurnosAgendados;
