import * as React from "react";

const Icon_userjsx = ({nombre, apellido, props}) => (
  <svg width={26} height={26} xmlns="http://www.w3.org/2000/svg" {...props}>
    {nombre === undefined ? (
      <title>{"Bienvenido"}</title>
    ) : nombre ? (
      <title>
        {nombre} {apellido}
      </title>
    ) : null}
    <g fill="none" fillRule="evenodd">
      <path d="M.75.75h24.5v24.5H.75z" />
      <path d="M.75.75h24.5v24.5H.75z" />
      <path
        d="M3.78 19.504c.156-3.05 4.224-5.493 9.22-5.493 5.094 0 9.224 2.54 9.224 5.673v2.91c0 .259-.092.635-.36.635H4.366c-.262 0-.59-.38-.59-.636v-2.91ZM13 3.846a4.779 4.779 0 1 1 0 9.558 4.779 4.779 0 0 1 0-9.558Z"
        fill="#EB5F0A"
      />
    </g>
  </svg>
);

export default Icon_userjsx;
