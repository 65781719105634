import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {useTheme} from "@material-ui/core";

interface PublicProps {
  onClickHandler: () => void;
}

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    width: 250,
  },
});

const BtnConfirmar: React.FC<PublicProps> = (props) => {
  const {onClickHandler} = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      endIcon={<ArrowForwardIosIcon fontSize="small" />}
      onClick={onClickHandler}
    >
      Confirmar
    </Button>
  );
};

export default BtnConfirmar as React.ComponentType<PublicProps>;
