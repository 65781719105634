import React, {useState, useEffect} from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  WithStyles,
  withStyles,
  MenuItem,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {styles} from "./SeleccionarEspecialidadStyles";
import {
  setEspecialidad,
  setHorariosDisponibles,
  setMedicoFiltrado,
} from "../../../../store/paciente/consultas-programadas/consultasProgramadasSlice";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import Siguiente from "../../../../components/buttons/Siguiente/Siguiente";
import PerfilPaciente from "../../../../components/PerfilPaciente/PerfilPaciente";
import {IEspecialidad} from "../../../../Interfaces/IEspecialidad";
import {getEspecialidades} from "../../../../apis/especialidadAPI";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
// import PersonIcon from '@material-ui/icons/Person';
// import StarIcon from '@material-ui/icons/Star';

const SeleccionarEspecialidad: React.FC<WithStyles<typeof styles>> = (
  props
) => {
  const {classes} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [listaEspecialidadesApi, setListaEspecialidadesApi] = useState<
    Array<IEspecialidad>
  >([]);
  const [especialidadesPrincipales, setListaEspecialidadesPrincipales] =
    useState<Array<IEspecialidad>>([]);

  useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = async () => {
    setLoading(true);
    const paciente = pacientesByCuil[cuilPacienteSeleccionado];
    const data = await getEspecialidades(
      paciente.CODIGO_SEXO,
      paciente.EDAD,
      "programada",
      plan?.nombre
    );
    setLoading(false);
    let especialidades = data.filter((especialidad) => {
      let prestadorCorrecto = false;
      especialidad.tmPrestador!.forEach((prestador) => {
        if (
          prestador.descripcion.toLowerCase() === "medife" &&
          prestador.activo === "S"
        ) {
          prestadorCorrecto = true;
        }
      });
      return prestadorCorrecto;
    });

    setListaEspecialidadesApi(especialidades);

    //solo la primera es principal
    const especialidadesPrincipales = data.slice(0, 1);
    setListaEspecialidadesPrincipales(especialidadesPrincipales);
  };
  const {cuilPacienteSeleccionado, pacientesByCuil, plan} = useSelector(
    (state: RootState) => state.user
  );
  const [especialidad, guardarEspecialidad] = useState("");
  const [loading, setLoading] = useState(false);

  const onEspecialidadClicked = (nombreEspecialidad: string) => {
    dispatch(setEspecialidad(nombreEspecialidad));
    dispatch(setMedicoFiltrado(undefined));
    dispatch(setHorariosDisponibles([]));
    history.push(`/dashboard/consultas-programadas/seleccionar-turno`);
  };

  const onEspecialidadChanged = (event: any) => {
    guardarEspecialidad(event.target.value);
  };

  const onSiguienteClicked = () => {
    if (especialidad === "") {
      return;
    }
    dispatch(setEspecialidad(especialidad));
    dispatch(setMedicoFiltrado(undefined));
    dispatch(setHorariosDisponibles([]));
    history.push(`/dashboard/consultas-programadas/seleccionar-turno`);
  };

  return (
    <>
      <Divider />
      <Box my={3}>
        <PerfilPaciente paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
      </Box>
      <Divider />
      <h2>Seleccione la especialidad</h2>

      {loading ? (
        <LoadingBubbles />
      ) : (
        <>
          {/* <List>
                            {
                                especialidadesPrincipales.map((especialidad, index) => (
                                    <ListItem button onClick={() => { onEspecialidadClicked(especialidad.Descripcion) }} key={index}>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={especialidad.Descripcion} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => { onEspecialidadClicked(especialidad.Descripcion) }} >
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <p>O podés elegir una especialidad de la lista</p> */}
          <FormControl fullWidth style={{marginBottom: 50}}>
            <InputLabel htmlFor="especialidad">
              Selecioná una especialidad
            </InputLabel>
            <Select
              value={especialidad}
              onChange={onEspecialidadChanged}
              label="Selecioná una especialidad"
              inputProps={{
                name: "especialidad",
              }}
            >
              {listaEspecialidadesApi.map((especialidad, index) => {
                return (
                  <MenuItem key={index} value={especialidad.descripcion}>
                    {especialidad.descripcion}
                  </MenuItem>
                );
              })}
              {/*<option value={"Pediatría"}>Pediatría</option>
                    <option value={"Odontología"}>Odontología</option>*/}
            </Select>
          </FormControl>

          {/* ACA DEBERIA IR UN MAPS DE LSO MEDICOS FAVORITOS DEL PACIENTE! */}
          {/* <Grid container spacing={2}>
                            
                            <Grid item md={4}>
                                <Card className={classes.card}>
                                    <Grid container spacing={2}>
                                        <Grid item className={classes.gridIcon}>
                                            <PersonIcon className={classes.icono}></PersonIcon> 
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="subtitle1" component="div">
                                                        Medico pediatra
                                                    </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    CLINICA ADULTOS (especialidad)
                                                </Typography>
                                                <Typography variant="body2" color="secondary">
                                                    Fecha ultima creacion xx/xx/xxxx
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <IconButton className={classes.estrella} aria-label="settings">
                                                    <StarIcon />
                                                </IconButton> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item md={4}>
                                <Card className={classes.card}>
                                    <Grid container spacing={2}>
                                        <Grid item className={classes.gridIcon}>
                                            <PersonIcon className={classes.icono}></PersonIcon> 
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="subtitle1" component="div">
                                                        Medico pediatra
                                                    </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    CLINICA ADULTOS (especialidad)
                                                </Typography>
                                                <Typography variant="body2" color="secondary">
                                                    Fecha ultima creacion xx/xx/xxxx
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <IconButton className={classes.estrella} aria-label="settings">
                                                    <StarIcon />
                                                </IconButton> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item md={4}>
                                <Card className={classes.card}>
                                    <Grid container spacing={2}>
                                        <Grid item className={classes.gridIcon}>
                                            <PersonIcon className={classes.icono}></PersonIcon> 
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="subtitle1" component="div">
                                                        Medico pediatra
                                                    </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    CLINICA ADULTOS (especialidad)
                                                </Typography>
                                                <Typography variant="body2" color="secondary">
                                                    Fecha ultima creacion xx/xx/xxxx
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <IconButton className={classes.estrella} aria-label="settings">
                                                    <StarIcon />
                                                </IconButton> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid> */}

          <div className={classes.buttonsRoot}>
            <Volver onClickHandler={() => history.goBack()} />
            <Siguiente
              disabled={especialidad === ""}
              onClickHandler={onSiguienteClicked}
            />
          </div>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(SeleccionarEspecialidad);
