import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    buttonsRoot: {
      marginTop: 20,
      display: "flex",
      justifyContent: "center",
      "& button": {
        textTransform: "none",
        width: 250,
        margin: 10,
      },
      "& button:nth-child(2)": {
        color: "white",
        backgroundColor: "grey",
      },
    },
  });
