import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  DialogContentText,
  IconButton,
  useTheme,
} from "@material-ui/core";
import {FavoriteOutlined} from "@material-ui/icons";
import React from "react";
import {IMedicoFav} from "../../Interfaces/IMedicoFav";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import usuario from "../../assets/images/usuario.png";

interface OwnProps {
  medico: IMedicoFav;
  onSolicitarTurno?: (
    medico: string,
    especialidad: string,
    Nombre: string,
    Apellido: string
  ) => void;
  onQuitarFavorito?: (medico: string, especialidad: string) => void;
}

function CardMedicoFavorito(props: OwnProps) {
  const {medico, onSolicitarTurno, onQuitarFavorito} = props;
  const theme = useTheme();

  const [quitarFavOpen, setQuitarFavOpen] = React.useState(false);

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <img
              src={medico.urlFoto ? medico.urlFoto : usuario}
              alt="imagen medico"
              height="100%"
            />
          </Avatar>
        }
        title={`${medico.Apellido}, ${medico.Nombre}`}
        subheader={
          <>
            <div>{medico.Matricula}</div>
            <div style={{color: theme.paletaColores.naranja.tercero}}>
              {medico.especialidad}
            </div>
          </>
        }
        action={
          onQuitarFavorito ? (
            <IconButton
              onClick={() => {
                setQuitarFavOpen(true);
              }}
            >
              <FavoriteOutlined />
            </IconButton>
          ) : null
        }
      />
      {onSolicitarTurno && (
        <CardActions>
          <Button
            variant="contained"
            disableElevation
            size="small"
            onClick={() => {
              onSolicitarTurno(
                medico.email,
                medico.especialidad,
                medico.Nombre,
                medico.Apellido
              );
            }}
          >
            Solicitar Turno
          </Button>
        </CardActions>
      )}
      <ConfirmationDialog
        open={quitarFavOpen}
        setOpen={setQuitarFavOpen}
        title="Quitar Favorito"
        onConfirm={() => {
          onQuitarFavorito!(medico.email, medico.especialidad);
        }}
      >
        <DialogContentText>
          {`Está seguro de que desea quitar al medico ${medico.Nombre} ${medico.Apellido} de sus favoritos?`}
        </DialogContentText>
      </ConfirmationDialog>
    </Card>
  );
}

export default CardMedicoFavorito;
