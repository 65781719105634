import DateFnsUtils from "@date-io/date-fns";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Divider,
  Tabs,
  Tooltip,
  Chip,
  Box,
  Tab,
  Avatar,
  useTheme,
  Button,
  Dialog,
  Toolbar,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import {CloseOutlined, Done} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import {obtenerListaProfesionalesFinochietto} from "../../../../../apis/finochiettoAPI";
import InteractiveCalendar from "../../../../../components/InteractiveCalendar/InteractiveCalendar";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {IProfesional} from "../../../../../Interfaces/IProfesional";
import {IProfesionalTurnos} from "../../../../../Interfaces/IProfesionalHorario";
import {
  convertDatestringToDate,
  convertDateToDatestring,
  convertDateToTimestring,
  convertDiaHoraToDate,
  convertTimestringToDate,
} from "../../../../../utils/dateTimeHelper";
import DialogSobreturnos from "./DialogSobreturnos";

interface OwnProps {
  onHabilitarSiguiente: (habilitado: boolean) => void;
  siguienteHabilitado: boolean;
  onSiguienteClicked: () => void;

  setFecha: (fechaNueva: Date) => void;
  fecha?: Date;

  setProfesional: (profNuevo: string) => void;
  profesional: string | undefined;

  setSelectedProfTurno: (
    profTurnoNuevo: IProfesionalTurnos | undefined
  ) => void;
  selectedProfTurno: IProfesionalTurnos | undefined;
  setSelectedTurno: (turnoNuevo: string) => void;
  selectedTurno: string;
  posiblesProfesionalTurnos: Array<IProfesionalTurnos>;

  fechasLibres: Array<string>;
  turnosHorarios: Array<{nombre: string; desde: number; hasta: number}>;

  tabsSeleccionadas: Array<number>;
  setTabsSeleccionadas: (nuevo: Array<number>) => void;

  loadingFechas: boolean;
  loadingTurnos: boolean;

  profesionalSobreturno: string | null;
  setProfesionalSobreturno: (profNuevo: string | null) => void;

  horarioSobreturno: Date | null;
  setHorarioSobreturno: (horarioNuevo: Date | null) => void;

  loadingMedicos: boolean;
  listadoMedicos: Array<IProfesional>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{width: "100%", flex: 1}}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function SeleccionProfesional(props: OwnProps) {
  const {
    siguienteHabilitado,
    onHabilitarSiguiente,
    fecha,
    setFecha,
    profesional,
    setProfesional,
    posiblesProfesionalTurnos,
    selectedProfTurno,
    setSelectedProfTurno,
    selectedTurno,
    setSelectedTurno,
    fechasLibres,
    turnosHorarios,
    tabsSeleccionadas,
    setTabsSeleccionadas,
    loadingFechas,
    loadingTurnos,
    profesionalSobreturno,
    setProfesionalSobreturno,
    horarioSobreturno,
    setHorarioSobreturno,
    loadingMedicos,
    listadoMedicos,
    onSiguienteClicked,
  } = props;

  const theme = useTheme();

  let hayTurnos = false;
  posiblesProfesionalTurnos.forEach((profturno) => {
    if (
      (profesional === undefined || profesional === profturno.profesional) &&
      profturno.turnos.length > 0
    ) {
      hayTurnos = true;
    }
  });

  const handleChangeTab = (newTab: number, tabIndex: number) => {
    let temp = [...tabsSeleccionadas];
    temp[tabIndex] = newTab;
    setTabsSeleccionadas(temp);
  };

  const [dialogSobreturnosOpen, setDialogSobreturnosOpen] =
    React.useState(false);

  return (
    <Grid container spacing={2}>
      {loadingFechas ? (
        <Grid item xs={12}>
          <div style={{margin: "50px 0px 50px 0px"}}>
            <LoadingBubbles />
          </div>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={4}>
            <div style={{margin: "0px 0px 20px 0px"}}>
              <InteractiveCalendar
                minDate={
                  fechasLibres.length > 0
                    ? convertDatestringToDate(fechasLibres[0])
                    : new Date()
                }
                maxDate={
                  fechasLibres.length > 0
                    ? convertDatestringToDate(
                        fechasLibres[fechasLibres.length - 1]
                      )
                    : undefined
                }
                tileDisabled={(props: {date: Date; view: string}) => {
                  const dateString = convertDateToDatestring(props.date);
                  if (fechasLibres.find((x) => x === dateString)) {
                    return false;
                  }
                  //if (props.date.getDate() === 10) return true;
                  //if (props.date.getDay() === 0) return true;
                  return true;
                }}
                value={fecha}
                onChange={(callback) => {
                  setFecha(callback as Date);
                }}
              />
            </div>
            <Button
              onClick={() => {
                setSelectedProfTurno(undefined);
                setSelectedTurno("");
                onHabilitarSiguiente(false);
                setDialogSobreturnosOpen(true);
              }}
              variant="contained"
              fullWidth
              disableElevation
              color="primary"
            >
              Agregar Sobreturno
            </Button>
            <DialogSobreturnos
              open={dialogSobreturnosOpen}
              onClose={() => {
                setDialogSobreturnosOpen(false);
                setHorarioSobreturno(null);
                setProfesionalSobreturno(null);
                onHabilitarSiguiente(false);
              }}
              listadoMedicos={listadoMedicos}
              loading={loadingMedicos}
              profesionalSobreturno={profesionalSobreturno}
              setProfesionalSobreturno={setProfesionalSobreturno}
              horarioSobreturno={horarioSobreturno}
              setHorarioSobreturno={setHorarioSobreturno}
              onHabilitarSiguiente={onHabilitarSiguiente}
              siguienteHabilitado={siguienteHabilitado}
              onSiguienteClicked={onSiguienteClicked}
              fechaSeleccionada={fecha}
            />
          </Grid>
          <Grid item xs={12} md={8} style={{padding: 15}}>
            {loadingTurnos ? (
              <div style={{marginTop: 100}}>
                <LoadingBubbles />
              </div>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={7}>
                    <Typography variant="h6">Horarios Disponibles</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth size="small" margin="none">
                      <InputLabel>Filtrar por profesional</InputLabel>
                      <Select
                        value={profesional}
                        onChange={(event) => {
                          setProfesional(event.target.value as string);
                        }}
                        inputProps={{
                          name: "profesional",
                        }}
                      >
                        <MenuItem aria-label="None" value={undefined}>
                          (Cualquier Profesional)
                        </MenuItem>
                        {posiblesProfesionalTurnos.map(
                          (profesionalTurno, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={profesionalTurno.profesional}
                              >
                                {profesionalTurno.nombre}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider style={{marginTop: 15, marginBottom: 15}} />
                {hayTurnos ? (
                  <>
                    {posiblesProfesionalTurnos.map(
                      (profesionalTurno, indexProfesionalTurno) => {
                        const isProfesionalTurnosSelected =
                          selectedProfTurno?.profesional ===
                          profesionalTurno.profesional;
                        const show =
                          (profesional === undefined ||
                            profesional === profesionalTurno.profesional) &&
                          profesionalTurno.turnos.length > 0;
                        if (!show) {
                          return null;
                        }
                        return (
                          <div key={indexProfesionalTurno}>
                            <Typography
                              variant="h6"
                              style={{
                                color: theme.paletaColores.naranja.tercero,
                              }}
                            >
                              {profesionalTurno.nombre}
                            </Typography>
                            <Typography paragraph>
                              Matricula: {profesionalTurno.matricula}
                            </Typography>
                            <Tabs
                              style={{flexGrow: 1, width: "100%"}}
                              value={tabsSeleccionadas[indexProfesionalTurno]}
                              onChange={(event, newTab) => {
                                handleChangeTab(newTab, indexProfesionalTurno);
                              }}
                            >
                              {turnosHorarios.map(
                                (turnoHorario, iTurnoHorario) => {
                                  let disabled = false;
                                  if (
                                    profesionalTurno.turnos.filter((x) => {
                                      const horaDelTurno = Number.parseInt(
                                        x.split(":")[0]
                                      );
                                      return (
                                        horaDelTurno >= turnoHorario.desde &&
                                        horaDelTurno < turnoHorario.hasta
                                      );
                                    }).length === 0
                                  ) {
                                    disabled = true;
                                    return null;
                                  }
                                  return (
                                    <Tab
                                      disabled={disabled}
                                      key={iTurnoHorario}
                                      value={iTurnoHorario}
                                      style={{fontSize: 11, minWidth: "30px"}}
                                      {...a11yProps(0)}
                                      label={turnoHorario.nombre}
                                    />
                                  );
                                }
                              )}
                            </Tabs>
                            <SwipeableViews
                              index={tabsSeleccionadas[indexProfesionalTurno]}
                              onChangeIndex={(tab: number) => {
                                handleChangeTab(tab, indexProfesionalTurno);
                              }}
                              style={{width: "100%"}}
                            >
                              {turnosHorarios.map(
                                (turnoHorario, iTurnoHorario) => {
                                  return (
                                    <TabPanel
                                      key={iTurnoHorario}
                                      value={
                                        tabsSeleccionadas[indexProfesionalTurno]
                                      }
                                      index={iTurnoHorario}
                                      dir={theme.direction}
                                    >
                                      {profesionalTurno.turnos
                                        .filter((x) => {
                                          const horaDelTurno = Number.parseInt(
                                            x.split(":")[0]
                                          );
                                          return (
                                            horaDelTurno >=
                                              turnoHorario.desde &&
                                            horaDelTurno < turnoHorario.hasta
                                          );
                                        })
                                        .map((turno, indexTurno) => {
                                          const isSobreturno =
                                            turno.endsWith("s");
                                          const horaDelTurno = turno.replace(
                                            "s",
                                            ""
                                          );
                                          const isTurnoSelected =
                                            (selectedTurno === turno ||
                                              (isSobreturno &&
                                                selectedTurno ===
                                                  horaDelTurno)) &&
                                            isProfesionalTurnosSelected;
                                          let texto = "";
                                          if (isSobreturno) {
                                            texto += "SOBRETURNO ";
                                          }
                                          if (horaDelTurno.length < 5) {
                                            texto += "0";
                                          }
                                          texto += horaDelTurno;
                                          return (
                                            <>
                                              <Tooltip
                                                arrow
                                                title={
                                                  <Typography>{`Profesional: ${profesionalTurno.nombre}`}</Typography>
                                                }
                                                key={indexTurno}
                                              >
                                                <Chip
                                                  onClick={() => {
                                                    setSelectedProfTurno(
                                                      profesionalTurno
                                                    );
                                                    setSelectedTurno(
                                                      horaDelTurno
                                                    );
                                                    onHabilitarSiguiente(true);
                                                  }}
                                                  style={{
                                                    margin: 3,
                                                    backgroundColor:
                                                      isTurnoSelected
                                                        ? theme.paletaColores
                                                            .naranja.tercero
                                                        : "white",
                                                    color: isTurnoSelected
                                                      ? "white"
                                                      : "black",
                                                  }}
                                                  label={texto}
                                                  variant={
                                                    isTurnoSelected
                                                      ? "default"
                                                      : "outlined"
                                                  }
                                                  avatar={
                                                    isTurnoSelected ? (
                                                      <Avatar
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                        }}
                                                      >
                                                        <Done fontSize="small" />
                                                      </Avatar>
                                                    ) : (
                                                      <Avatar> </Avatar>
                                                    )
                                                  }
                                                />
                                              </Tooltip>
                                            </>
                                          );
                                        })}
                                    </TabPanel>
                                  );
                                }
                              )}
                            </SwipeableViews>
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <div style={{marginTop: 50}}>
                    <Typography>
                      No hay turnos para el dia seleccionado.
                    </Typography>
                  </div>
                )}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default SeleccionProfesional;
