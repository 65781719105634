//
// usStillLoggedIn hook
//
import { useEffect, useState } from 'react';

export const getLoggedIn = () => {
  let loggedIn = localStorage.getItem("LoggedIn");
  if (loggedIn?.toLowerCase() === "false") {
    return false;
  }//desloguea si especificamente está en falso
  return true;
};

export const useLoggedIn = (refreshCycle = 100) => {
  const [loggedIn, setLoggedIn] = useState(getLoggedIn());

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(
      () => setLoggedIn(getLoggedIn()),
      refreshCycle,
    );

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, [refreshCycle, setInterval, clearInterval, setLoggedIn, getLoggedIn]);

  return loggedIn;
};