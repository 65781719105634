import React, {useEffect, useState} from "react";
import {
  Divider,
  WithStyles,
  withStyles,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Button,
  FormControl,
  Select,
  MenuItem,
  CardActions,
  InputLabel,
  Grid,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  DialogContentText,
  Paper,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {styles} from "./ConfiguracionHorariosStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {ScheduleOutlined} from "@material-ui/icons";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import {getHorarioAtencion} from "../../../../apis/citaInstantaneaAPI";
import {getFeriados} from "../../../../apis/calendarioAPI";
import {obtenerEspecialidadesEspontaneas} from "../../../../apis/especialidadAPI";
import {IEspecialidad} from "../../../../Interfaces/IEspecialidad";
import {IGuardia} from "../../../../Interfaces/IGuardia";
import {
  convertDateToDatestring,
  convertDatestringToDate,
  convertDateToTimestring,
} from "../../../../utils/dateTimeHelper";
import {ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import InteractiveCalendar from "../../../../components/InteractiveCalendar/InteractiveCalendar";
import {setHorarioAtencion} from "../../../../apis/citaInstantaneaAPI";
import CardHorarioEspecialidad from "../../../../components/CardHorarioEspecialidad/CardHorarioEspecialidad";

const ConfiguracionHorarios: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();

  const [loadingEsps, setLoadingEsps] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [inicio, setInicio] = useState<Date>(new Date());
  const [fin, setFin] = useState<Date>(new Date());

  const [dialogAlertaOpen, setDialogAlertaOpen] = useState(false);
  const [alertaText, setAlertaText] = useState("");
  const [feriados, setFeriados] = useState([]);
  const [listaEspecialidadesGuardiaApi, setListaEspecialidadesGuardiaApi] =
    useState<Array<IEspecialidad>>([]);
  const [especialidadGuardia, setEspecialidadGuardia] = useState<string>("");
  const [diasHabilitados, setDiasHabilitados] = React.useState<Array<number>>(
    []
  );
  const [configuracionCompleta, setConfiguracionCompleta] =
    React.useState(false);
  //const [fechasExcepciones, setFechasExcepciones] = React.useState<Array<string>>([]);

  const diasPosibles = [0, 1, 2, 3, 4, 5, 6];

  const diaTexts = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const mesTexts = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const hoy = new Date();
  const [excepciones, setExcepciones] = useState<Array<string>>([]);

  useEffect(() => {
    if (especialidadGuardia) {
      getFeriados(new Date().getFullYear().toString())
        .then((datosObtenidos) => {
          setFeriados(datosObtenidos);
        })
        .catch((e) => {
          console.log(e);
        });

      getHorarioAtencion(especialidadGuardia)
        .then((res) => {
          let inicio_dia = new Date();
          inicio_dia.setHours(
            parseInt(res.inicio.split(":")[0]),
            parseInt(res.inicio.split(":")[1])
          );
          let fin_dia = new Date();
          fin_dia.setHours(
            parseInt(res.fin.split(":")[0]),
            parseInt(res.fin.split(":")[1])
          );
          setInicio(inicio_dia);
          setFin(fin_dia);

          setExcepciones(res.excepciones);
          setDiasHabilitados(res.dias);
          setLoadingConfig(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [especialidadGuardia]);
  useEffect(() => {
    setLoadingEsps(true);
    obtenerEspecialidadesEspontaneas()
      .then((datosObtenidos) => {
        setListaEspecialidadesGuardiaApi(datosObtenidos);
        setLoadingEsps(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // useEffect(() => {
  //     setLoading(true);
  //     getHorarioAtencion()
  //         .then(
  //             (datos) => {
  //                 const inicioRecibido = convertTimestringToDate(datos.horaInicio);
  //                 const finRecibido = convertTimestringToDate(datos.horaFin);

  //                 setInicio(inicioRecibido);
  //                 setFin(finRecibido);
  //                 setLoading(false);
  //             }
  //         )
  //         .catch(e => { console.log(e) })

  // }, [])

  const onConfirmarMod = async () => {
    let inicioP = convertDateToTimestring(inicio);
    let finP = convertDateToTimestring(fin);

    let guardiaNueva: IGuardia = {
      inicio: inicioP,
      fin: finP,
      nombre: especialidadGuardia,
      dias: diasHabilitados,
      excepciones: excepciones,
    };
    await setHorarioAtencion(guardiaNueva)
      .then((res) => {
        setPuedeEditar(false);
        setEspecialidadGuardia("");
        setInicio(new Date());
        setFin(new Date());
        setConfiguracionCompleta(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [puedeEditar, setPuedeEditar] = useState(false);

  const onEspecialidadChanged = (event: any) => {
    if (event.target.value) {
      setPuedeEditar(true);
    } else {
      setPuedeEditar(false);
    }
    setEspecialidadGuardia(event.target.value);
    setLoadingConfig(true);
  };
  const handleDiasHabilitados = (
    event: React.MouseEvent<HTMLElement>,
    newDiasHabilitados: number[]
  ) => {
    newDiasHabilitados = newDiasHabilitados.sort((a, b) => {
      return a - b;
    });
    setDiasHabilitados(newDiasHabilitados);
  };

  const handleClose = () => {
    setDialogAlertaOpen(false);
  };

  const onClickDay = (day: Date) => {
    let fechaStr = convertDateToDatestring(day);
    if (excepciones.includes(fechaStr)) return;
    setExcepciones((fecha) => [...excepciones, fechaStr]);
  };

  const eliminarExcepcion = (excep: string) => {
    setExcepciones(excepciones.filter((x) => x !== excep));
  };

  // if (configuracionCompleta) {
  //     return (
  //         <Dialog open={true}>
  //             <DialogContent>
  //                 <Typography>
  //                     Configuración modificada con éxito.
  //                 </Typography>
  //             </DialogContent>
  //             <DialogActions>
  //                 <Button >
  //                     Aceptar
  //                 </Button>
  //             </DialogActions>
  //         </Dialog>
  //     )
  // }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Card style={{flexGrow: 1, marginBottom: 20}}>
          <CardHeader
            avatar={
              <Avatar>
                <ScheduleOutlined />
              </Avatar>
            }
            title={
              <Typography variant="h6">
                Administración de horarios de atención de la guardia virtual
              </Typography>
            }
          />
          {loadingEsps ? (
            <div>
              <LoadingBubbles />
            </div>
          ) : (
            <FormControl
              fullWidth
              style={{width: 200, margin: 10, marginLeft: 20}}
            >
              <InputLabel htmlFor="especialidad">
                Selecioná una especialidad
              </InputLabel>
              <Select
                value={especialidadGuardia}
                onChange={onEspecialidadChanged}
                label="Selecioná una especialidad"
                inputProps={{
                  name: "especialidad",
                }}
              >
                {listaEspecialidadesGuardiaApi?.map((especialidad, index) => {
                  return (
                    <MenuItem key={index} value={especialidad.descripcion}>
                      {especialidad.descripcion}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <Divider />
          {especialidadGuardia && (
            <CardContent
              style={{backgroundColor: theme.paletaColores.fondo.segundo}}
            >
              <Card style={{flexGrow: 1, marginBottom: 20}}>
                <CardContent>
                  {loadingConfig ? (
                    <div style={{margin: "10vh 0px 10vh 0px"}}>
                      <LoadingBubbles />
                    </div>
                  ) : (
                    <>
                      <div>
                        <List>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="h6">
                                Días habilitados para consulta
                              </Typography>
                            </ListItemText>
                            <ListItemText>
                              <ToggleButtonGroup
                                size="small"
                                value={diasHabilitados}
                                onChange={handleDiasHabilitados}
                              >
                                {diasPosibles.map((dia, indexDia) => {
                                  const diaText = diaTexts[dia];
                                  return (
                                    <ToggleButton key={indexDia} value={dia}>
                                      <Avatar
                                        style={{
                                          backgroundColor: "white",
                                          color:
                                            theme.paletaColores.naranja.tercero,
                                          height: 25,
                                          width: 25,
                                        }}
                                      >
                                        {diaText[0]}
                                      </Avatar>
                                    </ToggleButton>
                                  );
                                })}
                              </ToggleButtonGroup>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </div>
                      <div>
                        <List>
                          <ListItem>
                            <ListItemText>
                              <Typography variant="h6">
                                Horarios guardia médica
                              </Typography>
                            </ListItemText>
                            <ListItemText>
                              <TimePicker
                                orientation="landscape"
                                variant="inline"
                                label="Desde"
                                value={inicio}
                                onChange={(newDate) => {
                                  setInicio(newDate as Date);
                                }}
                                style={{
                                  margin: "0px 5px 0px 5px",
                                  maxWidth: "100px",
                                }}
                              />

                              <TimePicker
                                orientation="landscape"
                                variant="inline"
                                label="Hasta"
                                value={fin}
                                onChange={(newDate) => {
                                  setFin(newDate as Date);
                                }}
                                style={{
                                  margin: "0px 5px 0px 5px",
                                  maxWidth: "100px",
                                }}
                              />
                              {inicio.getTime() >= fin.getTime() && (
                                <Typography
                                  style={{color: theme.paletaColores.error}}
                                >
                                  La hora de inicio debe ser anterior a la hora
                                  de fin.
                                </Typography>
                              )}
                            </ListItemText>
                          </ListItem>
                        </List>
                      </div>
                      <div>
                        <Paper variant="outlined" style={{padding: 10}}>
                          <Grid
                            container
                            style={{marginTop: 20, marginLeft: 20}}
                          >
                            <Grid
                              container
                              item
                              xs={6}
                              spacing={1}
                              style={{marginRight: "-40px"}}
                            >
                              <div style={{margin: 10}}>
                                <Typography variant="h6">
                                  Próximas excepciones activas
                                </Typography>
                              </div>
                              <InteractiveCalendar
                                minDate={hoy}
                                // maxDate={ultimoDia}
                                value={hoy}
                                dateHighlighted={(fecha: Date) => {
                                  const dateString =
                                    convertDateToDatestring(fecha);
                                  if (feriados.find((x) => x === dateString)) {
                                    return true;
                                  }
                                  return false;
                                }}
                                onClickDay={(day) => {
                                  onClickDay(day);
                                }}
                              />
                            </Grid>
                            <Grid container item xs={6} spacing={1}>
                              {excepciones.map((excep, iExcep) => {
                                const date = convertDatestringToDate(excep);
                                return (
                                  <Grid item xs={6} md={6}>
                                    <Card>
                                      <CardActionArea
                                        onClick={() => {
                                          eliminarExcepcion(excep);
                                        }}
                                      >
                                        <CardHeader
                                          avatar={
                                            <Avatar
                                              style={{
                                                backgroundColor:
                                                  theme.paletaColores.naranja
                                                    .segundo,
                                              }}
                                            >
                                              {date.getDate()}
                                            </Avatar>
                                          }
                                          title={mesTexts[date.getMonth()]}
                                        />
                                      </CardActionArea>
                                    </Card>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          )}
        </Card>
        <div className={classes.buttonsRoot}>
          <Volver
            onClickHandler={() => {
              history.goBack();
            }}
          />
          <Button
            variant="contained"
            disableElevation
            color="primary"
            disabled={!puedeEditar}
            onClick={onConfirmarMod}
          >
            CONFIRMAR
          </Button>
        </div>
      </MuiPickersUtilsProvider>
      <Dialog open={dialogAlertaOpen}>
        <DialogContent>
          <DialogContentText>{alertaText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            disableElevation
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ConfiguracionHorarios);
