import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../store";
import {fetchPacientes} from "../../../apis/pacientesAPI";
import {IPaciente} from "../../../Interfaces/IPaciente";
import {fetchEventosCalendario} from "../../../apis/calendarioAPI";
import {IResultadoListaEventosCalendario} from "../../../Interfaces/IResultadoListaEventosCalendario";
import {ISintomaPreguntasRespuestas} from "../../../Interfaces/ISintomaPreguntasRespuestas";

interface IatenderConsultorioState {
  pacientesByCuil: Record<number, IPaciente>;
  cuilsDePacientes: ReadonlyArray<number>;
  cuilPacienteSeleccionado: number;
  especialidad: string;
  idSalaVideollamada: string;
  passSalaVideollamada: string;
  paso: number;
  error: string | null;
  sintomaGuardado: ISintomaPreguntasRespuestas | null;
}

let initialState: IatenderConsultorioState = {
  pacientesByCuil: {},
  cuilsDePacientes: [],
  cuilPacienteSeleccionado: -1,
  especialidad: "",
  idSalaVideollamada: "",
  passSalaVideollamada: "",
  paso: 0,
  error: null,
  sintomaGuardado: null,
};

const consultasProgramadasSlice = createSlice({
  name: "consultasProgramadas",
  initialState,
  reducers: {
    setPaso(state, action: PayloadAction<number>) {
      state.paso = action.payload;
    },
    getPacientesSuccess(state, action: PayloadAction<IPaciente[]>) {
      state.cuilsDePacientes = [];
      action.payload.forEach((paciente) => {
        state.pacientesByCuil[paciente.CUIL] = paciente;
        state.cuilsDePacientes.push(paciente.CUIL);
      });
      state.error = null;
    },
    clearPacientes(state, action: PayloadAction) {
      state.cuilsDePacientes = [];
      state.pacientesByCuil = {};
    },
    getPacientesFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    getEventosCalendarioSuccess(
      state,
      action: PayloadAction<IResultadoListaEventosCalendario>
    ) {
      state.cuilsDePacientes = [];

      action.payload.items.forEach((evento) => {
        /*
                state.pacientesByCuil[evento.CUIL] = evento;
                state.cuilsDePacientes.push(evento.CUIL);
                */
      });

      state.error = null;
    },
    getEventosCalendarioFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setCuilPacienteSeleccionado(state, action: PayloadAction<number>) {
      state.cuilPacienteSeleccionado = action.payload;
    },
    setEspecialidad(state, action: PayloadAction<string>) {
      state.especialidad = action.payload;
    },
    setIdSalaVideollamada(state, action: PayloadAction<string>) {
      state.idSalaVideollamada = action.payload;
    },
    setPassSalaVideollamada(state, action: PayloadAction<string>) {
      state.passSalaVideollamada = action.payload;
    },
    setSintomaGuardado(
      state,
      action: PayloadAction<ISintomaPreguntasRespuestas>
    ) {
      state.sintomaGuardado = action.payload;
    },
  },
});

export const {
  setPaso,
  getPacientesSuccess,
  getPacientesFailed,
  setCuilPacienteSeleccionado,
  setEspecialidad,
  setIdSalaVideollamada,
  setPassSalaVideollamada,
  getEventosCalendarioSuccess,
  getEventosCalendarioFailed,
  setSintomaGuardado,
  clearPacientes,
} = consultasProgramadasSlice.actions;

export default consultasProgramadasSlice.reducer;

export const getPacientes =
  (ID_GRUPO_FAMILIAR: number): AppThunk =>
  async (dispatch) => {
    try {
      const pacientes = await fetchPacientes(ID_GRUPO_FAMILIAR);
      dispatch(getPacientesSuccess(pacientes));
    } catch (err) {
      dispatch(getPacientesFailed(err.toString()));
    }
  };

export function getEventosCalendario(mailProfesional: string): AppThunk {
  return async (dispatch) => {
    try {
      const eventosCalendario = await fetchEventosCalendario(mailProfesional);
      return eventosCalendario;
    } catch (err) {
      return null;
    }
  };
}

export const resetPacientes = (): AppThunk => (dispatch) => {
  dispatch(clearPacientes());
};
