import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 375,
      width: 375,
    },
    exitIcon: {
      padding: 0,
    },
    list: {
      "& > hr": {
        height: 2,
        border: "1px solid #979797",
      },
    },
    listItem: {
      width: "100%",
      height: 40,
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.paletaColores.negro.segundo,
      "& .MuiListItemIcon-root": {
        minWidth: 0,
      },
    },
    listItemSmall: {
      "& .MuiTypography-body1": {
        fontSize: "0.875rem",
      },
    },
    redesSociales: {
      "& > svg": {
        marginRight: 16,
      },
    },
  });
