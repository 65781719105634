import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import SignIn from "./pages/SignIn/SignIn";
import Dashboard from "./pages/Dashboard/Dashboard";
import JitsiMobile from "./pages/Externas/JitsiMobile/JitsiMobile";
import { ThemeProvider } from "@material-ui/core";
import createTheme from "./theme/AppTheme";
import IngresoAsociado from "./pages/Externas/IngresoAsociado/IngresoAsociado";
import DocumentosMail from "./pages/Externas/DocumentosMail/DocumentosMail";
import CancelarCitaMail from "./pages/Externas/CancelarCitaMail/CancelarCitaMail";
import PacientesFinochietto from "./pages/Externas/PacientesFinochietto/PacientesFinochietto";

const theme = createTheme();

function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <PublicRoute component={SignIn} path="/" exact />
          <PublicRoute component={SignIn} path="/signin" />
          <PrivateRoute component={Dashboard} path="/dashboard" />
          {/*<PublicRoute component={JitsiMobile} path="/jitsi-mobile" />*/}
          <Route path="/jitsi-mobile" component={JitsiMobile} />
          <Route path="/pacientes-finochietto" component={PacientesFinochietto} />
          <PublicRoute component={IngresoAsociado} path="/ingreso-asociado" />
          <Route component={DocumentosMail} path="/documentos-mail" />
          <Route component={CancelarCitaMail} path="/cancelar-cita" />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
