import {createStyles} from "@material-ui/core";

export const styles = createStyles({
  paper: {
    padding: 16,
    maxWidth: 500,
  },
  buttonsRoot: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    "& button": {
      textTransform: "none",
      width: 250,
      margin: 10,
    },
    "& button:nth-child(2)": {
      color: "white",
      backgroundColor: "grey",
    },
  },
  mic: {
    minWidth: "50%",
    maxWidth: "80%",
    flexShrink: 1,
    flexGrow: 1,
    marginRight: 10,
  },
});
