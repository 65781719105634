import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import ContainerBusquedaSolicitudesFarmacia from "../../../pages/Farmaceutico/AdministrarSolicitudesFarmacia/ContainerBusquedaSolicitudesFarmacia/ContainerBusquedaSolicitudesFarmacia";

const AdministrarSolicitudesFarmacia = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/buscar-solicitudes`} exact />
      <PrivateRoute
        component={ContainerBusquedaSolicitudesFarmacia}
        path={`${path}/buscar-solicitudes`}
      />
    </Switch>
  );
};

export default AdministrarSolicitudesFarmacia;
