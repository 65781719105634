import React, {useEffect, useState} from "react";
import {
  Divider,
  WithStyles,
  withStyles,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CardActions,
  InputLabel,
  Grid,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {styles} from "./ListadoSintomasStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {
  SettingsOutlined,
  AddCircleOutlineOutlined,
  EditOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {useDispatch} from "react-redux";
import {
  cargarNuevoSintoma,
  getPreguntasTriaje,
  obtenerEspecialidadesEspontaneas,
} from "../../../../apis/especialidadAPI";
import {IEspecialidad} from "../../../../Interfaces/IEspecialidad";
import {ISintoma} from "../../../../Interfaces/ISintoma";
import CardSintomaAdmin from "../../../../components/CardSintomaAdmin/CardSintomaAdmin";
import CardNuevaPreguntaTriaje from "../../../../components/CardNuevaPreguntaTriaje/CardNuevaPreguntaTriaje";

const ListadoPacientesEspera: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [loadingSintomas, setLoadingSintomas] = useState(false);
  const [updateDummieState, setUpdateDummieState] = useState(false);

  const [especialidades, setEspecialidades] = useState<Array<IEspecialidad>>(
    []
  );
  const [especialidadSelected, setEspecialidadSelected] = useState<
    string | undefined
  >(undefined);
  const [sintomas, setSintomas] = useState<Array<ISintoma>>([]);
  const [forceUpdateSintomasState, setForceUpdateSintomasState] =
    useState(false);

  const [dialogNuevoSintomaOpen, setDialogNuevoSintomaOpen] = useState(false);

  const dispatch = useDispatch();

  const [preguntasNuevoSintoma, setPreguntasNuevoSintoma] = useState<
    Array<{pregunta: string; cerrada: boolean}>
  >([]);
  const [tituloNuevoSintoma, setTituloNuevoSintoma] = useState<string>("");
  const [imagenNuevoSintoma, setImagenNuevoSintoma] = useState<File>();
  const [imagenNuevoSintomaURL, setImagenNuevoSintomaURL] = useState<string>();
  const [isImagenHovered, setIsImagenHovered] = useState(false);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLoading(true);
    obtenerEspecialidadesEspontaneas()
      .then((datosObtenidos) => {
        setEspecialidades(datosObtenidos);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (especialidadSelected) {
      setLoadingSintomas(true);
      getPreguntasTriaje(especialidadSelected)
        .then((datosObtenidos) => {
          setLoadingSintomas(false);
          setSintomas(datosObtenidos);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [especialidadSelected, forceUpdateSintomasState]);

  const resetNuevoSintoma = () => {
    setTituloNuevoSintoma("");
    setImagenNuevoSintoma(undefined);
    setImagenNuevoSintomaURL(undefined);
    setPreguntasNuevoSintoma([]);
  };

  const dialogNuevoSintoma = () => {
    let puedeEnviarNuevoSintoma = true;
    if (loadingSintomas || loading) {
      puedeEnviarNuevoSintoma = false;
    } else {
      if (tituloNuevoSintoma === "") {
        puedeEnviarNuevoSintoma = false;
      } else {
        if (imagenNuevoSintoma === undefined) {
          puedeEnviarNuevoSintoma = false;
        } else {
          if (preguntasNuevoSintoma.length === 0) {
            puedeEnviarNuevoSintoma = false;
          } else {
            preguntasNuevoSintoma.forEach((pregunta) => {
              if (pregunta.pregunta === "") {
                puedeEnviarNuevoSintoma = false;
              }
            });
          }
        }
      }
    }

    return (
      <Dialog
        open={dialogNuevoSintomaOpen}
        onClose={() => {
          setDialogNuevoSintomaOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <div style={{display: "flex", alignItems: "center"}}>
            <IconButton
              style={{
                borderColor: theme.paletaColores.negro.cuarto,
                borderStyle: "solid",
                borderWidth: "1px",
                margin: "0px 15px 0px 0px",
                width: "56px",
                height: "56px",
              }}
              onMouseEnter={() => setIsImagenHovered(true)}
              onMouseLeave={() => setIsImagenHovered(false)}
              onClick={() => hiddenFileInput.current?.click()}
            >
              <Avatar
                style={{
                  width: "56px",
                  height: "56px",
                }}
                src={isImagenHovered ? undefined : imagenNuevoSintomaURL}
              >
                {isImagenHovered ? (
                  <EditOutlined />
                ) : imagenNuevoSintomaURL ? undefined : (
                  <CloudUploadOutlined />
                )}
              </Avatar>
              <input
                type="file"
                name="inputArchivo"
                accept=".jpg,.png"
                onChange={(e) => {
                  const file = e!.target!.files![0]!;
                  setImagenNuevoSintoma(file);
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (event) => {
                    setImagenNuevoSintomaURL(event.target!.result! as string);
                  };
                }}
                hidden
                ref={hiddenFileInput}
              />
            </IconButton>
            <TextField
              fullWidth
              label="Titulo del nuevo sintoma"
              value={tituloNuevoSintoma}
              onChange={(event) => {
                setTituloNuevoSintoma(event.target.value as string);
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            {preguntasNuevoSintoma.map((pregunta, iPregunta) => {
              const handleSetTextoPregunta = (texto: string) => {
                let tmp = [...preguntasNuevoSintoma];
                tmp[iPregunta].pregunta = texto;
                setPreguntasNuevoSintoma(tmp);
              };
              const handleSetPreguntaCerrada = (cerrada: boolean) => {
                let tmp = [...preguntasNuevoSintoma];
                tmp[iPregunta].cerrada = cerrada;
                setPreguntasNuevoSintoma(tmp);
              };
              const handleBorrarPregunta = () => {
                let tmp = [...preguntasNuevoSintoma];
                tmp.splice(iPregunta, 1);
                setPreguntasNuevoSintoma(tmp);
              };
              return (
                <CardNuevaPreguntaTriaje
                  textoPregunta={pregunta.pregunta}
                  preguntaCerrada={pregunta.cerrada}
                  setTextoPregunta={handleSetTextoPregunta}
                  setPreguntaCerrada={handleSetPreguntaCerrada}
                  onBorrarPregunta={handleBorrarPregunta}
                />
              );
            })}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineOutlined />}
              fullWidth
              onClick={() => {
                let temp = [...preguntasNuevoSintoma];
                temp.push({cerrada: false, pregunta: ""});
                setPreguntasNuevoSintoma(temp);
              }}
            >
              Agregar pregunta
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            disabled={!puedeEnviarNuevoSintoma}
            onClick={() => {
              setDialogNuevoSintomaOpen(false);
              setLoadingSintomas(true);
              cargarNuevoSintoma(
                especialidadSelected!,
                tituloNuevoSintoma,
                preguntasNuevoSintoma,
                imagenNuevoSintoma!
              )
                .then((datosRecibidos) => {
                  setForceUpdateSintomasState(!forceUpdateSintomasState);
                  resetNuevoSintoma();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <div style={{marginTop: "40vh"}}>
        <LoadingBubbles />
      </div>
    );
  }
  return (
    <>
      <Card style={{flexGrow: 1, marginBottom: 20}}>
        <CardHeader
          avatar={
            <Avatar>
              <SettingsOutlined />
            </Avatar>
          }
          title={
            <Typography variant="h6">
              Administración de síntomas y preguntas triaje
            </Typography>
          }
          subheader="Seleccione aquí la especialidad a la cual desea añadir o quitar sintomas o preguntas de  triaje"
        />
        <CardActions>
          <FormControl style={{margin: "10px 10px 10px 65px", minWidth: 250}}>
            <InputLabel>Especialidad</InputLabel>
            <Select
              value={especialidadSelected}
              onChange={(event) => {
                setEspecialidadSelected(event.target.value as string);
              }}
            >
              {especialidades.map((especialidad, iEsp) => {
                return (
                  <MenuItem key={iEsp} value={especialidad.descripcion}>
                    {especialidad.descripcion}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </CardActions>
        <Divider />
        {especialidadSelected && (
          <CardContent
            style={{backgroundColor: theme.paletaColores.fondo.segundo}}
          >
            <Card style={{flexGrow: 1, marginBottom: 20}}>
              <CardContent>
                {loadingSintomas ? (
                  <div style={{margin: "10vh 0px 10vh 0px"}}>
                    <LoadingBubbles />
                  </div>
                ) : (
                  <Grid container spacing={1}>
                    {sintomas.map((sintoma, index) => {
                      return (
                        <>
                          <CardSintomaAdmin
                            key={index}
                            modeloSintoma={sintoma}
                            // setLoading={setLoadingSintomas}
                            especialidad={especialidadSelected}
                            onCompleteModificacion={() => {
                              setForceUpdateSintomasState(
                                !forceUpdateSintomasState
                              );
                            }}
                            setParentLoading={setLoadingSintomas}
                          />
                        </>
                      );
                    })}
                    <Grid item xs={12} lg={6}>
                      <Card variant="outlined">
                        <CardActionArea
                          onClick={() => {
                            resetNuevoSintoma();
                            setDialogNuevoSintomaOpen(true);
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                style={{
                                  background: "none",
                                  color: "black",
                                }}
                              >
                                <AddCircleOutlineOutlined />
                              </Avatar>
                            }
                            title={
                              <Typography>Agregar nuevo síntoma</Typography>
                            }
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </CardContent>
        )}
      </Card>
      <div className={classes.buttonsRoot}>
        <Volver
          onClickHandler={() => {
            history.goBack();
          }}
        />
      </div>
      {dialogNuevoSintoma()}
    </>
  );
};

export default withStyles(styles)(ListadoPacientesEspera);
