import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box, Divider, Grid, WithStyles, withStyles} from "@material-ui/core";

import {styles} from "./SeleccionarPersonaStyles";
import PacienteCard from "../../../../components/PacienteCard/PacienteCard";
import {
  getPacientes,
  setCuilPacienteSeleccionado,
} from "../../../../store/user/userSlice";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";

const SeleccionarPersona: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {cuilsDePacientes, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );
  const {ID_GRUPO_FAMILIAR} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getPacientes(ID_GRUPO_FAMILIAR!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPacienteClicked = (cuil: number) => {
    dispatch(setCuilPacienteSeleccionado(cuil));
    history.push(`/dashboard/ver-consultas-anteriores/historial`);
  };

  const onVolverClicked = () => {
    history.goBack();
  };

  return (
    <>
      <p>Por favor selecciona una persona.</p>
      <Divider />
      {cuilsDePacientes.length === 0 ? (
        <LoadingBubbles />
      ) : (
        <Box my={3}>
          <Grid justify="center" container spacing={3}>
            {cuilsDePacientes.map((cuil: number) => (
              <Grid item key={cuil}>
                <PacienteCard
                  paciente={pacientesByCuil[cuil]}
                  onClickHandler={() => onPacienteClicked(cuil)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Divider />
      <div className={classes.buttonsRoot}>
        <Volver onClickHandler={onVolverClicked} />
      </div>
    </>
  );
};

export default withStyles(styles)(SeleccionarPersona);
