import React, {useEffect, useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Toolbar,
  IconButton,
  useTheme,
  Button,
  Typography,
  Card,
  CardActionArea,
  CardHeader,
  Collapse,
  CardContent,
  TextField,
} from "@material-ui/core";
import {
  ArrowBackIos,
  CloseOutlined,
  ExpandLess,
  ExpandMore,
  SearchOutlined,
} from "@material-ui/icons";
import BusquedaPacienteHistorial from "../../../components/BusquedaPacienteHistorial/BusquedaPacienteHistorial";
import {IPaciente} from "../../../Interfaces/IPaciente.js";
import {IResultadoBusquedaPaciente} from "../../../Interfaces/IResultadoBuquedaPaciente";
import {IElementoHistorial} from "../../../Interfaces/IElementoHistorial";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import LoadingBubbles from "../../../components/LoadingBubbles/LoadingBubbles";
import {Pagination} from "@material-ui/lab";
import CardHistoriaClinica from "../../../components/CardHistoriaClinica/CardHistoriaClinica";
import DateFnsUtils from "@date-io/date-fns";
import {obtenerHistorialClinico} from "../../../apis/historiaClinicaAPI";

interface Props {
  open: boolean;
  onClose: () => void;
  paciente: {
    cuil: string;
    nombre: string;
  };
}

const VerFichaPaciente = ({open, onClose, paciente}: Props) => {
  const [loadingHistorial, setLoadingHistorial] = React.useState(false);
  const [historialClinico, setHistorialClinico] = React.useState<
    Array<IElementoHistorial>
  >([]);
  const [pagina, setPagina] = React.useState(0);

  const [filtros, setFiltros] = React.useState(false);
  const [apellidoProfesional, setApellidoProfesional] = React.useState("");
  const [especialidad, setEspecialidad] = React.useState("");
  const [fechaDesde, setFechaDesde] = React.useState<Date | null>(null);
  const [fechaHasta, setFechaHasta] = React.useState<Date | null>(null);
  const theme = useTheme();
  const elementosPerPag = 5;

  const limpiarFiltros = () => {
    setApellidoProfesional("");
    setEspecialidad("");
    setFechaDesde(null);
    setFechaHasta(null);
  };

  useEffect(() => {
    if (paciente.cuil !== "" && open) {
      buscarHistorialPaciente();
    }
  }, [paciente, open]);

  const buscarHistorialPaciente = () => {
    setLoadingHistorial(true);
    setPagina(0);
    obtenerHistorialClinico(
      paciente.cuil,
      fechaDesde,
      fechaHasta,
      especialidad,
      apellidoProfesional
    )
      .then((respuesta) => {
        setLoadingHistorial(false);
        respuesta.sort((a, b) => {
          return b.fecha._seconds - a.fecha._seconds;
        });
        setHistorialClinico(respuesta);
        console.log(respuesta);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogContent>
        <div>
          <Toolbar variant="dense">
            <Button
              variant="contained"
              disableElevation
              startIcon={<ArrowBackIos />}
              onClick={() => {
                onClose();
                limpiarFiltros();
              }}
            >
              Atras
            </Button>
            <div style={{flexGrow: 1}} />
            <Typography style={{margin: 10}} variant="h6">
              {paciente ? `${paciente.nombre}` : <LoadingBubbles />}
            </Typography>
            <div style={{flexGrow: 1}} />
            <Typography variant="h6">{paciente?.cuil || ""}</Typography>
          </Toolbar>
          <Card
            variant="outlined"
            style={{
              margin: 20,
              backgroundColor: theme.paletaColores.fondo.segundo,
            }}
          >
            <CardActionArea
              onClick={() => {
                setFiltros(!filtros);
              }}
            >
              <CardHeader
                title="Aplicar Filtros"
                action={filtros ? <ExpandLess /> : <ExpandMore />}
              />
            </CardActionArea>
            <Collapse in={filtros} timeout="auto" unmountOnExit>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CardContent>
                  <KeyboardDatePicker
                    style={{margin: 5, maxWidth: 150}}
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Desde"
                    value={fechaDesde}
                    onChange={(event) => {
                      setFechaDesde(event as Date);
                    }}
                    required
                    disableFuture
                    invalidDateMessage="Fecha inválida"
                    maxDateMessage="La fecha debe ser anterior a hoy"
                    InputAdornmentProps={{style: {visibility: "hidden"}}}
                  />
                  <KeyboardDatePicker
                    style={{margin: 5, maxWidth: 150}}
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Hasta"
                    value={fechaHasta}
                    onChange={(event) => {
                      setFechaHasta(event as Date);
                    }}
                    required
                    disableFuture
                    invalidDateMessage="Fecha inválida"
                    maxDateMessage="La fecha debe ser anterior a hoy"
                    InputAdornmentProps={{style: {visibility: "hidden"}}}
                  />
                  <TextField
                    style={{margin: 5}}
                    value={apellidoProfesional}
                    label="Apellido Profesional"
                    onChange={(event) => {
                      setApellidoProfesional(event.target.value as string);
                    }}
                  />
                  <TextField
                    style={{margin: 5}}
                    value={especialidad}
                    label="Especialidad"
                    onChange={(event) => {
                      setEspecialidad(event.target.value as string);
                    }}
                  />
                  <Button
                    onClick={buscarHistorialPaciente}
                    color="primary"
                    variant="contained"
                    disableElevation
                    startIcon={<SearchOutlined />}
                    style={{margin: 5}}
                    size="large"
                    disabled={loadingHistorial}
                  >
                    Buscar
                  </Button>
                </CardContent>
              </MuiPickersUtilsProvider>
            </Collapse>
          </Card>
          {loadingHistorial ? (
            <div style={{marginTop: "50px", marginBottom: "50px"}}>
              <LoadingBubbles />
            </div>
          ) : (
            <div style={{width: "100%"}}>
              {historialClinico.length === 0 ? (
                <div style={{marginTop: "50px", marginBottom: "50px"}}>
                  <Typography>
                    No hay datos para la búsqueda realizada.
                  </Typography>
                </div>
              ) : (
                historialClinico.map((elemento, index) => {
                  if (
                    index >= pagina * elementosPerPag &&
                    index < (pagina + 1) * elementosPerPag
                  ) {
                    return (
                      <CardHistoriaClinica
                        mostrarNotasPrivadas={false}
                        key={index}
                        elementoHistorial={elemento}
                      />
                    );
                  }
                  return null;
                })
              )}
              <Pagination
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  flexGrow: 1,
                  margin: 30,
                }}
                count={Math.ceil(historialClinico.length / elementosPerPag)}
                page={pagina + 1}
                onChange={(event, value) => {
                  setLoadingHistorial(true);
                  setTimeout(() => {
                    setPagina(value - 1);
                    setLoadingHistorial(false);
                  }, 500);
                }}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VerFichaPaciente;
