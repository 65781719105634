import React from "react";
import useQuery from "../../../hooks/useQuery";

import Jitsi from "../../../components/jitsi/Jitsi";
import Turn from "../../../components/turn/Turn";

const JitsiMobile: React.FC<any> = () => {
  const query = useQuery();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Jitsi
        roomName={query.get("sesion")}
        displayName={query.get("nombre")}
        showChat={false}
        showFullscreen={false}
      />
    </div>
  );
};

export default JitsiMobile as React.ComponentType;
