import axios from "axios";
import firebase from "firebase";
import {getFirebase} from "../db";
import {IProfesional} from "../Interfaces/IProfesional";

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

adminApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
adminApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("adminAPI.ts");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);
export async function obtenerListaProfesionales(): Promise<
  Array<IProfesional>
> {
  const {data} = await adminApi.get<Array<IProfesional>>(
    `/ObtenerListaProfesionales`
  );
  return data;
}
export async function finalizarSesionProfesional(
  emailProfesional: string
): Promise<string> {
  const {data} = await adminApi.get<string>(
    `/finalizarSesionProfesional?emailProfesional=${emailProfesional}`
  );
  return data;
}

export async function setUserClaims(email: string, rol: string): Promise<any> {
  const {data} = await adminApi.post<any>(`/setUserClaims`, {email, rol});
  return data;
}
