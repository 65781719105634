import React, {useEffect, useState, useRef} from "react";
import {useHistory} from "react-router-dom";
import {
  Box,
  Divider,
  Button,
  Grid,
  Paper,
  Typography,
  ThemeProvider,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import CancelOutlined from "@material-ui/icons/CancelOutlined";

import CardGuardiaMedico from "../../../../components/CardGuardiaMedico/CardGuardiaMedico";
import {getFirestore} from "../../../../db";
import {useSelector, useDispatch} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import {io} from "socket.io-client";
import {
  setSesionLogeo,
  setUser,
  setSaque,
} from "../../../../store/user/userSlice";
import {v4 as uuidv4} from "uuid";

import ring from "../../../../assets/sfx/notif_medico_guardia.wav";
import {obtenerCCitasAtendidasDia} from "../../../../apis/citaInstantaneaAPI";
import type firebase from "firebase";

const ringAudio = new Audio(ring);

interface CachedCallback {
  current: () => void;
}

const VistaGuardia: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();

  const {
    especialidades,
    NOMBRE,
    APELLIDO,
    email,
    matricula,
    sesionLogeo,
    saque,
    rol,
  } = useSelector((state: RootState) => state.user);

  const [alCerrar, setAlCerrar] = React.useState<boolean>(true);

  const dispatch = useDispatch();

  interface IElementoCardGuardia {
    backgroundColor: string;
    textoRectangulo?: string | undefined;
    titulo: string;
    subtitulo: string;
    componenteRectangulo?: React.ReactElement;
  }
  const [cantidadesEnEspera, setCantidadesEnEspera] = React.useState<
    Array<number>
  >([]);
  const [colegasAtendiendo, setColegasAtendiendo] = React.useState<number>(0);

  const [citasAtendidas, setCitasAtendidas] = React.useState(0);

  useEffect(() => {
    let mounted = true;
    dispatch(setSaque(true));
    console.log("puse true en saque");
    //CitasInstantaneas/Clínica Médica Adultos/sesiones/f9a9e367-e3a0-4a13-82f9-fcc85bbffafc
    obtenerCCitasAtendidasDia(email, new Date())
      .then((dato) => {
        setCitasAtendidas(dato.cantidadCitasAtendidas);
      })
      .catch((e) => {
        console.log(e);
      });

    const unsubscribeProfesionales = getFirestore()
      .collection("UsuariosConectados")
      .doc("profesional")
      .collection("Lista")
      .onSnapshot((profesionalesSnapshot) => {
        let temp = 0;
        profesionalesSnapshot.docs.forEach((prof) => {
          const profData = prof.data();
          const usuariomedico: string = profData.usuario;
          const esp: Array<string> = profData.especialidad
            .split(", ")
            .filter((x: string) => x !== "");
          let coincide = false;
          esp.forEach((espMedicoSnapshot, iEspMedicoSnapshot) => {
            if (
              especialidades?.findIndex(
                (x) => x.descripcion === espMedicoSnapshot
              ) !== -1
            ) {
              coincide = true;
            }
          });
          if (coincide) {
            if (!usuariomedico.startsWith("tiempototalguardia_")) {
              temp++;
            }
          }
        });
        setColegasAtendiendo(temp);
      });
    let cantidadesEnEsperaLocal = new Array<number>(especialidades!.length);

    for (let index = 0; index < cantidadesEnEsperaLocal.length; index++) {
      cantidadesEnEsperaLocal[index] = 0;
    }
    setCantidadesEnEspera(cantidadesEnEsperaLocal);

    let stringEsps = "";
    especialidades!.forEach((esp) => {
      if (esp.consultaEspontanea == "1" || esp.consultaEspontanea == "1") {
        stringEsps += `${esp.descripcion}, `;
      }
    });
    stringEsps = stringEsps.slice(0, stringEsps.length - 2);

    if (sesionLogeo === "") {
      const sesionLogeoNueva = uuidv4();
      dispatch(setSesionLogeo(sesionLogeoNueva));
    }
    const socket = io(`${process.env.REACT_APP_URL_SOCKET_GUARDIA}`, {
      reconnectionDelayMax: 10000,
      secure: true,
      query: {
        tipo: "profesional",
        usuario: email,
        nombre: NOMBRE,
        apellido: APELLIDO,
        matricula: matricula!.toString(),
        especialidad: stringEsps,
        estado: "Guardia",
        ambiente: `${process.env.REACT_APP_URL_PACIENTES}`,
        // "horaConexion": getCurrentTime().getTime().toString(),
        sesionLogeoID: sesionLogeo as string,
      },
    });

    const unsubscribesPacientes: Array<() => void> = [];

    if (mounted){
      especialidades!
      .filter((x) => x.consultaEspontanea == "1" || x.consultaEspontanea == 1)
      .forEach((especialidad, indexEspecialidad) => {
        setTimeout(() => {
          const unsubscribePacientes = getFirestore()
            .collection("CitasInstantaneas")
            .doc(especialidad.descripcion)
            .collection("sesiones")
            .where("estado", "==", "P")
            .where("profesional", "==", null)
            .onSnapshot((sesiones) => {
              let temp = [...cantidadesEnEsperaLocal];
              let playSound = false;
              if (
                sesiones.size > cantidadesEnEsperaLocal[indexEspecialidad] ||
                (cantidadesEnEsperaLocal[indexEspecialidad] === undefined &&
                  sesiones.size > 0)
              ) {
                playSound = true;
              }
              if (playSound && mounted) {
                ringAudio.play();
              }
              temp[indexEspecialidad] = sesiones.size;
              cantidadesEnEsperaLocal = [...temp];
              setCantidadesEnEspera(cantidadesEnEsperaLocal);
            });
          unsubscribesPacientes.push(unsubscribePacientes);
        }, indexEspecialidad * 2000);
      });
    }
    
    return () => {
      mounted = false;
      unsubscribesPacientes.forEach((unsubscribe) => {
        unsubscribe();
      });
      unsubscribeProfesionales();
      socket.disconnect();

      function confirmExit() {
        dispatch(
          setUser({
            NOMBRE: "",
            APELLIDO: "",
            NRO_DOCUMENTO: "",
            email: "",
            rol: null,
            isUserLogin: false,
            warningSarsCov2: false,
            pacientesByCuil: {},
            cuilsDePacientes: [],
            cuilPacienteSeleccionado: -1,
            error: null,
            photoURL: "",
          })
        );
        localStorage.removeItem("token");
        dispatch(setSesionLogeo(""));
        return "show warning";
      }
      if (rol === "MEDICO") {
        window.onbeforeunload = confirmExit;
        window.onhashchange = confirmExit;
      }
    };
  }, []);

  const onSiguienteClicked = () => {
    //guardar los datos del proximo evento!
    dispatch(setSaque(false));
  };

  useEffect(() => {
    setAlCerrar(saque as boolean);
    if (!saque) {
      dispatch(setSaque(true));
      history.push(`/dashboard/guardia/proximo-paciente`);
    }
  }, [saque]);

  /*useEffect(() => {
        return () => {
            console.log(`al destruir la guardia ${alCerrar}`)
            console.log(window)
            window.onpopstate = () => {
                console.log('saque por guardia')
                dispatch(setSesionLogeo(''))
            }
        }
    }, [])
*/
  const [consultaExistente, setConsultaExistente] = React.useState(false);
  const [consultasActivas, setConsultasActivas] = React.useState<
    Array<{sesion: string; especialidad: string}>
  >([]);
  const [loadingActivas, setLoadingActivas] = React.useState(false);

  useEffect(() => {
    let total = 0;
    let firestorePromises: Array<
      Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> | void>
    > = [];
    let activas: Array<{sesion: string; especialidad: string}> = [];
    especialidades?.forEach((esp, iEsp) => {
      //let store = getFirestore().collection("CitasInstantaneas").doc(esp.descripcion).collection("sesiones").where('emailProfesional', '==', `${email}`);
      let store = getFirestore()
        .collection("UsuariosConectados")
        .doc("profesional")
        .collection("Lista")
        .where("usuario", "==", `${email}`);
      let promise: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> | void> =
        store.get().then((consultas) => {
          if (consultas.docs.length > 0) {
            console.log("hay consultas de ese prof");
            for (let consulta of consultas.docs) {
              activas.push({
                sesion: consulta.id,
                especialidad: esp.descripcion,
              });
              total++;
              setConsultaExistente(true);
              //console.log("viendo consulta " + consulta.id);
              // if (consulta.data().estado == 'A' || consulta.data().estado == 'R' || consulta.data().estado == 'P') {
              //     console.log(`consulta ${consulta.id} estado asignada o recibida ${consulta.data().estado}`);
              //     activas.push({ sesion: consulta.id, especialidad: esp.descripcion })
              //     total++;
              //     setConsultaExistente(true)
              // }
            }
          }
        });
      firestorePromises.push(promise);
    });

    Promise.all(firestorePromises)
      .then(() => {
        if (total > 0) {
          console.log("activando");
          setConsultasActivas(activas);
        } else {
          console.log("desactivando");
          setConsultaExistente(false);
          setConsultasActivas([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  let cantidadEspera = 0;
  cantidadesEnEspera.forEach((cantidad) => {
    cantidadEspera += cantidad;
  });
  /*
       const cancelarConsultasActivas = async () => {
           setLoadingActivas(true)
           for (let consulta of consultasActivas) {
               await getFirestore().collection('CitasInstantaneas').doc(consulta.especialidad).collection('sesiones').doc(consulta.sesion).set({
                   estado: 'C',
                   status: 'cancelado-por-boton-al-sacar-otra-sesion'
               }, {
                   merge: true
               })
           }
           setLoadingActivas(false)
           setConsultaExistente(false)
       }
   */
  const elementosCardGuardia: IElementoCardGuardia[] = [
    {
      backgroundColor: theme.paletaColores.naranja.tercero,
      textoRectangulo: cantidadEspera.toString(),
      titulo: "Pacientes en espera",
      subtitulo: "Total de pacientes",
    },
    {
      backgroundColor: theme.paletaColores.fondo.tercero,
      textoRectangulo: citasAtendidas.toString(),
      titulo: "Mi producción",
      subtitulo: "Consultas atendidas",
    },
    {
      backgroundColor: theme.paletaColores.fondo.tercero,
      textoRectangulo: colegasAtendiendo.toString(),
      titulo: "Profesionales",
      subtitulo: "Profesionales atendiendo",
    },
  ];
  /*
        if (consultaExistente) {
            console.log(consultasActivas);
            return (
                <Dialog open={true} fullWidth maxWidth="xs">
                    <DialogContent>
                        <DialogContentText>
                            {`Estas conectado en otra sesión.Podes cancelarla y continuar aquí, o permanecer en la otra conexión.`}
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                PERMANECER EN LA OTRA SESIÓN
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                disabled={loadingActivas}
                                onClick={() => {
                                    cancelarConsultasActivas()
                                }}
                            >
                                {loadingActivas ? 'PROCESANDO...' : 'NUEVA CONEXIÓN'}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )
        }
    */
  return (
    <>
      <h2>Resumen de la Guardia virtual</h2>
      <Divider />
      <Box my={3}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={8}>
            <Paper
              style={{backgroundColor: "#EEEEEE", width: "97%", margin: "1.5%"}}
            >
              <Grid container item xs={12} direction="column">
                <Grid container item xs>
                  <Paper style={{width: "100%"}} square>
                    <Grid
                      container
                      alignItems="center"
                      style={{width: "98%", margin: "1%"}}
                    >
                      <Typography variant="h6">
                        Resumen de la guardia virtual
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid container item xs>
                  <Grid container item xs direction="column">
                    {elementosCardGuardia.map((card, index) => {
                      return (
                        <>
                          <CardGuardiaMedico
                            key={"card" + index}
                            backgroundColor={card.backgroundColor}
                            textoRectangulo={card.textoRectangulo}
                            titulo={card.titulo}
                            subtitulo={card.subtitulo}
                            componenteRectangulo={card.componenteRectangulo}
                          />
                          <Divider key={"divider" + index} />
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{backgroundColor: "#EEEEEE", width: "97%", margin: "1.5%"}}
            >
              <Grid container item xs={12} direction="column">
                <Grid container>
                  <Paper style={{width: "100%"}} square>
                    <Grid
                      container
                      alignItems="center"
                      style={{width: "94%", margin: "3%"}}
                    >
                      <Typography variant="h6">Estado: Atendiendo</Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid container item xs>
                  <Grid container item xs style={{width: "96%", margin: "2%"}}>
                    <div style={{margin: 10}}>
                      <Typography variant="h6">Recordá:</Typography>
                      <Typography paragraph>
                        El botón Ver próximo paciente te mostrará una vista
                        previa del siguiente en la lista de espera de la guardia
                        virtual.
                      </Typography>
                      <Typography paragraph>
                        Los pacientes en espera serán llamados por todos los
                        médicos atendiendo en la guardia virtual de la
                        plataforma.
                      </Typography>
                      <Typography paragraph>
                        Asegurate de revisar periódicamente el estado de tu
                        conexión. Si tenes una consulta, contacta al coordinador
                        de guardia.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* <p>
                        Hay {citaSiguiente? citaSiguiente!.enEspera : 0} pacientes en espera;
                    </p> */}
        </Grid>
      </Box>
      <Divider />

      <Box my={3}>
        {/* <Grid justify="center" container spacing={3}>
                    <ul>
                        {citaSiguiente.map((item) => (
                            <li key={"item.summary." + item.id}>
                                {item.summary}
                                <ul>
                                    <li key={"item.description." + item.id}>{item.description}</li>
                                    <li key={"item.start." + item.id}>{item.start.dateTime}</li>
                                    <li key={"item.end." + item.id}>{item.end.dateTime}</li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                </Grid> */}
        <Grid justify="center" container spacing={3}>
          <Button
            variant="contained"
            disableElevation
            component="label"
            onClick={() => {
              /*dispatch(setSesionLogeo(''));*/ history.push("/dashboard");
            }}
            startIcon={<CancelOutlined />}
            style={{margin: 5}}
          >
            Salir de la guardia
          </Button>
          <Button
            disabled={cantidadEspera < 1}
            variant="contained"
            disableElevation
            onClick={onSiguienteClicked}
            color="primary"
            style={{margin: 5}}
          >
            Ver próximo paciente
          </Button>
        </Grid>
      </Box>
      <Divider />
    </>
  );
};

export default VistaGuardia;
