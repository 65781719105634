import React, {useState, useEffect} from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Toolbar,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import {styles} from "./AgendaDeTurnosStyles";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import PerfilPaciente from "../../../../components/PerfilPaciente/PerfilPaciente";
// import CardHistoriaClinica from '../../../components/CardHistoriaClinica/CardHistoriaClinica';
// import { obtenerHistorialClinico } from '../../../apis/historiaClinicaAPI';
// import { IElementoHistorial } from '../../../Interfaces/IElementoHistorial';
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {Pagination} from "@material-ui/lab";
import {obtenerTurnos} from "../../../../apis/citaProgramadaAPI";
import {ITurnoAgendado} from "../../../../Interfaces/ITurnoAgendado";
import CardTurnoAgendado from "../../../../components/CardTurnoAgendado/CardTurnoAgendado";
import {convertDiaHoraToDate} from "../../../../utils/dateTimeHelper";

const AgendaDeTurnos: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const {cuilPacienteSeleccionado, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    obtenerDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuilPacienteSeleccionado]);

  const [loading, setLoading] = useState(false);

  const obtenerDatos = async () => {
    setLoading(true);
    let data = await obtenerTurnos(cuilPacienteSeleccionado.toString());
    setLoading(false);
    data.sort((a, b) => {
      let dateA = convertDiaHoraToDate(a.turno);
      let dateB = convertDiaHoraToDate(b.turno);
      return dateB.getTime() - dateA.getTime();
    });
    // data = data.filter((cita) => {
    //     const dateTime = convertDiaHoraToDate(cita.turno);
    //     let today = new Date();
    //     today.setHours(today.getHours() - 2);
    //     return (dateTime.getTime() > today.getTime());
    // })
    setAgenda(data as any);
  };
  const [ocultarVencidos, setOcultarVencidos] = useState(true);

  const [agenda, setAgenda] = useState<Array<ITurnoAgendado>>([]);
  const [agendaFiltrada, setAgendaFiltrada] = useState<Array<ITurnoAgendado>>(
    []
  );

  useEffect(() => {
    if (agenda.length > 0) {
      if (ocultarVencidos) {
        let datos = [...agenda];
        datos = datos.filter((x) => x.estado !== "Vencida");
        setAgendaFiltrada(datos);
      } else {
        setAgendaFiltrada(agenda);
      }
    } else {
      setAgendaFiltrada([]);
    }
  }, [agenda, ocultarVencidos]);

  const elementosPerPag = 5;
  const [pagina, setPagina] = useState(0);
  return (
    <Grid container>
      <Grid item xs />
      <Grid container item xs={10} direction="column">
        {/* <Typography variant="h6">Confirmación de la consulta de {especialidad}</Typography> */}
        <Divider />
        <Box my={4}>
          <PerfilPaciente
            paciente={pacientesByCuil[cuilPacienteSeleccionado]}
          />
        </Box>
        <Divider />
        <p />
        <Paper style={{paddingBottom: -50}}>
          <p />
          <Toolbar>
            <Typography variant="h5">Agenda de Turnos</Typography>
            <div style={{flexGrow: 1}} />
            <FormControlLabel
              control={
                <Switch
                  checked={ocultarVencidos}
                  onChange={(event) => {
                    setOcultarVencidos(event.target.checked);
                  }}
                />
              }
              labelPlacement="start"
              label="Ocultar Vencidos"
            />
          </Toolbar>
          <p />
          {loading ? (
            <LoadingBubbles />
          ) : (
            <>
              <Grid item container xs={12}>
                <Grid item container xs={12} direction="column">
                  {agendaFiltrada.length > 0 ? (
                    <>
                      {agendaFiltrada.map((turnoAgendado, index) => {
                        if (
                          index >= pagina * elementosPerPag &&
                          index < (pagina + 1) * elementosPerPag
                        ) {
                          return (
                            <CardTurnoAgendado
                              key={index}
                              turno={turnoAgendado}
                            />
                          );
                        }
                        return null;
                      })}
                      <Pagination
                        style={{
                          alignSelf: "center",
                          justifySelf: "center",
                          flexGrow: 1,
                          margin: 30,
                        }}
                        count={Math.ceil(
                          agendaFiltrada.length / elementosPerPag
                        )}
                        page={pagina + 1}
                        onChange={(event, value) => {
                          setLoading(true);
                          setTimeout(() => {
                            setPagina(value - 1);
                            setLoading(false);
                          }, 500);
                        }}
                      />
                    </>
                  ) : (
                    <Box margin={2} textAlign="center">
                      <Typography variant="h6">
                        Usted no tiene turnos agendados
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        <div className={classes.buttonsRoot}>
          <Volver
            onClickHandler={() => {
              history.push(`/dashboard`);
            }}
          />
        </div>
      </Grid>
      <Grid item xs />
    </Grid>
  );
};

export default withStyles(styles)(AgendaDeTurnos);
