import React from "react";
import {Button, makeStyles, useTheme} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

interface PublicProps {
  onClickHandler: () => void;
}

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    width: 250,
  },
});

const Volver: React.FC<PublicProps> = (props) => {
  const {onClickHandler} = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      disableElevation
      startIcon={<ArrowBackIosIcon fontSize="small" />}
      onClick={onClickHandler}
    >
      Volver
    </Button>
  );
};

export default Volver as React.ComponentType<PublicProps>;
