import {
  Card,
  CardContent,
  Grid,
  Link,
  Toolbar,
  Typography,
  WithStyles,
  useTheme,
  withStyles,
} from "@material-ui/core";

import React from "react";
import {styles} from "./TurnosDiaMedicoStyles";

interface OwnProps {
  title: String;
  pacientesEspera: number;
  onClick: () => void;
  icon: React.ReactNode;
  buttonText: string;
  isGuardia?: boolean;
}

type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const SectionTurno: React.FC<Props> = (props) => {
  const {
    classes,
    title,
    pacientesEspera,
    onClick,
    icon,
    buttonText,
    isGuardia = false,
  } = props;
  const theme = useTheme();

  return (
    <>
      <Grid item container>
        <Card
          style={{
            flexGrow: 1,
            backgroundColor:
              pacientesEspera > 0
                ? theme.paletaColores.naranja.cuarto
                : theme.paletaColores.fondo.primero,
          }}
        >
          <CardContent className={classes.cardHome}>
            <Toolbar
              variant="dense"
              style={{padding: 0, justifyContent: "space-between"}}
            >
              <Typography variant="h6" style={{fontWeight: "bold"}}>
                {title}
              </Typography>
              {/* <img src={icon} alt="imagen app mobile" width="100%" />  */}
              {icon}
            </Toolbar>
            <Typography
              variant="subtitle1"
              style={{color: theme.paletaColores.negro.segundo}}
            >
              Pacientes en espera:
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Typography
                variant="h3"
                style={{
                  color: theme.paletaColores.negro.segundo,
                  fontWeight: "bold",
                }}
              >
                {pacientesEspera}
              </Typography>
              {pacientesEspera > 0 || isGuardia ? (
                <div style={{flexDirection: "row", textAlign: "right"}}>
                  <Link
                    onClick={() => {
                      onClick();
                    }}
                    underline="hover"
                    align="right"
                    style={{
                      color: theme.paletaColores.naranja.segundo,
                      cursor: "pointer",
                      width: "35%",
                      marginTop: "30px",
                      alignSelf: "self-end",
                    }}
                  >
                    {buttonText}
                  </Link>
                </div>
              ) : null}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default withStyles(styles)(
  SectionTurno
) as React.ComponentType<PublicProps>;
