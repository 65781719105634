import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: 8,
      minWidth: 310,
      maxWidth: 420,
      width: 420,
      backgroundColor: theme.paletaColores.fondo.segundo,
      "&:hover": {
        backgroundColor: theme.paletaColores.naranja.cuarto,
        cursor: "pointer",
        "& p>b": {
          color: "orange",
        },
      },
      "& p": {
        margin: "10px 0",
      },
    },
  });
