import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import ContainerBusquedaPacienteHistorial from "../../../pages/Administrador/AdministrarHistorialOffline/ContainerBusquedaPacienteHistorial/ContainerBusquedaPacienteHistorial";

const AdministrarHistorialOffline = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/buscar-historial-paciente`} exact />
      <PrivateRoute
        component={ContainerBusquedaPacienteHistorial}
        path={`${path}/buscar-historial-paciente`}
      />
    </Switch>
  );
};

export default AdministrarHistorialOffline;
