import {useDispatch} from "react-redux";
import {IEspecialidad} from "../Interfaces/IEspecialidad";
import {IUser} from "../Interfaces/IUser";
import store from "../store/store";
import {setUser} from "../store/user/userSlice";
import {v4 as uuidv4} from "uuid";
import {IPlan} from "../Interfaces/IPlan";

export function login(
  nombre: string,
  apellido: string,
  email: string,
  rol:
    | "PACIENTE"
    | "MEDICO"
    | "OPERADOR"
    | "ADMINISTRADOR"
    | "ADMINISTRADOR FINOCHIETTO"
    | "FARMACEUTICO",
  especialidades: Array<IEspecialidad> = [],
  matricula: number | undefined = undefined,
  plan: IPlan | undefined = undefined,
  idGrupoFamiliar: number | undefined = undefined,
  credencial: string | undefined = undefined,
  dni: string | undefined = undefined,
  photoURL: string | undefined = undefined
) {
  let user: IUser = {...store.getState().user};
  user.NOMBRE = nombre;
  user.APELLIDO = apellido;
  user.email = email;
  user.rol = rol;
  user.isUserLogin = false;
  user.cuilsDePacientes = [];
  user.cuilPacienteSeleccionado = -1;
  user.pacientesByCuil = {};
  user.error = null;
  user.photoURL = photoURL;

  if (rol === "PACIENTE") {
    user.plan = plan;
    user.ID_GRUPO_FAMILIAR = idGrupoFamiliar;
    user.NRO_CREDENCIAL = credencial;
    user.NRO_DOCUMENTO = dni!;
    user.warningSarsCov2 = true;
  } else {
    user.especialidades = especialidades;
    user.matricula = matricula;
    user.sesionLogeo = uuidv4();
    user.warningSarsCov2 = false;
    user.NRO_DOCUMENTO = "";
  }

  store.dispatch(setUser(user));

  return user;
}
