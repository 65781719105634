import React from "react";
import SwipeableViews from "react-swipeable-views";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tab,
  Tabs,
  Box,
  useTheme,
  Button,
  ButtonGroup,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogActions,
  Toolbar,
  TextField,
  Avatar,
  Checkbox,
} from "@material-ui/core";

import {IElementoHistorial} from "../../Interfaces/IElementoHistorial";
import CardArchivoSubido from "../CardArchivoSubido/CardArchivoSubido";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloseOutlined,
  CloudDownloadOutlined,
  Star,
  StarBorder,
  Favorite,
  FavoriteBorder,
} from "@material-ui/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SolicitarFarmaciaBtn from "../SolicitarFarmaciaBtn/SolicitarFarmaciaBtn";
import {GeolocationData} from "../../hooks/useGeolocation";
import perfilMedico from "../../assets/images/perfil_medico.jpg";
import usuario from "../../assets/images/usuario.png";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {
  agregarMedicoAFavoritos,
  esMedicoFavorito,
  quitarMedicoDeFavoritos,
} from "../../apis/medicosFavoritosAPI";
import LoadingBubbles from "../LoadingBubbles/LoadingBubbles";

// import StarIcon from '@material-ui/icons/Star';
// import Checkbox from '@material-ui/core/Checkbox';
// import Close from '@material-ui/icons/Close';

interface OwnProps {
  elementoHistorial: IElementoHistorial;
  setElementoHistorial?: (newElemento: IElementoHistorial) => void;
  mostrarNotasPrivadas: boolean;
  puedePedirFarmacia?: boolean;
  areaSupportsPharmacy?: boolean;
  locationData?: GeolocationData;
  mostrarAgregarFavorito?: boolean;
  instantanea?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{width: "100%", flex: 1}}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

type PublicProps = OwnProps;
type Props = PublicProps;

const ElementoHistoriaClinicaContent: React.FC<Props> = (props) => {
  const {
    elementoHistorial,
    setElementoHistorial,
    mostrarNotasPrivadas,
    puedePedirFarmacia,
    locationData,
    areaSupportsPharmacy,
    mostrarAgregarFavorito,
  } = props;

  const {cuilPacienteSeleccionado, email} = useSelector(
    (state: RootState) => state.user
  );

  const [tabSeleccionada, setTabSeleccionada] = React.useState(0);
  const handleChangeTab = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTabSeleccionada(newTab);
  };

  const handleChangeIndex = (index: number) => {
    setTabSeleccionada(index);
  };

  const {height, width} = useWindowDimensions();

  const theme = useTheme();

  const subtitle_h5 = {color: theme.paletaColores.naranja.tercero};
  const subtitle_h6 = {color: theme.paletaColores.naranja.primero};

  const mostrarOdontologia =
    elementoHistorial.odontoConsulta?.length > 0 ||
    elementoHistorial.odontoPerio?.length > 0 ||
    elementoHistorial.odontoRadio?.length > 0;

  const [favorito, setFavorito] = React.useState(false);
  const [loadingFav, setLoadingFav] = React.useState(false);
  const [favConfirmOpen, setFavConfirmOpen] = React.useState(false);

  const openFavConfirmation = () => {
    setFavConfirmOpen(true);
  };
  const closeFavConfirmation = () => {
    setFavConfirmOpen(false);
  };
  const handleToggleFav = () => {
    closeFavConfirmation();
    setLoadingFav(true);
    const toggle = favorito ? quitarMedicoDeFavoritos : agregarMedicoAFavoritos;
    toggle(
      cuilPacienteSeleccionado.toString(),
      elementoHistorial.emailProfesional!,
      elementoHistorial.especialidad
    )
      .then((res) => {
        esMedicoFavorito(
          cuilPacienteSeleccionado.toString(),
          elementoHistorial.emailProfesional!,
          elementoHistorial.especialidad
        )
          .then((res) => {
            setFavorito(res);
            setLoadingFav(false);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    if (mostrarAgregarFavorito) {
      setLoadingFav(true);
      esMedicoFavorito(
        cuilPacienteSeleccionado.toString(),
        elementoHistorial.emailProfesional!,
        elementoHistorial.especialidad
      )
        .then((res) => {
          setFavorito(res);
          setLoadingFav(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <>
      {mostrarAgregarFavorito && (
        <Dialog open={favConfirmOpen}>
          <DialogContent>
            <DialogContentText>
              {favorito
                ? `Desea quitar al medico ${elementoHistorial.nombreProfesional} de favoritos?`
                : `Desea agregar al medico ${elementoHistorial.nombreProfesional} a favoritos?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disableElevation
              size="small"
              onClick={closeFavConfirmation}
            >
              No
            </Button>
            <Button
              variant="contained"
              disableElevation
              size="small"
              onClick={handleToggleFav}
              color="primary"
            >
              Si
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div style={{flexDirection: "row", display: "flex"}}>
        <div style={{width: 90, height: 80}}>
          <div
            style={{
              width: 70,
              height: 70,
              margin: 5,
              overflow: "clip",
              borderRadius: 10,
            }}
          >
            <img
              src={
                elementoHistorial!.photoURL
                  ? elementoHistorial!.photoURL!
                  : usuario
              }
              alt="imagen medico"
              height="100%"
            />
          </div>
        </div>
        <div style={{flexGrow: 1}}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{color: theme.paletaColores.naranja.segundo}}
            >
              <span
                style={{
                  color: theme.paletaColores.negro.primero,
                  marginRight: "5",
                }}
              >
                Atendida por:{" "}
              </span>
              {elementoHistorial.nombreProfesional}
            </Typography>

            {!elementoHistorial.instantanea ? (
              <Checkbox
                checked={favorito}
                onClick={openFavConfirmation}
                disabled={loadingFav}
                icon={
                  loadingFav ? (
                    <LoadingBubbles size={24} useCircle />
                  ) : (
                    <FavoriteBorder />
                  )
                }
                checkedIcon={
                  loadingFav ? (
                    <LoadingBubbles size={24} useCircle />
                  ) : (
                    <Favorite />
                  )
                }
              />
            ) : null}

            {/* <Checkbox checked={favorito}
                            onClick={openFavConfirmation}
                            disabled={loadingFav}
                            icon={loadingFav ? <LoadingBubbles size={24} useCircle /> : <FavoriteBorder />}
                            checkedIcon={loadingFav ? <LoadingBubbles size={24} useCircle /> : <Favorite />}
                        /> */}
          </div>
          <Typography
            paragraph
            variant="body1"
            style={{color: theme.paletaColores.naranja.segundo}}
          >
            <span style={{color: theme.paletaColores.negro.primero}}>
              Matrícula:{" "}
            </span>
            {elementoHistorial.matriculaProfesional}
          </Typography>
        </div>
      </div>
      {elementoHistorial.motivo ? (
        <Card style={{marginBottom: 20}}>
          <CardContent>
            <Typography variant="h6">
              Motivo: {elementoHistorial.motivo!}
            </Typography>
            {elementoHistorial.triaje?.map((qa, qaInd) => {
              return (
                <>
                  <Typography
                    style={{color: theme.paletaColores.naranja.tercero}}
                  >
                    {qa.pregunta}
                  </Typography>
                  <Typography style={{marginLeft: 20}} variant="body2">
                    {`• ${qa.respuesta ? qa.respuesta : "(no contesta)"}`}
                  </Typography>
                </>
              );
            })}
          </CardContent>
        </Card>
      ) : null}
      {/* {
                dialogOpen ?
                <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth >
                    <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea agregar al {elementoHistorial.nombreProfesional} como favoritos?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary">
                            No
                        </Button>
                        <Button onClick={closeDialog} color="primary" autoFocus>
                            Si
                        </Button>
                    </DialogActions>
                </Dialog>:
                null
            }
            {
                dialogOpen2 ?
                <Dialog open={dialogOpen2} onClose={closeDialog2} maxWidth="md" fullWidth >
                    <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Está seguro que desea eliminar al {elementoHistorial.nombreProfesional} de sus médicos favoritos?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog2} color="primary">
                            No
                        </Button>
                        <Button onClick={closeDialog2} color="primary" autoFocus>
                            Si
                        </Button>
                    </DialogActions>
                </Dialog>:
                null
            } */}

      <Typography variant="h6">Subjetivo</Typography>
      <Typography paragraph variant="body2">
        {elementoHistorial.motivoConsulta}
      </Typography>
      <Typography variant="h6">Objetivo</Typography>
      <Typography paragraph variant="body2">
        {elementoHistorial.enfermedadActual}
      </Typography>
      <Typography variant="h6">Evaluación</Typography>
      <Typography paragraph variant="body2">
        {elementoHistorial.diagnosticoPresuntivo
          ? elementoHistorial.diagnosticoPresuntivo.map((enfermedad, index) => {
              return `(${enfermedad.ID}) ${enfermedad.Name}, `;
            })
          : "None"}
      </Typography>
      <Card square>
        <CardContent>
          <Grid item container xs={12}>
            <Typography
              variant="h6"
              style={{
                ...subtitle_h5,
                marginLeft: 15,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              PLAN
            </Typography>
            <Divider />
            {width < 576 ? (
              <Grid item xs={12}>
                <ButtonGroup fullWidth variant="outlined">
                  <Button
                    disabled={tabSeleccionada === 0}
                    onClick={() => {
                      if (tabSeleccionada === 2 && !mostrarNotasPrivadas) {
                        setTabSeleccionada(0);
                      } else {
                        setTabSeleccionada(tabSeleccionada - 1);
                      }
                    }}
                    style={{width: 30}}
                  >
                    <ArrowBackIos />{" "}
                  </Button>
                  <Button disabled style={{color: "black"}}>
                    {tabSeleccionada === 0 && "Conducta Terapéutica"}
                    {tabSeleccionada === 1 && "Notas Privadas"}
                    {tabSeleccionada === 2 && "Prácticas"}
                    {tabSeleccionada === 3 && "Medicación"}
                  </Button>
                  <Button
                    disabled={tabSeleccionada === 3}
                    onClick={() => {
                      if (tabSeleccionada === 0 && !mostrarNotasPrivadas) {
                        setTabSeleccionada(2);
                      } else {
                        setTabSeleccionada(tabSeleccionada + 1);
                      }
                    }}
                    style={{width: 30}}
                  >
                    <ArrowForwardIos />
                  </Button>
                </ButtonGroup>
              </Grid>
            ) : (
              <Tabs
                style={{flexGrow: 1, width: "100%"}}
                value={tabSeleccionada}
                onChange={handleChangeTab}
              >
                <Tab
                  value={0}
                  style={{fontSize: 11, minWidth: "30px"}}
                  {...a11yProps(0)}
                  label="Conducta Terapéutica"
                />
                {mostrarNotasPrivadas && (
                  <Tab
                    value={1}
                    style={{fontSize: 11, minWidth: "30px"}}
                    {...a11yProps(1)}
                    label="Notas Privadas"
                  />
                )}
                <Tab
                  value={2}
                  style={{fontSize: 11, minWidth: "30px"}}
                  {...a11yProps(2)}
                  label="Prácticas"
                />
                <Tab
                  value={3}
                  style={{fontSize: 11, minWidth: "30px"}}
                  {...a11yProps(3)}
                  label="Medicación"
                />
                <Tab
                  value={4}
                  style={{fontSize: 11, minWidth: "30px"}}
                  {...a11yProps(4)}
                  label="Interconsulta"
                />
              </Tabs>
            )}
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={tabSeleccionada}
              onChangeIndex={handleChangeIndex}
              style={{width: "100%"}}
            >
              <TabPanel value={tabSeleccionada} index={0} dir={theme.direction}>
                <Grid container xs={12} style={{flex: 1, width: "100%"}}>
                  <Grid item xs />
                  <Grid item container xs={12} direction="column">
                    <Grid
                      item
                      container
                      xs={12}
                      style={{width: "100%", flex: 1}}
                      direction="column"
                    >
                      <Typography variant="h6">Indicaciones</Typography>
                      <Typography paragraph variant="body2">
                        {elementoHistorial.indicaciones}
                      </Typography>
                      <Typography variant="h6">Signos de alerta</Typography>
                      <Typography paragraph variant="body2">
                        {elementoHistorial.signosAlerta}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs />
                </Grid>
              </TabPanel>
              {mostrarNotasPrivadas && (
                <TabPanel
                  value={tabSeleccionada}
                  index={1}
                  dir={theme.direction}
                >
                  <Typography variant="h6">Notas Privadas</Typography>
                  <Typography paragraph variant="body2">
                    {elementoHistorial.notasPrivadas}
                  </Typography>
                </TabPanel>
              )}
              <TabPanel value={tabSeleccionada} index={2} dir={theme.direction}>
                <Typography style={subtitle_h5} variant="h6">
                  Ambulatoria
                </Typography>
                {elementoHistorial.ambuImg?.length > 0 && (
                  <>
                    <Typography style={subtitle_h6}>Imagenes</Typography>
                    {elementoHistorial.ambuImg?.map((practica, index) => {
                      return (
                        <Typography variant="body2">
                          {practica.descripcion}
                        </Typography>
                      );
                    })}
                    <p />
                  </>
                )}
                <Typography style={subtitle_h6}>Laboratorio</Typography>
                {elementoHistorial.ambuLab?.map((practica, index) => {
                  return (
                    <Typography variant="body2">
                      {practica.descripcion}
                    </Typography>
                  );
                })}
                <p />
                <Typography style={subtitle_h6}>
                  Estudios y Practicas
                </Typography>
                {elementoHistorial.ambuEstPract?.map((practica, index) => {
                  return (
                    <Typography variant="body2">
                      {practica.descripcion}
                    </Typography>
                  );
                })}
                <p />
                <Typography style={subtitle_h6}>Consulta</Typography>
                {elementoHistorial.ambuConsulta?.map((practica, index) => {
                  return (
                    <Typography variant="body2">
                      {practica.descripcion}
                    </Typography>
                  );
                })}
                <Divider style={{marginTop: 10, marginBottom: 10}} />
                {mostrarOdontologia && (
                  <>
                    <Typography style={subtitle_h5} variant="h6">
                      Odontología
                    </Typography>
                    <Typography style={subtitle_h6}>Consulta</Typography>
                    {elementoHistorial.odontoConsulta?.map(
                      (practica, index) => {
                        return (
                          <Typography variant="body2">
                            {practica.descripcion}
                          </Typography>
                        );
                      }
                    )}
                    <p />
                    <Typography style={subtitle_h6}>Periodoncia</Typography>
                    {elementoHistorial.odontoPerio?.map((practica, index) => {
                      return (
                        <Typography variant="body2">
                          {practica.descripcion}
                        </Typography>
                      );
                    })}
                    <p />
                    <Typography style={subtitle_h6}>Radiología</Typography>
                    {elementoHistorial.odontoRadio?.map((practica, index) => {
                      return (
                        <Typography variant="body2">
                          {practica.descripcion}
                        </Typography>
                      );
                    })}
                  </>
                )}
              </TabPanel>
              <TabPanel value={tabSeleccionada} index={3} dir={theme.direction}>
                <Typography variant="h6" paragraph>
                  Recetas
                </Typography>
                {elementoHistorial.recetas?.map((receta, iReceta) => {
                  const setEstadoReceta = (newEstado: string) => {
                    let temp = {...elementoHistorial};
                    temp.recetas[iReceta].estado = newEstado;
                    setElementoHistorial!(temp);
                  };

                  return (
                    <Card
                      style={{marginBottom: 10}}
                      elevation={3}
                      key={iReceta}
                    >
                      <CardContent>
                        <Paper
                          variant="outlined"
                          style={{margin: 10, padding: 10}}
                        >
                          <Typography variant="h6">Diagnostico</Typography>
                          {receta ? (
                            receta?.diagnosticoReceta?.map(
                              (diagnostico, iDiagnostico) => {
                                return (
                                  <Typography
                                    variant="subtitle1"
                                    key={iDiagnostico}
                                  >
                                    • {diagnostico.Name}
                                  </Typography>
                                );
                              }
                            )
                          ) : (
                            <Typography>• No hay diagnosticos</Typography>
                          )}
                        </Paper>
                        {receta.medicamentos?.map(
                          (medicamento, iMedicamento) => {
                            return (
                              <>
                                <Typography variant="subtitle1">
                                  • {medicamento.f0_}
                                </Typography>
                                <Typography variant="body2" paragraph>
                                  {medicamento.permiteSustitucion.toLowerCase() ===
                                  "s" ? (
                                    <>Permite sustitución</>
                                  ) : (
                                    <>No permite sustitución</>
                                  )}{" "}
                                  / Cantidad de envases:{" "}
                                  {medicamento.cantidadEnvases}
                                </Typography>
                                <Typography variant="subtitle1">
                                  • Indicaciones
                                </Typography>
                                <Typography paragraph variant="body2">
                                  {medicamento.indicaciones}
                                </Typography>
                                <Divider style={{marginBottom: 10}} />
                              </>
                            );
                          }
                        )}
                        <Card elevation={0}>
                          <CardHeader
                            title={
                              receta.tratamientoProlongado === "true" ? (
                                <Typography>
                                  Esta receta es un tratamiento prolongado.
                                </Typography>
                              ) : (
                                <Typography>
                                  Esta receta no es un tratamiento prolongado.
                                </Typography>
                              )
                            }
                            // action={
                            //   puedePedirFarmacia && receta.idReceta ? (
                            //     <SolicitarFarmaciaBtn
                            //       locationData={locationData!}
                            //       areaSupported={areaSupportsPharmacy!}
                            //       idReceta={receta.idReceta!}
                            //       usuario={cuilPacienteSeleccionado.toString()}
                            //       sesion={elementoHistorial.id!}
                            //       email={email}
                            //       estadoReceta={receta.estado!}
                            //       setEstadoReceta={setEstadoReceta}
                            //     />
                            //   ) : null
                            // }
                          />
                        </Card>
                      </CardContent>
                    </Card>
                  );
                })}
              </TabPanel>
              <TabPanel value={tabSeleccionada} index={4} dir={theme.direction}>
                <Typography
                  variant="h6"
                  paragraph
                >{`Interconsultas`}</Typography>
                <Grid container>
                  {elementoHistorial.interconsultas?.map((cons, iCons) => {
                    const clickedFile = () => {
                      window.open(
                        cons.url!,
                        `Interconsulta con ${cons.especialidad}`
                      );
                    };
                    return (
                      <Grid item xs={12} key={iCons}>
                        <Card>
                          <CardHeader
                            avatar={<Avatar>{iCons + 1}</Avatar>}
                            // action={<IconButton onClick={clickedFile}><CloudDownloadOutlined /></IconButton>}
                            title={`Interconsulta con ${cons.especialidad}`}
                          />
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </CardContent>
      </Card>
      <p />
      {elementoHistorial.archivos.length > 0 ? (
        <Card square>
          <CardHeader
            title={<Typography variant="h6">Archivos del Paciente</Typography>}
          />
          <CardContent>
            <Grid container item xs={12}>
              {elementoHistorial.archivos.map((archivo, indexArchivo) => {
                let mostrarLink = true;
                if (
                  archivo.linkArchivo === "-Archivo no encontrado-" ||
                  archivo.linkArchivo === null ||
                  archivo.linkArchivo === undefined
                )
                  mostrarLink = false;

                return (
                  <Grid item xs={12} md={6}>
                    <CardArchivoSubido
                      canDelete={false}
                      fileName={archivo.file}
                      description={archivo.descripcion}
                      downloadLink={
                        mostrarLink ? archivo.linkArchivo : undefined
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="body1">
          No hay archivos subidos por el paciente en esta consulta.
        </Typography>
      )}
      <p />
      {elementoHistorial.subidoProfesional.length > 0 ? (
        <Card square>
          <CardHeader
            title={
              <Typography variant="h6">Archivos del Profesional</Typography>
            }
          />
          <CardContent>
            <Grid container item xs={12}>
              {elementoHistorial.subidoProfesional.map(
                (archivo, indexArchivo) => {
                  let mostrarLink = true;
                  if (
                    archivo.linkArchivo === "-Archivo no encontrado-" ||
                    archivo.linkArchivo === null ||
                    archivo.linkArchivo === undefined
                  )
                    mostrarLink = false;

                  return (
                    <Grid item xs={12} md={6}>
                      <CardArchivoSubido
                        canDelete={false}
                        fileName={archivo.file}
                        description={archivo.descripcion}
                        downloadLink={
                          mostrarLink ? archivo.linkArchivo : undefined
                        }
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="body1">
          No hay archivos subidos por el profesional en esta consulta.
        </Typography>
      )}
      <p />
      {elementoHistorial.documentacion.length > 0 ? (
        <Card square>
          <CardHeader
            title={<Typography variant="h6">Documentacion</Typography>}
          />
          <CardContent>
            <Grid container item xs={12}>
              {elementoHistorial.documentacion.map((archivo, indexArchivo) => {
                let mostrarLink = true;
                if (
                  archivo.linkArchivo === "-Archivo no encontrado-" ||
                  archivo.linkArchivo === null ||
                  archivo.linkArchivo === undefined
                )
                  mostrarLink = false;

                return (
                  <Grid item xs={12} md={6}>
                    <CardArchivoSubido
                      canDelete={false}
                      fileName={archivo.file}
                      description={archivo.descripcion}
                      downloadLink={
                        mostrarLink ? archivo.linkArchivo : undefined
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="body1">
          No hay documentos en esta consulta.
        </Typography>
      )}
    </>
  );
};

export default ElementoHistoriaClinicaContent as React.ComponentType<PublicProps>;
