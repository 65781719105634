import axios from "axios";
import firebase from "firebase";
import {IArchivoSubido} from "../Interfaces/IArchivoSubido";
import {IEventoCalendario} from "../Interfaces/IEventoCalendario";
import {IPostEventoCalendario} from "../Interfaces/IPostEventoCalendario";
import {IResultadoListaEventosCalendario} from "../Interfaces/IResultadoListaEventosCalendario";

const calendarioApi = axios.create({
  baseURL: process.env.REACT_APP_URL_PACIENTES,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

calendarioApi.interceptors.request.use(
  async (config) => {
    let token: string | null | undefined = localStorage.getItem("token");
    if (!token) {
      token = await firebase.auth().currentUser?.getIdToken();
    }
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
calendarioApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Error 401");
      console.log("calendarioApi");
      localStorage.setItem("LoggedIn", "false");
    }
    return error;
  }
);

export async function fetchEventosCalendario(
  mailProfesional: string
): Promise<IResultadoListaEventosCalendario> {
  const {data} = await calendarioApi.get<IResultadoListaEventosCalendario>(
    "/obtenerEventosCalendario?mail=" + mailProfesional
  );
  return data;
}

export async function postEventoCalendario(
  evento: IPostEventoCalendario
): Promise<IEventoCalendario> {
  const {data} = await calendarioApi.post<IEventoCalendario>(
    "/crearEventoCalendario",
    evento
  );
  return data;
}

export async function postArchivo(
  archivo: File,
  idSalaVideollamada: string,
  cuilPacienteSeleccionado: string,
  descripcion: string
): Promise<string> {
  let formData = new FormData();
  formData.append("file", archivo);
  formData.append("idSalaVideollamada", idSalaVideollamada);
  formData.append("cuilPacienteSeleccionado", cuilPacienteSeleccionado);
  formData.append("descripcion", descripcion);
  const {data} = await calendarioApi.post<string>("/cargarArchivo", formData);
  return data;
}
export async function getArchivosSubidos(
  usuario: string,
  sesion: string
): Promise<Array<IArchivoSubido>> {
  const {data} = await calendarioApi.get<Array<IArchivoSubido>>(
    `/obtenerArchivosSesion?usuario=${usuario}&sesion=${sesion}`
  );
  return data;
}

export async function getFeriados(año: string) {
  const {data} = await calendarioApi.get<any>(`/getFeriados?año=${año}`);
  return data;
}

//export default pacientesApi;
