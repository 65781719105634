import React from "react";
import {useSelector} from "react-redux";
import {Route, Redirect} from "react-router-dom";

import {isLogin} from "../../utils/helper";

const PublicRoute = ({component: Component, ...rest}) => {
  const {isUserLogin} = useSelector((state) => state.user);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin(isUserLogin) ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
