import DateFnsUtils from "@date-io/date-fns";
import {
  Dialog,
  DialogTitle,
  Toolbar,
  Typography,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import React from "react";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {IProfesional} from "../../../../../Interfaces/IProfesional";
import {
  convertTimestringToDate,
  convertDateToTimestring,
  convertDateToLocaleLongDateOnly,
} from "../../../../../utils/dateTimeHelper";

interface OwnProps {
  onHabilitarSiguiente: (habilitado: boolean) => void;
  siguienteHabilitado: boolean;
  onSiguienteClicked: () => void;
  open: boolean;
  onClose: () => void;
  listadoMedicos: Array<IProfesional>;
  loading: boolean;
  profesionalSobreturno: string | null;
  setProfesionalSobreturno: (profNuevo: string | null) => void;
  horarioSobreturno: Date | null;
  setHorarioSobreturno: (horarioNuevo: Date | null) => void;
  fechaSeleccionada: Date | undefined;
}

function DialogSobreturnos(props: OwnProps) {
  const {
    open,
    onClose,
    listadoMedicos,
    loading,
    profesionalSobreturno,
    setProfesionalSobreturno,
    horarioSobreturno,
    setHorarioSobreturno,
    onHabilitarSiguiente,
    siguienteHabilitado,
    onSiguienteClicked,
    fechaSeleccionada,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h6">Sobreturnos</Typography>
            <div style={{flexGrow: 1}} />
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Toolbar>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{margin: "30px 0px 30px 0px"}}>
              <LoadingBubbles />
            </div>
          ) : (
            <>
              <DialogContentText style={{padding: "0px 30px 0px 30px"}}>
                <Typography>
                  Agregar sobreturno en la fecha seleccionada:
                </Typography>
                <Typography>
                  {fechaSeleccionada
                    ? convertDateToLocaleLongDateOnly(fechaSeleccionada!)
                    : null}
                </Typography>
              </DialogContentText>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  padding: "0px 30px 0px 30px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Autocomplete
                      // disablePortal
                      options={listadoMedicos}
                      getOptionLabel={(option) =>
                        option.profesional.split(":")[0]
                      }
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          {...params}
                          label="Profesional"
                        />
                      )}
                      value={listadoMedicos.find(
                        (x) => x.email === profesionalSobreturno
                      )}
                      onChange={(event, newValue) => {
                        setProfesionalSobreturno(
                          newValue ? newValue!.email : null
                        );
                        if (horarioSobreturno) {
                          onHabilitarSiguiente(true);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <KeyboardTimePicker
                      minutesStep={5}
                      orientation="landscape"
                      variant="inline"
                      label="Horario"
                      value={horarioSobreturno}
                      onChange={(hora) => {
                        setHorarioSobreturno(hora as Date);
                        if (profesionalSobreturno) {
                          onHabilitarSiguiente(true);
                        }
                      }}
                      ampm={false}
                      inputVariant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!siguienteHabilitado}
            onClick={onSiguienteClicked}
            variant="contained"
            disableElevation
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}

export default DialogSobreturnos;
