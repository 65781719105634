import React, {useEffect, useState} from "react";
import {
  List,
  ListItem,
  Divider,
  WithStyles,
  withStyles,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  ListItemText,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {styles} from "./ListadoMedicosStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {
  CalendarTodayOutlined,
  SettingsOutlined,
  SearchOutlined,
} from "@material-ui/icons";
import {IProfesional} from "../../../../Interfaces/IProfesional";
import {obtenerListaProfesionales} from "../../../../apis/adminAPI";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {useDispatch} from "react-redux";
import {setEspecialidades} from "../../../../store/administrador/administrar-consultorios-medicos/administrarConsultoriosMedicosSlice";
import {obtenerListaProfesionalesFinochietto} from "../../../../apis/finochiettoAPI";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";

const ListadoMedicos: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const [listadoTotalMedicos, setListadoTotalMedicos] = useState<
    Array<IProfesional>
  >([]);
  const [listadoFiltradoMedicos, setListadoFiltradoMedicos] = useState<
    Array<IProfesional>
  >([]);
  const dispatch = useDispatch();

  const {rol} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const getListadoProfesionales =
      rol === "ADMINISTRADOR FINOCHIETTO"
        ? obtenerListaProfesionalesFinochietto
        : obtenerListaProfesionales;

    setLoading(true);
    getListadoProfesionales()
      .then((datosObtenidos) => {
        const datosObtenidosSorted = datosObtenidos.sort((a, b) => {
          let configurarProgramadaA = false;
          a.especialidad!.forEach((especialidad) => {
            if (especialidad.consultaProgramada) configurarProgramadaA = true;
          });
          let configurarProgramadaB = false;
          b.especialidad!.forEach((especialidad) => {
            if (especialidad.consultaProgramada) configurarProgramadaB = true;
          });
          if (configurarProgramadaA !== configurarProgramadaB) {
            if (configurarProgramadaA) {
              return -1;
            } else {
              return 1;
            }
          } else {
            const [nombreMedicoA, matriculaA] = a.profesional.split(":");
            const [nombreMedicoB, matriculaB] = b.profesional.split(":");

            return nombreMedicoA.localeCompare(nombreMedicoB);
          }
        });
        setListadoTotalMedicos(datosObtenidosSorted);
        setListadoFiltradoMedicos(
          datosObtenidosSorted.filter((x) => {
            if (rol === "ADMINISTRADOR") {
              if (x.email) {
                const dominio = x.email!.split("@")[1].toLowerCase();
                if (dominio === "camdoctor2.medife.com.ar") {
                  return true;
                }
              }
              return false;
            } else if (rol === "ADMINISTRADOR FINOCHIETTO") {
              //no hay filtros para los de finochietto
              return true;
            }
          })
        );
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const acciones = [
    {
      enabled: true,
      tag: "Agenda actual",
      icon: <CalendarTodayOutlined />,
      to: "/dashboard/admin/administrar-consultorios-medicos/configurar-agenda?agenda=presente&medico=",
    },
    {
      enabled: true,
      tag: "Agenda Futura",
      icon: <CalendarTodayOutlined />,
      to: "/dashboard/admin/administrar-consultorios-medicos/configurar-agenda?agenda=futura&medico=",
    },
    {
      enabled: true,
      tag: "Agenda y Sobreturnos",
      icon: <CalendarTodayOutlined />,
      to: "/dashboard/admin/administrar-consultorios-medicos/ver-agenda?medico=",
    },
  ];

  if (loading) {
    return (
      <div style={{marginTop: "40vh"}}>
        <LoadingBubbles />
      </div>
    );
  }
  return (
    <>
      <Card style={{flexGrow: 1, marginBottom: 20}}>
        <CardHeader
          avatar={
            <Avatar>
              <SettingsOutlined />
            </Avatar>
          }
          title={<Typography variant="h6">Configuración de Agendas</Typography>}
          action={
            <TextField
              aria-label="filtro-medicos"
              name="filtro-medicos"
              variant="outlined"
              label="Filtrar Profesionales"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              style={{margin: 10}}
              onChange={(event) => {
                let listadoTemp: Array<IProfesional> = [];
                const filtro: string = event.target.value;
                if (filtro === "") {
                  listadoTemp = [...listadoTotalMedicos];
                } else {
                  listadoTotalMedicos.forEach((medico, indexMedico) => {
                    const [nombre, matricula] = medico.profesional.split(":");
                    const email = medico.email;
                    if (medico.email) {
                      const dominio = medico.email!.split("@")[1].toLowerCase();
                      if (dominio === "camdoctor2.medife.com.ar") {
                        if (
                          nombre.toLowerCase().includes(filtro.toLowerCase())
                        ) {
                          listadoTemp.push(medico);
                        } else if (matricula.includes(filtro)) {
                          listadoTemp.push(medico);
                        } else if (
                          email &&
                          email.toLowerCase().includes(filtro.toLowerCase())
                        ) {
                          listadoTemp.push(medico);
                        }
                      }
                    }
                  });
                }

                setListadoFiltradoMedicos(listadoTemp);
              }}
            />
          }
        />
        <Divider />
        <CardContent
          style={{backgroundColor: theme.paletaColores.fondo.segundo}}
        >
          <Card style={{flexGrow: 1, marginBottom: 20}}>
            <CardContent>
              <List>
                {listadoFiltradoMedicos.map((medico, index) => {
                  let puedeConfigurarProgramada = false;
                  medico.especialidad!.forEach((especialidad) => {
                    if (especialidad.consultaProgramada)
                      puedeConfigurarProgramada = true;
                  });

                  const [nombreMedico, matricula] =
                    medico.profesional.split(":");
                  return (
                    <ListItem
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? "white"
                            : theme.paletaColores.fondo.segundo,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            style={{color: theme.paletaColores.naranja.tercero}}
                          >
                            {nombreMedico}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography>{`Matricula: ${matricula}`}</Typography>
                            {medico.email && (
                              <Typography>{`${medico.email}`}</Typography>
                            )}
                          </>
                        }
                      />
                      <div>
                        {acciones.map((accion, indexAccion) => {
                          return (
                            <div key={indexAccion}>
                              <Button
                                size="small"
                                variant="contained"
                                disableElevation
                                style={{margin: 5}}
                                disabled={!puedeConfigurarProgramada}
                                onClick={() => {
                                  dispatch(
                                    setEspecialidades(medico.especialidad)
                                  );
                                  history.push(`${accion.to}${medico.email}`);
                                }}
                                startIcon={accion.icon}
                                fullWidth
                              >
                                {accion.tag}
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <div className={classes.buttonsRoot}>
        <Volver
          onClickHandler={() => {
            history.goBack();
          }}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(ListadoMedicos);
