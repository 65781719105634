import {
  Grid,
  Typography,
  useTheme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {
  getDiasLibresMedico,
  obtenerTurnosMedicoDia,
} from "../../../apis/citaProgramadaAPI";
import InteractiveCalendar from "../../../components/InteractiveCalendar/InteractiveCalendar";
import {ITurnoAgendado} from "../../../Interfaces/ITurnoAgendado";
import ring from "../../../../src/assets/sfx/notif_medico_prog.wav";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/rootReducer";
import {
  convertDateToDatestring,
  convertDiaHoraToDate,
} from "../../../utils/dateTimeHelper";

interface Props {
  fechaSeleccionada: Date;
  onChangeDate: (date: Date) => void;
}

const CalendarMedico = ({fechaSeleccionada, onChangeDate}: Props) => {
  //const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date())
  const {email} = useSelector((state: RootState) => state.user);
  const [fechasLibres, setFechasLibres] = useState<Array<string>>([]);

  const handleChange = (date: Date) => {
    //setFechaSeleccionada(date);
    onChangeDate(date);
  };

  useEffect(() => {
    getDiasLibresMedico(email)
      .then((res) => {
        setFechasLibres(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <InteractiveCalendar
      minDate={new Date()}
      value={fechaSeleccionada}
      onChange={(newValue) => {
        handleChange(newValue as Date);
      }}
      tileDisabled={(props: {date: Date; view: string}) => {
        const dateString = convertDateToDatestring(props.date);
        if (fechasLibres && fechasLibres.find((x) => x === dateString)) {
          return false;
        }
        return true;
      }}
    />
  );
};

export default CalendarMedico;
