import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IEventoCalendario} from "../../../Interfaces/IEventoCalendario";

interface IGuardiaMedicoState {
  proximoEvento: IEventoCalendario | null;
  idSalaVideollamada: string;
  passSalaVideollamada: string;
  cuilPacienteSeleccionado: number | null;
  especialidad: string;
}

let initialState: IGuardiaMedicoState = {
  proximoEvento: null,
  idSalaVideollamada: "",
  passSalaVideollamada: "",
  cuilPacienteSeleccionado: null,
  especialidad: "",
};

const guardiaMedicoSlice = createSlice({
  name: "guardiaMedico",
  initialState,
  reducers: {
    setState(state, {payload}: PayloadAction<IGuardiaMedicoState | null>) {
      if (payload) {
        state.proximoEvento = payload.proximoEvento;
      } else {
        state.proximoEvento = initialState.proximoEvento;
      }
    },
    setProximoEvento(state, action: PayloadAction<IEventoCalendario>) {
      state.proximoEvento = action.payload;
    },
    setIdSalaVideollamada(state, action: PayloadAction<string>) {
      state.idSalaVideollamada = action.payload;
    },
    setPassSalaVideollamada(state, action: PayloadAction<string>) {
      state.passSalaVideollamada = action.payload;
    },
    setCuilPacienteSeleccionado(state, action: PayloadAction<number>) {
      state.cuilPacienteSeleccionado = action.payload;
    },
    setEspecialidad(state, action: PayloadAction<string>) {
      state.especialidad = action.payload;
    },
  },
});

export const {
  setState,
  setProximoEvento,
  setIdSalaVideollamada,
  setCuilPacienteSeleccionado,
  setPassSalaVideollamada,
  setEspecialidad,
} = guardiaMedicoSlice.actions;

export default guardiaMedicoSlice.reducer;
