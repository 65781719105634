import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

//MATERIAL-UI
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import {Box, Divider, Grid} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";

import useGeolocation from "../../../../../hooks/useGeolocation";

import ring from "../../../../../assets/sfx/notif_paciente.wav";
import usuario from "../../../../../assets/images/usuario.png";
import {getCurrentTime} from "../../../../../hooks/useTime";

const ringAudio = new Audio(ring);

interface OwnProps {
  nombre: string;
  apellido: string;
  edad: number;
  financiador: string;
  credencial: string;
  onEntrarConsultorio: () => void;
}

const LlamadoConsultorioFinochietto = (props: OwnProps) => {
  const history = useHistory();
  const location = useGeolocation();
  const theme = useTheme();

  const {nombre, apellido, edad, financiador, credencial, onEntrarConsultorio} =
    props;

  const playSound = (audioFile: HTMLAudioElement) => {
    audioFile.play();
  };

  useEffect(() => {
    playSound(ringAudio);
  }, []);

  const servicios = [
    {
      id: "INGRESAR",
      primaryText: "Ingresar al consultorio",
      icon: VideocamOutlinedIcon,
      onClick: onEntrarConsultorio,
    },
    // {
    //     id: 'CANCELAR',
    //     primaryText: "Cancelar la consulta",
    //     icon: CancelOutlinedIcon,
    //     onClick: onCancelarCita
    // }
  ];

  const opcionesList = servicios.map((item, index) => (
    <Box key={index} borderBottom="1px solid #efefef">
      <ListItem
        style={{padding: "20px 10px 20px 10px"}}
        button
        onClick={item.onClick}
      >
        <ListItemIcon>
          <item.icon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary={item.primaryText} />
        <ListItemSecondaryAction onClick={item.onClick}>
          <IconButton edge="end">
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  ));

  // const Ubicacion = () => {
  //     let latitud = undefined;
  //     let longitud = undefined;
  //     navigator.geolocation.getCurrentPosition(
  //         (geoData) => {
  //             latitud = geoData.coords.latitude;
  //             longitud = geoData.coords.longitude;
  //             setUbicacion({ latitud: latitud, longitud: longitud });
  //         }
  //     )
  //     return (
  //         <>
  //             <Typography>
  //                 {latitud}
  //             </Typography>
  //             <Typography>
  //                 {longitud}
  //             </Typography>
  //         </>
  //     )
  // }

  if (!location.loaded) {
    return (
      <>
        <LoadingBubbles />
        <Box
          paddingY={2}
          borderBottom="1px solid #efefef"
          style={{marginTop: "30px"}}
        >
          <Typography variant="h5" style={{textAlign: "center"}}>
            Por favor, permitanos acceder a su ubicacion.
          </Typography>
        </Box>
      </>
    );
  }

  if (location.error !== undefined) {
    console.log(location);
    return (
      <Box
        paddingY={2}
        borderBottom="1px solid #efefef"
        style={{marginTop: "30vh"}}
      >
        <Typography variant="h5" style={{textAlign: "center"}}>
          {location.error!.code === 0
            ? "Por favor actualice su navegador."
            : location.error!.code === 1
            ? "Usted ha bloqueado el acceso a su ubicacion.\nPor favor, permitalo y actualice la página."
            : location.error!.message}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Divider />
      <Box my={3}>
        <Grid container alignItems="center">
          <Grid item>
            <Box mx={3}>
              <img src={usuario} alt="paciente" width="130px" />
            </Box>
          </Grid>
          <Grid item>
            <p style={{color: theme.paletaColores.negro.finochietto}}>
              <b>{nombre + " " + apellido}</b>
            </p>
            <p>{edad} años</p>
            <p>OBRA SOCIAL: {financiador}</p>
            <p>CREDENCIAL: {credencial}</p>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <h2>Ha sido llamado al consultorio</h2>
      <Divider />
      <Grid item xs={12} sm={10} md={7} lg={5}>
        <List>{opcionesList}</List>
      </Grid>
      <div style={{marginTop: 20, marginBottom: 20, height: 20}}></div>
    </>
  );
};

export default LlamadoConsultorioFinochietto;
