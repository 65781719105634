import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import ListadoSintomas from "../../../pages/Administrador/AdministrarSintomas/ListadoSintomas/ListadoSintomas";

const AdministrarSintomas = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/listado-sintomas`} exact />
      <PrivateRoute
        component={ListadoSintomas}
        path={`${path}/listado-sintomas`}
      />
    </Switch>
  );
};

export default AdministrarSintomas;
