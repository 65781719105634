import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import React from "react";
import LoadingBubbles from "../../../../../components/LoadingBubbles/LoadingBubbles";
import {IEspecialidad} from "../../../../../Interfaces/IEspecialidad";

interface OwnProps {
  especialidades: Array<IEspecialidad>;
  onChangeEspecialidad: (especialidad: IEspecialidad) => void;
  especialidad: IEspecialidad | undefined;

  onHabilitarSiguiente: (habilitado: boolean) => void;
  siguienteHabilitado: boolean;
  loading: boolean;
}

function SeleccionEspecialidad(props: OwnProps) {
  const {
    especialidad,
    especialidades,
    siguienteHabilitado,
    onChangeEspecialidad,
    onHabilitarSiguiente,
    loading,
  } = props;

  if (loading) {
    return (
      <div>
        <LoadingBubbles />
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs />
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Especialidad</InputLabel>
          <Select
            label="Especialidad"
            value={especialidad?.especialidadId}
            onChange={(event) => {
              const seleccionada = especialidades.find(
                (x) => x.especialidadId === (event.target.value as number)
              );
              onChangeEspecialidad(seleccionada!);
              if (event.target.value) {
                onHabilitarSiguiente(true);
              }
            }}
          >
            {especialidades.map((esp, iEsp) => {
              return (
                <MenuItem value={esp.especialidadId} key={iEsp}>
                  {esp.descripcion}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs />
    </Grid>
  );
}

export default SeleccionEspecialidad;
