import {
  Card,
  CardContent,
  Grid,
  Link,
  Toolbar,
  Typography,
  WithStyles,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import LoadingBubbles from "../../../components/LoadingBubbles/LoadingBubbles";
import {styles} from "./TurnosDiaMedicoStyles";

interface OwnProps {
  period: "mes" | "año";
  cantidadPacientes: Number;
  Icon: React.ComponentType<any>;
  loading: boolean;
}

type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;

const SectionResumenPaciente: React.FC<Props> = (props) => {
  const {classes, period, cantidadPacientes, Icon, loading} = props;
  const theme = useTheme();

  return (
    <>
      <Grid item container>
        <Card
          style={{
            flexGrow: 1,
            backgroundColor: theme.paletaColores.fondo.primero,
          }}
        >
          <CardContent className={classes.cardHome}>
            <Toolbar
              variant="dense"
              style={{
                padding: 0,
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className={classes.iconResumenContainer}>
                <Icon
                  className={classes.iconResumen}
                  style={{
                    backgroundColor:
                      period === "mes"
                        ? theme.paletaColores.naranja.segundo
                        : theme.paletaColores.negro.primero,
                    padding: period === "año" ? "10px 0" : 0,
                  }}
                />
              </div>
              <Typography variant="subtitle1" style={{margin: "10px 0"}}>
                Este {period} <b>atendiste</b>
              </Typography>
            </Toolbar>
            <Typography
              variant="h4"
              style={{
                color: theme.paletaColores.negro.primero,
                fontWeight: "bold",
              }}
            >
              {loading ? <LoadingBubbles size={42} /> : cantidadPacientes}
            </Typography>
            <Typography variant="body2">pacientes</Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default withStyles(styles)(
  SectionResumenPaciente
) as React.ComponentType<PublicProps>;
