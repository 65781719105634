import React, {useState} from "react";
import {
  Card,
  CardHeader,
  IconButton,
  Avatar,
  CardActions,
  Button,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  PhotoOutlined,
  PictureAsPdfOutlined,
} from "@material-ui/icons";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

interface OwnProps {
  fileName: string;
  description: string;
  downloadLink?: string;
  canDelete?: boolean;
  onDelete?: () => void;
}

type PublicProps = OwnProps;
type Props = PublicProps;

const CardArchivoSubido: React.FC<Props> = (props) => {
  const {fileName, description, downloadLink, canDelete, onDelete} = props;
  const icono = fileName.endsWith(".pdf") ? (
    <PictureAsPdfOutlined />
  ) : (
    <PhotoOutlined />
  );
  const [deleteOpen, setDeleteOpen] = useState(false);

  const clickedFile = () => {
    window.open(downloadLink, description);
  };

  return (
    <Card style={{margin: "2%", width: "96%"}} elevation={4}>
      <CardActionArea onClick={clickedFile}>
        <CardHeader
          avatar={<Avatar>{icono}</Avatar>}
          title={
            <span style={{maxWidth: "50%", textOverflow: "ellipsis"}}>
              {fileName}
            </span>
          }
          subheader={
            <span style={{maxWidth: "50%", textOverflow: "ellipsis"}}>
              {description}
            </span>
          }
        />
      </CardActionArea>
      <CardActions>
        <>
          {/*
                        downloadLink ?
                            <Button
                                href={downloadLink}
                                target="_blank"
                                startIcon={<CloudDownloadOutlined />}
                                variant="contained"
                                disableElevation
                                color="primary"
                            >
                                Descargar
                            </Button>
                            : null
                    */}
          {canDelete ? (
            <Button
              onClick={() => {
                setDeleteOpen(true);
              }}
              variant="contained"
              color="default"
              disableElevation
              startIcon={<DeleteOutlined />}
              size="small"
              fullWidth
            >
              Eliminar
            </Button>
          ) : null}
        </>
      </CardActions>
      <ConfirmationDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title="Eliminar archivo"
        onConfirm={onDelete!}
      >
        <Typography>Está seguro de eliminar el archivo {fileName}?</Typography>
      </ConfirmationDialog>
    </Card>
  );
};

export default CardArchivoSubido as React.ComponentType<PublicProps>;
