import React from "react";
import {useHistory} from "react-router-dom";
//MATERIAL-UI
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ThemeProvider,
} from "@material-ui/core";

import {IServicio} from "../../../Interfaces/IServicio";
import adminFinochietto1 from "../../../assets/images/adminFinochietto1.jpg";
import {
  HistoryOutlined,
  ScheduleOutlined,
  WorkOutlineOutlined,
} from "@material-ui/icons";
import {createThemeFinochietto} from "../../../theme/AppTheme";

const servicios: ReadonlyArray<IServicio> = [
  {
    id: "GESTIONAR_CONSULTORIOS",
    primaryText: "Gestionar consultorios médicos",
    secondaryText: "Manejar la modalidad de atención. Facturación Unificada",
    icon: WorkOutlineOutlined,
    to: "/dashboard/admin/administrar-consultorios-medicos",
  },
  {
    id: "GESTIONAR_HISTORIAL",
    primaryText: "Historial de Consultas",
    secondaryText: "Buscar pacientes y consultar su historial médico",
    icon: HistoryOutlined,
    to: "/dashboard/admin/administrar-historial-offline",
  },
];

const themeFinochietto = createThemeFinochietto();

const WelcomeAdminFinochietto = () => {
  const history = useHistory();

  const onItemClicked = (servicio: IServicio) => {
    history.push(servicio.to);
  };

  const serviciosList = servicios.map((servicio, index) => (
    <Box key={index} borderBottom="1px solid #efefef">
      <ListItem
        button
        style={{paddingTop: 20, paddingBottom: 20}}
        onClick={() => onItemClicked(servicio)}
      >
        <ListItemIcon>
          <servicio.icon fontSize="large" />
        </ListItemIcon>
        <ListItemText
          primary={servicio.primaryText}
          secondary={servicio.secondaryText}
        />
        <ListItemSecondaryAction onClick={() => onItemClicked(servicio)}>
          <IconButton edge="end">
            <ArrowForwardIosIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  ));

  return (
    <ThemeProvider theme={themeFinochietto}>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12} md={7} lg={7}>
          <List>{serviciosList}</List>
        </Grid>
        <Grid item xs={10} md={5} lg={5}>
          <img
            style={{borderRadius: "30px 0px 30px 0px"}}
            src={adminFinochietto1}
            alt="imagen administrador finochietto"
            width="100%"
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default WelcomeAdminFinochietto;
