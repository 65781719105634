import React from "react";
import {styles} from "./InteractiveCalendarStyles";
import Calendar, {
  CalendarTileProperties,
  DateCallback,
  OnChangeDateCallback,
} from "react-calendar";
import "./Calendar.css";
import {withStyles, WithStyles} from "@material-ui/styles";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";

interface OwnProps {
  // paciente: IPaciente;
  // onClickHandler?: () => void;
  tileDisabled?: (
    props: CalendarTileProperties & {activeStartDate: Date}
  ) => boolean;
  minDate?: Date;
  maxDate?: Date;
  value?: Date | Array<Date>;
  onChange?: OnChangeDateCallback;
  selectRange?: boolean;
  allowPartialRange?: boolean;
  onClickDay?: DateCallback;
  dateHighlighted?: (date: Date) => boolean;
}

type PublicProps = OwnProps;
type Props = PublicProps & WithStyles<typeof styles>;
const InteractiveCalendar: React.FC<Props> = (props) => {
  const {
    classes,
    tileDisabled,
    minDate,
    maxDate,
    value,
    onChange,
    selectRange,
    allowPartialRange,
    onClickDay,
    dateHighlighted,
  } = props;
  return (
    <div
      style={{marginTop: 15, alignContent: "center", justifyContent: "center"}}
    >
      <Calendar
        locale="es-AR"
        next2Label={null}
        prev2Label={null}
        minDetail="month"
        prevLabel={<ArrowBackIos fontSize="small" />}
        nextLabel={<ArrowForwardIos fontSize="small" />}
        showNeighboringMonth={false}
        tileDisabled={tileDisabled}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={onChange}
        selectRange={selectRange}
        allowPartialRange={allowPartialRange}
        onClickDay={onClickDay}
        tileClassName={(fecha) => {
          if (dateHighlighted !== undefined) {
            if (dateHighlighted!(fecha.date)) {
              return "highlight";
            }
          }
          return "";
        }}
        // tileDisabled={({ date, view }) =>
        //     disabledDates.some(disabledDate =>
        //         date.getFullYear() === disabledDate.getFullYear() &&
        //         date.getMonth() === disabledDate.getMonth() &&
        //         date.getDate() === disabledDate.getDate()
        //     )}
      />
    </div>
  );
};

export default withStyles(styles)(
  InteractiveCalendar
) as React.ComponentType<PublicProps>;
