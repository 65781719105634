import { createMuiTheme } from "@material-ui/core";
import { orange, blue } from "@material-ui/core/colors";

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    paletaColores: {
      negro: {
        primero: string;
        segundo: string;
        tercero: string;
        cuarto: string;
        quinto: string;
        finochietto: string;
      }
      naranja: {
        primero: string;
        segundo: string;
        tercero: string;
        cuarto: string;
        finochietto: string;
      },
      fondo: {
        primero: string;
        segundo: string;
        tercero: string;
        cuarto: string;
        quinto: string;
        finochietto: string;
      },
      error: string;
    },
    buttons: {
      okButton: ButtonOptions;
      okDisabledButton: ButtonOptions;
      cancelButton: ButtonOptions;
      cancelDisabledButton: ButtonOptions;
    }
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    paletaColores: {
      negro: {
        primero: string;
        segundo: string;
        tercero: string;
        cuarto: string;
        quinto: string;
        finochietto: string;
      },
      naranja: {
        primero: string;
        segundo: string;
        tercero: string;
        cuarto: string;
        finochietto: string;
      },
      fondo: {
        primero: string;
        segundo: string;
        tercero: string;
        cuarto: string;
        quinto: string;
        finochietto: string;
      },
      error: string;
    },
    buttons: {
      okButton: ButtonOptions;
      okDisabledButton: ButtonOptions;
      cancelButton: ButtonOptions;
      cancelDisabledButton: ButtonOptions;
    }
  }
  interface ButtonOptions {
    backgroundColor: string;
    color: string;
  }
}

export function createThemeFinochietto() {
  return createMuiTheme({
    palette: {
      primary: {
        main: '#435363',
      },
      secondary: {
        main: '#E65300',
      },
    },
    paletaColores: {
      negro: {//de oscuro a claro
        primero: '#1D1E1C',
        segundo: '#58575C',
        tercero: '#797978',
        cuarto: '#B2B2B1',
        quinto: '#F5F5F5',
        finochietto: '#435363',
      },
      naranja: {//de oscuro a claro
        primero: '#BE4801',
        segundo: '#EB5F0A',
        tercero: '#EF7F41',
        cuarto: '#fef9e5',
        finochietto: '#E65300',
      },
      fondo: {//de claro a oscuro
        primero: '#ffffff',
        segundo: '#F5F5F5',
        tercero: '#797978',
        cuarto: '#58575C',
        quinto: '#EB5F0A',
        finochietto: '#435363',
      },
      error: '#D60000'
    },
    typography: {
      fontFamily: [
        'Avenir LT Std Roman',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif'
      ].join(','),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1200,
      },
    },
    buttons: {
      okButton: {
        backgroundColor: '#EF7F41',
        color: '#FFFFFF'
      },
      okDisabledButton: {
        backgroundColor: '#fef9e5',
        color: '#FFFFFF'
      },
      cancelButton: {
        backgroundColor: '#797978',
        color: '#FFFFFF'
      },
      cancelDisabledButton: {
        backgroundColor: '#F5F5F5',
        color: '#FFFFFF'
      }
    }
  })
}

export default function createTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        light: '#fef9e5',
        main: '#EF7F41',
        dark: '#BE4801',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#fef9e5',
        main: '#EF7F41',
        dark: '#BE4801',
        contrastText: '#ffffff'
      },
    },
    paletaColores: {
      negro: {//de oscuro a claro
        primero: '#1D1E1C',
        segundo: '#58575C',
        tercero: '#797978',
        cuarto: '#B2B2B1',
        quinto: '#F5F5F5',
        finochietto: '#435363',
      },
      naranja: {//de oscuro a claro
        primero: '#BE4801',
        segundo: '#EB5F0A',
        tercero: '#EF7F41',
        cuarto: '#fef9e5',
        finochietto: '#E65300',
      },
      fondo: {//de claro a oscuro
        primero: '#ffffff',
        segundo: '#F5F5F5',
        tercero: '#797978',
        cuarto: '#58575C',
        quinto: '#EB5F0A',
        finochietto: '#435363',
      },
      error: '#D60000'
    },
    typography: {
      fontFamily: [
        'Syntax LT Std Roman',
        // 'Syntax LT Std Black',
        // 'Syntax LT Std Bold',
        // 'Syntax LT Std Italic',
        // 'Syntax LT Std UltraBlack',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif'
      ].join(','),
      // h1: {
      //   fontFamily: '"TitilliumWeb", Open Sans',
      //   fontSize
      //  }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1200,
      },
    },
    buttons: {
      okButton: {
        backgroundColor: '#EF7F41',
        color: '#FFFFFF'
      },
      okDisabledButton: {
        backgroundColor: '#fef9e5',
        color: '#FFFFFF'
      },
      cancelButton: {
        backgroundColor: '#797978',
        color: '#FFFFFF'
      },
      cancelDisabledButton: {
        backgroundColor: '#F5F5F5',
        color: '#FFFFFF'
      }
    }
  });
}

// XS: 320 a 575 (elastico)
// SM: 576 a 767
// ML: 768 a 1023
// L: 1024 a 1199
// XL: 1200  en adelante