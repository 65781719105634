import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import ConfiguracionHorarios from "../../../pages/Administrador/AdministrarHorarios/ConfiguracionHorarios/ConfiguracionHorarios";

const AdministrarHorarios = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/configurar-horarios`} exact />
      <PrivateRoute
        component={ConfiguracionHorarios}
        path={`${path}/configurar-horarios`}
      />
    </Switch>
  );
};

export default AdministrarHorarios;
