import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  Typography,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Select,
  MenuItem,
  CardActionArea,
} from "@material-ui/core";
import {ArrowForwardIos} from "@material-ui/icons";

import {ISintoma} from "../../Interfaces/ISintoma";
import {
  setSintomaGuardado,
  setPaso,
} from "../../store/paciente/ver-medico-online/verMedicoOnlineSlice";
import {ISintomaPreguntasRespuestas} from "../../Interfaces/ISintomaPreguntasRespuestas";

interface OwnProps {
  modeloSintoma: ISintoma;
}

type PublicProps = OwnProps;
type Props = PublicProps;

const CardSintoma: React.FC<Props> = (props) => {
  const {modeloSintoma} = props;
  const [respuestas, setRespuestas] = React.useState<Array<string>>([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (
    numeroRespuesta: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let aux = respuestas;
    aux[numeroRespuesta] = (event.target as HTMLInputElement).value;
    setRespuestas(aux);
  };
  const handleChangeSelect = (
    numeroRespuesta: number,
    event: React.ChangeEvent<{name?: string | undefined; value: unknown}>
  ) => {
    let aux = respuestas;
    aux[numeroRespuesta] = (event.target as HTMLInputElement).value;
    setRespuestas(aux);
  };

  const handleClickOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDialog = () => {
    let arraypreg: Array<{pregunta: string; respuesta: string}> = [];

    let i = 0;
    while (i < respuestas.length) {
      arraypreg.push({
        pregunta: modeloSintoma.preguntas[i].pregunta,
        respuesta: respuestas[i],
      });
      i++;
    }
    while (i < modeloSintoma.preguntas.length) {
      arraypreg.push({
        pregunta: modeloSintoma.preguntas[i].pregunta,
        respuesta: "",
      });
      i++;
    }

    const elegido: ISintomaPreguntasRespuestas = {
      sintoma: modeloSintoma.sintoma,
      preguntas: arraypreg,
    };

    dispatch(setSintomaGuardado(elegido));
    dispatch(setPaso(4));
    history.push(`/dashboard/ver-medico-online/confirmar`);
  };

  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card elevation={0} style={{background: "none"}}>
          <CardActionArea
            style={{minHeight: 80}}
            onClick={handleClickOpenDialog}
          >
            <CardHeader
              avatar={<Avatar src={modeloSintoma.imagen} />}
              title={
                <Typography variant="body1">{modeloSintoma.sintoma}</Typography>
              }
              action={
                <IconButton onClick={handleClickOpenDialog}>
                  <ArrowForwardIos />
                </IconButton>
              }
            />
          </CardActionArea>
        </Card>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {modeloSintoma.sintoma}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {modeloSintoma.nota ? (
              <Typography paragraph variant="body2">
                {" "}
                {modeloSintoma.nota}
              </Typography>
            ) : null}
            <Grid item container direction="column">
              {modeloSintoma.preguntas.map((pregunta, indexQuestion) => {
                return (
                  <Grid item>
                    <FormControl
                      key={indexQuestion}
                      fullWidth
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        {
                          <>
                            <Typography paragraph />
                            <Typography paragraph>
                              {pregunta.pregunta}
                            </Typography>
                          </>
                        }
                      </FormLabel>
                      {pregunta.opciones !== undefined &&
                      pregunta.opciones.length > 0 ? (
                        <>
                          {pregunta.opciones.length > 2 ? (
                            <Select
                              value={respuestas[indexQuestion]}
                              onChange={(event) => {
                                handleChangeSelect(indexQuestion, event);
                              }}
                            >
                              {pregunta.opciones?.map((opcion, indexOption) => {
                                return (
                                  <MenuItem key={indexOption} value={opcion}>
                                    {opcion}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          ) : (
                            <RadioGroup
                              aria-label="question"
                              name="question"
                              value={respuestas[indexQuestion]}
                              onChange={(event) => {
                                handleChange(indexQuestion, event);
                              }}
                            >
                              <Grid item container xs={12}>
                                {pregunta.opciones?.map(
                                  (opcion, indexOption) => {
                                    return (
                                      <Grid item xs={6}>
                                        <FormControlLabel
                                          key={indexOption}
                                          value={opcion}
                                          control={<Radio />}
                                          label={opcion}
                                        />
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </RadioGroup>
                          )}
                        </>
                      ) : (
                        <TextField
                          aria-label="question"
                          name="question"
                          value={respuestas[indexQuestion]}
                          onChange={(event) => {
                            handleChange(
                              indexQuestion,
                              event as React.ChangeEvent<HTMLInputElement>
                            );
                          }}
                        />
                      )}
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContentText>
          {/* <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                    /> */}
        </DialogContent>
        <DialogActions>
          <Button
            style={theme.buttons.cancelButton}
            variant="contained"
            disableElevation
            onClick={handleCloseDialog}
          >
            Cancelar
          </Button>
          <Button
            style={theme.buttons.okButton}
            variant="contained"
            disableElevation
            onClick={handleConfirmDialog}
            endIcon={<ArrowForwardIos />}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardSintoma as React.ComponentType<PublicProps>;
