import React, {useEffect, useState} from "react";
import {
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControl,
  InputLabel,
  Select,
  WithStyles,
  withStyles,
  useTheme,
  Grid,
  Typography,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Toolbar,
  FormControlLabel,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {styles} from "./VerAgendaSobreturnosStyles";
import Volver from "../../../../components/buttons/Volver/Volver";
import {
  CancelOutlined,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  TimerOutlined,
} from "@material-ui/icons";
import InteractiveCalendar from "../../../../components/InteractiveCalendar/InteractiveCalendar";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import {
  convertDateToDatestring,
  convertDiaHoraToDate,
} from "../../../../utils/dateTimeHelper";
import {useTime} from "../../../../hooks/useTime";
import {
  cargarSobreturno,
  getDiasLibresMedico,
  obtenerTurnosMedicoDia,
} from "../../../../apis/citaProgramadaAPI";
import {ITurnoAgendado} from "../../../../Interfaces/ITurnoAgendado";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {
  setCuilPacienteSeleccionado,
  setEspecialidad,
  setIdSalaVideollamada,
} from "../../../../store/profesional-medico/atender-consultorio/atenderConsultorioSlice";
import {isFriday} from "date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";

import ring from "../../../../assets/sfx/notif_medico_prog.wav";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import useQuery from "../../../../hooks/useQuery";
const ringAudio = new Audio(ring);

const VerAgendaSobreturnos: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const query = useQuery();
  const email = query.get("medico")!;

  const [citasAgendadas, setCitasAgendadas] = useState<
    Array<ITurnoAgendado | string>
  >([]);
  const [citasLoading, setCitasLoading] = useState(false);
  const [updateDummieState, setUpdateDummieState] = useState(false);

  const [horaSobreturnos, setHoraSobreturnos] = useState<Date | null>(null);

  const [verLibres, setVerLibres] = useState(true);
  const [fechasLibres, setFechasLibres] = useState<Array<string>>([]);

  const now = useTime(60000);

  function isITurnoAgendado(object: any): object is ITurnoAgendado {
    if (typeof object === "string") return false;
    return "sesion" in object;
  }

  useEffect(() => {
    getDiasLibresMedico(email)
      .then((res) => {
        setFechasLibres(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setCitasLoading(true);
    obtenerTurnosMedicoDia(email, fechaSeleccionada)
      .then((datosRecibidos) => {
        let playSound = false;
        citasAgendadas.forEach((y) => {
          if (isITurnoAgendado(y)) {
            const turno = y as ITurnoAgendado;
            let datoRecibido = datosRecibidos.turnosTomados.find(
              (x) => x.paciente === turno.paciente && x.sesion === turno.sesion
            );
            if (datoRecibido) {
              if (!turno.enEspera && datoRecibido!.enEspera) {
                playSound = true;
              }
            }
          }
        });
        if (playSound) {
          ringAudio.play();
          //reproducir sonido
        }
        setCitasLoading(false);
        datosRecibidos.turnosTomados.sort((a, b) => {
          return (
            convertDiaHoraToDate(a.turno).getTime() -
            convertDiaHoraToDate(b.turno).getTime()
          );
        });

        let datos: Array<ITurnoAgendado | string> = [
          ...datosRecibidos.turnosTomados,
          ...datosRecibidos.disponibles,
        ];
        datos.sort((a, b) => {
          const aIsObj = isITurnoAgendado(a);
          const bIsObj = isITurnoAgendado(b);

          const fechaBase = "1970/01/01";

          const horaA = aIsObj
            ? (a as ITurnoAgendado).turno.horas
            : (a as string);
          const horaB = bIsObj
            ? (b as ITurnoAgendado).turno.horas
            : (b as string);
          return (
            convertDiaHoraToDate({día: fechaBase, horas: horaA}).getTime() -
            convertDiaHoraToDate({día: fechaBase, horas: horaB}).getTime()
          );
        });
        setCitasAgendadas(datos);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [fechaSeleccionada, now, updateDummieState, email]);

  useEffect(() => {
    setHoraSobreturnos(null);
  }, [fechaSeleccionada]);

  const sendSobreturno = () => {
    cargarSobreturno(email, horaSobreturnos!, fechaSeleccionada)
      .then((datosRecibidos) => {
        setHoraSobreturnos(null);
      })
      .catch((e) => console.log(e));
  };

  const [especialidadFiltro, setEspecialidadFiltro] = useState<string>("");

  const turnosList = citasLoading ? (
    <>
      <LoadingBubbles />
    </>
  ) : (
    <>
      {citasAgendadas.length === 0 ? (
        <ListItem>
          <ListItemText primary="No tiene turnos para la fecha seleccionada" />
        </ListItem>
      ) : (
        <>
          {citasAgendadas.map((posibleTurno, indexTurno) => {
            if (isITurnoAgendado(posibleTurno)) {
              const turno = posibleTurno as ITurnoAgendado;
              if (
                especialidadFiltro !== "" &&
                turno.especialidad !== especialidadFiltro
              ) {
                return null;
              }
              const atendiendo = turno.estado!.toLowerCase() === "atendido";
              const cancelada = turno.estado!.toLowerCase() === "cancelada";
              const terminada = turno.estado!.toLowerCase() === "terminada";
              const vencida = turno.estado!.toLowerCase() === "vencida";

              let estadoTooltip = "";
              if (atendiendo)
                estadoTooltip = `Esta consulta esta siendo atendida`;
              else if (terminada) estadoTooltip = `Esta consulta ha finalizado`;
              else if (cancelada)
                estadoTooltip = `Esta consulta ha sido cancelada`;
              else if (vencida) estadoTooltip = `Esta consulta se ha vencido`;
              else estadoTooltip = `Consulta pendiente de ser atendida`;

              const puedeSerAtendido =
                (turno.estado!.toLowerCase() === "activa" ||
                  turno.estado!.toLowerCase() === "atendido") &&
                turno.enEspera;

              return (
                <ListItem
                  key={indexTurno}
                  disabled={cancelada || terminada || vencida}
                >
                  <ListItemIcon>
                    <Tooltip
                      arrow
                      title={<Typography>{estadoTooltip}</Typography>}
                    >
                      {terminada ? (
                        <CheckBoxOutlined />
                      ) : cancelada ? (
                        <CancelOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined />
                      )}
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${turno.turno.horas}`}
                    secondary={`${turno.nombrePaciente!}`}
                  />
                  <ListItemText
                    primary={`${turno.especialidad}`}
                    secondary={
                      turno.enEspera &&
                      !cancelada &&
                      !terminada &&
                      !atendiendo ? (
                        <span
                          style={{
                            color: theme.paletaColores.naranja.tercero,
                            fontWeight: "bold",
                          }}
                        >
                          Paciente en espera
                        </span>
                      ) : (
                        <>{atendiendo ? "Atendiendo" : `${turno.estado}`}</>
                      )
                    }
                  />
                  <ListItemIcon>
                    {turno.enEspera && !cancelada && !terminada && !atendiendo && (
                      <Tooltip
                        arrow
                        title={
                          <Typography>
                            Paciente esperando ser atendido
                          </Typography>
                        }
                      >
                        <TimerOutlined color="primary" />
                      </Tooltip>
                    )}
                  </ListItemIcon>
                </ListItem>
              );
            } else {
              const turno = posibleTurno as string;
              return verLibres ? (
                <ListItem key={indexTurno}>
                  <ListItemText primary={`${turno}`} secondary={`DISPONIBLE`} />
                </ListItem>
              ) : null;
            }
          })}
        </>
      )}
    </>
  );

  const [turnoSeleccionado, setTurnoSeleccionado] = useState<
    ITurnoAgendado | undefined
  >(undefined);

  if (loading) {
    return (
      <div style={{marginTop: "40vh"}}>
        <LoadingBubbles />
      </div>
    );
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Typography variant="h5">Consultorio Virtual</Typography>
        <Grid container>
          <Grid item xs={12} md={5}>
            <div style={{marginBottom: 50}}>
              <InteractiveCalendar
                minDate={new Date()}
                value={fechaSeleccionada}
                onChange={(newValue) => {
                  setFechaSeleccionada(newValue as Date);
                }}
                tileDisabled={(props: {date: Date; view: string}) => {
                  const dateString = convertDateToDatestring(props.date);
                  if (fechasLibres.find((x) => x === dateString)) {
                    return false;
                  }
                  //if (props.date.getDate() === 10) return true;
                  //if (props.date.getDay() === 0) return true;
                  return true;
                }}
              />
            </div>
            {/* <FormControl fullWidth style={{ margin: 10 }}>
                        <InputLabel>Filtrar especialidad</InputLabel>
                        <Select
                            value={especialidadFiltro}
                            onChange={onEspecialidadFiltroChanged}
                            inputProps={{
                                name: 'filtrarEspecialidad'
                            }}
                        >
                            <MenuItem value={""} >Todas</MenuItem>
                            {especialidades.map((especialidad, index) => {
                                return (
                                    <MenuItem key={index} value={especialidad}>
                                        {especialidad}
                                    </MenuItem>
                                )
                            }
                            )}
                        </Select>
                    </FormControl> */}
            <Toolbar>
              <KeyboardTimePicker
                minutesStep={5}
                orientation="landscape"
                variant="inline"
                label="Horario Sobreturno"
                value={horaSobreturnos}
                onChange={(hora) => {
                  setHoraSobreturnos(hora as Date);
                }}
                ampm={false}
                inputVariant="outlined"
                style={{maxWidth: 200}}
              />
              <Button
                style={{margin: 20}}
                onClick={sendSobreturno}
                disabled={!horaSobreturnos}
              >
                Agregar
              </Button>
            </Toolbar>
          </Grid>
          <Grid item container xs={12} md={7}>
            <Card style={{flexGrow: 1}}>
              <CardContent
                style={{backgroundColor: theme.paletaColores.negro.quinto}}
              >
                <Toolbar variant="dense">
                  <Typography variant="h6">
                    {`Turnos para el dia ${
                      fechaSeleccionada.toLocaleDateString() ===
                      new Date().toLocaleDateString()
                        ? `de hoy`
                        : fechaSeleccionada.toLocaleDateString()
                    }`}
                  </Typography>
                  <div style={{flexGrow: 1}} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={verLibres}
                        onChange={(event) => {
                          setVerLibres(event.target.checked);
                        }}
                      />
                    }
                    labelPlacement="start"
                    label="Ver libres"
                  />
                </Toolbar>
              </CardContent>
              <CardContent style={{height: "55vh", overflowY: "scroll"}}>
                <List component="nav" aria-label="main mailbox folders">
                  {turnosList}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider style={{marginTop: 10, marginBottom: 10}} />
        <div className={classes.buttonsRoot}>
          <Volver
            onClickHandler={() => {
              history.goBack();
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default withStyles(styles)(VerAgendaSobreturnos);
