import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../store";
import {fetchPacientes} from "../../../apis/pacientesAPI";
import {IPaciente} from "../../../Interfaces/IPaciente";

interface IverConsultasAnterioresState {
  error: string | null;
}

let initialState: IverConsultasAnterioresState = {
  error: null,
};

const verConsultasAnterioresSlice = createSlice({
  name: "verConsultasAnteriores",
  initialState,
  reducers: {},
});

export const {} = verConsultasAnterioresSlice.actions;

export default verConsultasAnterioresSlice.reducer;
