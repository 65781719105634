import firebase from 'firebase/app';
import '@firebase/firestore';

let firebaseConfig = {};

switch (process.env.REACT_APP_ENV) {
    case "development": {
        firebaseConfig = {
            apiKey: "AIzaSyBLOzAvnv65Kj62q467OhuTLLNcOGHgjxY",
            authDomain: "medife-finochietto-dev.firebaseapp.com",
            databaseURL: "https://medife-finochietto-dev-default-rtdb.firebaseio.com",
            projectId: "medife-finochietto-dev",
            storageBucket: "medife-finochietto-dev.appspot.com",
            messagingSenderId: "459681035549",
            appId: "1:459681035549:web:22b5f609f5ee4d20541df4",
            measurementId: "G-3HTP1JRF2Y"
        };
        break;
    }
    case "test": {
        firebaseConfig = {
            apiKey: "AIzaSyB6_vtd9WmcZUlm3z6GGqIuofhBeOSAIlw",
            authDomain: "mdf-tele-test.firebaseapp.com",
            databaseURL: "https://mdf-tele-test-default-rtdb.firebaseio.com",
            projectId: "mdf-tele-test",
            storageBucket: "mdf-tele-test.appspot.com",
            messagingSenderId: "395231899587",
            appId: "1:395231899587:web:507c39c75916244d1c9776",
            measurementId: "G-GXTP8Q2GVW"
        };
        break;
    }
    case "production": {
        firebaseConfig = {
            apiKey: "AIzaSyBv7Fgn6AJpXrJlad8fYGqBEmYIHtXPV94",
            authDomain: "medife-telemedicina-produccion.firebaseapp.com",
            databaseURL: "https://medife-telemedicina-produccion-default-rtdb.firebaseio.com",
            projectId: "medife-telemedicina-produccion",
            storageBucket: "medife-telemedicina-produccion.appspot.com",
            messagingSenderId: "514855059602",
            appId: "1:514855059602:web:63908613abca56a38ee64b"
        };
        break;
    }
    case "medife-finochietto-dev": {
        firebaseConfig = {
            apiKey: "AIzaSyBLOzAvnv65Kj62q467OhuTLLNcOGHgjxY",
            authDomain: "medife-finochietto-dev.firebaseapp.com",
            databaseURL: "https://medife-finochietto-dev-default-rtdb.firebaseio.com",
            projectId: "medife-finochietto-dev",
            storageBucket: "medife-finochietto-dev.appspot.com",
            messagingSenderId: "459681035549",
            appId: "1:459681035549:web:22b5f609f5ee4d20541df4",
            measurementId: "G-3HTP1JRF2Y"
        };
        break;
    }
    case "camdoctor-telemedicina-demo": {
        firebaseConfig = {
            apiKey: "AIzaSyDzOHc0Er6HgMUJ1K67XczpEGzZZg4B4V8",
            authDomain: "camdoctor-telemedicina-demo.firebaseapp.com",
            databaseURL: "https://camdoctor-telemedicina-demo-default-rtdb.firebaseio.com",
            projectId: "camdoctor-telemedicina-demo",
            storageBucket: "camdoctor-telemedicina-demo.appspot.com",
            messagingSenderId: "770956701499",
            appId: "1:770956701499:web:fb37050f0c904036dc511e",
            measurementId: "G-F950N58MKP"
          };
        break;
    }
    case "medife-finochietto-qa": {
        firebaseConfig = {
            apiKey: "AIzaSyCOYl3x_H6ow44GWgNF1xM-RhEsqILynWo",
            authDomain: "medife-finochietto-qa.firebaseapp.com",
            databaseURL: "https://medife-finochietto-qa-default-rtdb.firebaseio.com",
            projectId: "medife-finochietto-qa",
            storageBucket: "medife-finochietto-qa.appspot.com",
            messagingSenderId: "129082972345",
            appId: "1:129082972345:web:29cbd5d0408e265a81e368",
            measurementId: "G-9P1W9SSFGF"
        };
        break;
    }
    case "UAT": {
        firebaseConfig = {
            apiKey: "AIzaSyANUDJ63lRnR0O_VguGwy1D0pfvVaKzT8M",
            authDomain: "medife-telemedicina-uat.firebaseapp.com",
            databaseURL: "https://medife-telemedicina-uat-default-rtdb.firebaseio.com",
            projectId: "medife-telemedicina-uat",
            storageBucket: "medife-telemedicina-uat.appspot.com",
            messagingSenderId: "919502796293",
            appId: "1:919502796293:web:820c91d0fde8ead8634e6c",
            measurementId: "G-8J3KX8P3P1"
        };
        break;
    }
}

const app = firebase.initializeApp(firebaseConfig);

export function getFirebase() {
    return app;
}

export function getFirestore() {
    return firebase.firestore(app);
}