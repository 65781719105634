import {createStyles, Theme} from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 2%",
      backgroundColor: theme.paletaColores.naranja.tercero,
    },
    buttonsFinochietto: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 2%",
      backgroundColor: theme.paletaColores.negro.finochietto,
    },
    llamadaFinalizada: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "calc(100% - 50px)",
      backgroundColor: "#474747",
      color: "white",
    },
  });
